import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { userActions } from "../actions";
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from "@material-ui/icons/Work";
import GroupIcon from "@material-ui/icons/Group";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(clientIdProofDetails, comparator) {
  const stabilizedThis = clientIdProofDetails && clientIdProofDetails.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'group_id', numeric: false, label: 'Group Id' ,},
  { id: '', numeric: false, label: '#', },
  { id: 'proof_type', numeric: false, label: 'Proof Type', },
  // { id: 'name', numeric: false, label: 'Name', },
  { id: 'document', numeric: false, label: 'Document', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions" || headCell.id === "") ?
          (
            <TableCell
              key={headCell.id}
              align="center"
              style={{ fontWeight: 'bold', fontSize: 17, }}
            >
              {headCell.label}
            </TableCell>
          )
          : (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
            style={{ fontWeight: 'bold', fontSize: 17, }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                // <span className={classes.visuallyHidden}>
                <span style={{
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: 1,
                  margin: -1,
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  top: 20,
                  // width: 1,
                }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    lexGrow: 1,
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    cursor: 'pointer',
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
    cursor: 'pointer',
  },

}));

export default function ClientsUploadedIdProofs() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [flag, setflag] = React.useState(false);
  const [ShowProcedure, setShowProcedure] = React.useState(false);

  const [clientIdProofDetails, setclientIdProofDetails] = React.useState([]);

  const [DeleteDocumentDetails, setDeleteDocumentDetails] = React.useState({
    document_id: "",
    delete_comments: "",
    business_id: JSON.parse(localStorage.getItem('selected_business_id')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [OpenDeleteComment, setOpenDeleteComment] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, clientIdProofDetails && clientIdProofDetails.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };


  useEffect(() => {
    setflag(true);
    let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'client_id': selected_Client_Showin_Ui,
        'device_token': device_token, 'session_id': session_id, 'business_id': business_id
      })
    };
    fetch(process.env.REACT_APP_GET_CLIENT_DETAILS, requestOptions)
      .then((Response) => Response.json())
      .then(async (clientIdProofDetails) => {
        setShowProcedure(false);
        // console.log("clientIdProofDetails from api if prof",clientIdProofDetails.data.client_details.idproofs);
        if (clientIdProofDetails.success == true) {

          let LengthofFirstArray = clientIdProofDetails.data.client_details.idproofs.length
          console.log(" LengthofFirstArray idproofs=== ", LengthofFirstArray)

          if (LengthofFirstArray === 0) {
            console.log(" array length is 0")
            setclientIdProofDetails([]);
          }
          else {
            console.log(" array length is not 0")
            setclientIdProofDetails(clientIdProofDetails.data.client_details.idproofs.idproofs.filter(item => item.is_active == true))
            // setclientIdProofDetails(clientIdProofDetails.data.client_details.idproofs.idproofs);
          }

          // if( clientIdProofDetails.data.client_details.idproofs.lenght > 0 ){
          //   setclientIdProofDetails(clientIdProofDetails.data.client_details.idproofs.idproofs);
          // }
          // else{
          //   setclientIdProofDetails([]);
          // }
        }
        else if (clientIdProofDetails.success == false) {
          setclientIdProofDetails([]);
          Swal.fire({
            icon: 'error',
            text: clientIdProofDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
    // .catch(err => {
    //   setShowProcedure(false);
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Server Error. Please try again.",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // });

  }, [flag]);

  const downloadDocumentFunc = (item) => {
    setShowProcedure(true);
    console.log("downloadDocumentFunc is clicked", item)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'doc_file_path': item.document,
        'device_token': device_token, 'session_id': session_id,
      })
    };
    return fetch(process.env.REACT_APP_GET_DOWNLOAD_INFO, requestOptions)
      .then((Response) => Response.json())
      .then(DownloadInfo => {
        setShowProcedure(false);
        if (DownloadInfo.success == true) {
          console.log("DownloadInfo is", DownloadInfo)
          window.open(DownloadInfo.data.download_info, "_blank");
        }
        else {
          Swal.fire({
            icon: 'error',
            text: DownloadInfo.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Something went wrong",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      })
  }

  // Delete idproof
  const handleOpneModalDeleteIdProof = (item) => {
    setDeleteDocumentDetails({ ...DeleteDocumentDetails , document_id:item.document_id })
    setOpenDeleteComment(true);
  }

  const handleDeleteCommentClose = () => {
    setDeleteDocumentDetails({ ...DeleteDocumentDetails , 
      document_id: "",
      delete_comments: "",
    })
    setOpenDeleteComment(false);
  }

  const bodyDeleteComment = (
    <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Delete Document</h2>
      </div>
      <div className={classes.modalBody}>
        <Grid style={{color: 'grey', fontSize: '12px', marginLeft:'1%' }}>
          Note: You can delete this document only if you have uploaded the document.
        </Grid>
        <Grid style={{marginTop:'4%'}}>
          <TextField
            id="deleteComment"
            name="deleteComment"
            variant="outlined"
            autoFocus
            fullWidth
            label="Comment"
            value={DeleteDocumentDetails.delete_comments}
            onChange={(e) => setDeleteDocumentDetails({ ...DeleteDocumentDetails , delete_comments: e.target.value }) }
          />
        </Grid>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'4%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleDeleteIdProof()}
          >
            Delete
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleDeleteCommentClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

    const handleDeleteIdProof = () => {
      console.log("in handle DeleteIdProof", )
      // let DeleteDocumentDetails = ({
      //   document_id: item.document_id,
      //   delete_comments: "",
      //   business_id: business_id,
      //   device_token: device_token,
      //   session_id: session_id,
      // })
      console.log("DeleteDocumentDetails", DeleteDocumentDetails)
      const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: classes.buttonSwalDelete,
            cancelButton: classes.buttonSwalCancel,
          },
          buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm it',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
                if (result.value) {
                  setShowProcedure(true);
                  const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify(DeleteDocumentDetails)
                  };
                  fetch( process.env.REACT_APP_DELETE_PROOF_DOCUMENT , requestOptions)
                    .then((Response) => Response.json())
                    .then(documentDeleteResponse => {
                      setShowProcedure(false);
                      console.log("documentDeleteResponse on same page",documentDeleteResponse);
                      setDeleteDocumentDetails({ ...DeleteDocumentDetails , 
                        document_id: "",
                        delete_comments: "",
                      })
                      setflag(false);
                      setOpenDeleteComment(false);
                      if(documentDeleteResponse.success == true)
                      {
                        Swal.fire({
                          icon: 'success',
                          text: "Document Deleted.",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                        
                      }
                      if(documentDeleteResponse.success == false){
                        Swal.fire({
                          icon: 'error',
                          text: documentDeleteResponse.errors,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      }
                  })
                  .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                  });
                        
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) 
                {
                  // swalWithBootstrapButtons.fire(
                  //   'Cancelled',
                  //   'Your imaginary file is safe :)',
                  //   'error'
                  // )
                }
              })
    }

  return (
    console.log("clientIdProofDetails in new page", clientIdProofDetails),
    <div className={classes.root}>
      <Grid>
        <TableContainer style={{ width: '95%' }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              // classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={clientIdProofDetails && clientIdProofDetails.length}
            />
            <TableBody>
              {stableSort(clientIdProofDetails && clientIdProofDetails, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // console.log("row in table map",item)
                  return (
                    <TableRow
                      key={item.state_id}
                      hover={true} classes={{ hover: classes.hover }}
                      // hover
                      tabIndex={-1}
                      align="center"
                    >
                      <TableCell width="5%" align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item.proof_type}</TableCell>
                      {/* <TableCell align="center">{item.name}</TableCell> */}
                      {/* <TableCell align="center">{item.document.substring(item.document.lastIndexOf('/')+1)}</TableCell> */}
                      {/* <TableCell align="center">{item.document}</TableCell> */}
                      <TableCell align="center">
                        <a
                          style={{cursor:"pointer"}}
                          onClick={() => downloadDocumentFunc(item)}
                          target="_blank"
                        >
                          {/* <Grid style={{ color: "#551A8B", listStyle: "none", cursor: "pointer" }}>
                            {item.document.substring(item.document.lastIndexOf('/') + 1)}
                          </Grid> */}
                          <Grid style={{ color: "#551A8B", listStyle: "none", cursor: "pointer" }}>
                            {item.name}
                          </Grid>
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginLeft:'3%' }}
                          // onClick={() => handleDeleteIdProof(item)}
                          onClick={() => handleOpneModalDeleteIdProof(item)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: clientIdProofDetails && clientIdProofDetails.length }]}
                  colSpan={8}
                  count={clientIdProofDetails && clientIdProofDetails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        open={OpenDeleteComment}
        onClose={handleDeleteCommentClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyDeleteComment}
      </Modal>
    </div>
  );
}
