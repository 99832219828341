// FeedbackDetail

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import MenuList from "@material-ui/core/MenuList";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MessageIcon from "@material-ui/icons/Message";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddNewTagModalView from "../AddNewTagModalView";
import Modal from '@material-ui/core/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from 'react-redux';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import {
    FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter, Table, Spinner,
    Popover, PopoverHeader, PopoverBody
} from 'reactstrap';
import { SketchPicker } from 'react-color';
import useCheckSessionApi from "../SessionValidity";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { userActions } from "../actions";
import CloseIcon from '@material-ui/icons/Close';
import { Link, Redirect } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { addcaseDetailsShow } from "../actions/casedetails.actions"
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from "@material-ui/icons/Check";

import FeedbackMessages from './FeedbackMessages';
import FeedbackImages from "./FeedbackImages";

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    wrapper: {
        margin: "10%",
        marginRight: "5%",
        marginTop: "7%",
        marginBottom: "3%",
    },
    showProcedureStyle: {
        position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    pageHeaderContainer: {
        marginBottom: "5%",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    backArrow: {
        marginRight: "10%",
    },
    casesDetailInfo: {
        textAlign: "left",
    },
    casesNameInfo: {
        marginTop: "10%",
        padding: 0,
        textTransform: "uppercase",
        color: "rgba(0, 0, 0, 0.96)",
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    casesFilingYearInfo: {
        padding: 0,
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    setPriorityButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "#4D47DD",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    verticalAlignTop: {
        verticalAlign: "top",
    },
    casesetPriorityInfo: {
        marginTop: "5%",
    },
    caseClientNameInfo: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "2%",
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.96)",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    caseClientNameInfoLowMarginTop: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "1%",
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.96)",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    casePanCardInfo: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "2%",
        marginBottom: "7%",
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.96)",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    casePhoneNumberInfo: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "3%",
        marginBottom: "3%",
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.96)",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    phoneIcon: {
        display: "inline",
        verticalAlign: "top",
        marginRight: "2%",
    },
    caseMessageInfo: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "1%",
        marginBottom: "3%",
        textTransform: "none",
        color: "#4D47DD",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    messageIcon: {
        display: "inline",
        verticalAlign: "top",
        marginRight: "2%",
    },
    caseAddTagInfo: {
        padding: 0,
        lineHeight: "24px",
        marginTop: "1%",
        marginBottom: "3%",
        textTransform: "none",
        color: "#4D47DD",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    addeIcon: {
        display: "inline",
        verticalAlign: "top",
        marginRight: "2%",
    },
    casesDetailSubMenu: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
    },
    tabCustomStyle: {
        textTransform: "none",
        minWidth: '16.5%',
        // width:'10%',
        // fontSize: 10,
    },
    customPaperStyle: {
        borderRadius: "10px",
        marginLeft: "1.5%",
    },
    // modal: {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    // },
    // paperModal: {
    //   backgroundColor: "#FFFFFF",
    //   boxShadow: theme.shadows[5],
    //   borderRadius: "5px",
    //   width: "570px",
    //   maxHeight: "480px",
    //   padding: theme.spacing(2, 4, 3),
    // },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // overflow:'scroll',
    },
    paperModal: {
        position: 'absolute',
        width: 500,
        height: 650,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // top:'2%',
        display: 'block',
        // overflowY:'scroll',
    },
    modalBody: {
        padding: theme.spacing(2, 4, 3),
        width: '100%',
        // overflowY:'scroll',

    },
    SwitchStepHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#23c1f7',
        height: '12%',
    },
    addNewBusinessHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#23c1f7',
        backgroundColor: 'white',
        height: '12%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    paperModalSelectTag: {
        position: 'absolute',
        width: 500,
        height: 650,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // top:'2%',
        display: 'flex',
        flexDirection: 'column',
        // overflowY:'scroll',
        overflow: 'auto',
    },
    addNewBusinessHeaderBackgroudSelectTag: {
        // display: 'flex',
        // alignItems: 'center', 
        // justifyContent: 'center',
        // backgroundColor: '#23c1f7',
        backgroundColor: 'white',
        // height:'12%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        // flex:1,
    },
    selectTagModalBody: {
        padding: theme.spacing(2, 4),
        width: '100%',
        // position:'absolute',
        overflow: 'auto',
        height: "65vh",
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        // flex: 1,
        // marginTop:'10%'
    },
    selectTagModalFooter: {
        padding: theme.spacing(2, 4),
        // marginTop: '2%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        // flex: 1,
    },

    addNewTagHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#23c1f7',
        // backgroundColor: 'white',
        height: '12%',
    },
    PendingInfoHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#23c1f7',
        height: '25%',
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
        cursor: "pointer",
    },
    submitPaymentDetails: {
        // margin: theme.spacing(3, 0, 2),
        cursor: "pointer",
    },
    addButtonStyle: {
        textTransform: "none",
        lineHeight: "24px",
        fontSize: "16px",
    },
    showProcedureStyle: {
        position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    moreVertIcon: {
        textAlign: "right",
        cursor: "pointer",
        color: 'black',
    },
    formControl: {
        // margin: theme.spacing(1),
        marginTop: '4%',
        width: '100%',
    },
    validation: {
        color: 'red',
        fontSize: 12,
    },
    paperModalSwitchStep: {
        position: 'absolute',
        width: 500,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // top:'4%',
        display: 'block',
        overflowY: 'scroll',
    },
    paperModalPendingInfo: {
        position: 'absolute',
        width: 500,
        height: 300,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // top:'4%',
        display: 'block',
        // overflowY:'scroll',
    },
    menuLableStyle: {
        color: 'rgba(0, 0, 0, 0.64)',
        fontSize: '16px',
        // fontFamily: BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontWeight: 600,
        lineHeight: 0,
        textTransform: 'none',
    },
    buttonSwalDelete: {
        background: "#1a1aff",
        height: 35,
        fontSize: 14,
        color: '#ffffff',
        // padding: 10,
        border: "1px solid #1a1aff",
        borderRadius: 5,
        cursor: 'pointer',
    },
    buttonSwalCancel: {
        background: "#1a1aff",
        height: 35,
        fontSize: 14,
        color: '#ffffff',
        // padding: 10,
        border: "1px solid #1a1aff",
        borderRadius: 5,
        marginRight: "2%",
        cursor: 'pointer',
    },
    CaseRejectedLable: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "red",
        textAlign: "left",
    },
    CaseRejectedComment: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

export default function FeedbackDetail({intl, props}) {
    const classes = useStyles();
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
    const [ShowProcedure, setShowProcedure] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [OpenStatus, setOpenStatus] = React.useState(false);
    const [Feedbacks, setFeedbacks] = React.useState(
        // {
        //     feedback_id: 1,
        //     feedback: "feedback 1 description",
        //     subject: "feedback 1 subject",
        //     priority: "Low",

        // },
    );
    const [value, setValue] = React.useState(0);
    const [RedirectToShowFeedback, setRedirectToShowFeedback] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setShowProcedure(true);
        dispatch(userActions.checkSessionValidity());
    
        let Feedback_Details_Here = JSON.parse(localStorage.getItem('selected_Feedback_Details'));
        console.log("Feedback_Details_Here", Feedback_Details_Here)
        setFeedbacks(Feedback_Details_Here)


        setShowProcedure(false);
    }, []);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeBackButton = () => {
        console.log("its handleChangeBack Button on case details");
        setRedirectToShowFeedback(true);
    }

    const handleTogglePriority = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChangePriority= (priority) => {
        console.log("handleChangePriority",priority)
        handleTogglePriority();
        setShowProcedure(true);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'device_token': device_token, 'session_id': session_id,
                'ticket_id': Feedbacks.ticket_id, 'previous_priority': Feedbacks.ticket_priority,
                'new_priority': priority, 'business_id': business_id
            })
        };
        fetch( process.env.REACT_APP_UPDATE_TICKET_PRIORITY , requestOptions)
        .then((Response) => Response.json())
        .then( async(PriorityDetails) => {
            setShowProcedure(false);
            console.log("PriorityDetails after api call",PriorityDetails);
            if(PriorityDetails.success == true)
            {
                console.log("PriorityDetails.data.priority_info ",PriorityDetails.data.priority_info);
                setFeedbacks({ ...Feedbacks , ticket_priority: PriorityDetails.data.priority_info.new_priority })
                Swal.fire({
                    icon: 'success',
                    text: 'Ticket Priority Updated Succefully',
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 1000,
                })
                let Feedback_Here = { ...Feedbacks , ticket_priority: PriorityDetails.data.priority_info.new_priority }
                console.log("Feedback_Here",Feedback_Here, Feedbacks)
                await localStorage.setItem('selected_Feedback_Details', JSON.stringify(Feedback_Here));
            }
            else if(PriorityDetails.success == false){
                // setFeedbacks([]);
                Swal.fire({
                    icon: 'error',
                    text: PriorityDetails.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 9000,
                })
            }
        })
        .catch(err => {
            setShowProcedure(false);
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });
    };

    const handleToggleChangeStatus = () => {
        setOpenStatus((prevOpen) => !prevOpen);
    }

    const handleChangeStatus = (status) => {
      
        console.log("in handle ChangeStatus", status);
        console.log("in handle ChangeStatus vbha change", status);
        handleToggleChangeStatus()
        setShowProcedure(true);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'device_token': device_token, 'session_id': session_id,
                'ticket_id': Feedbacks.ticket_id, 'comment': "static for now", 'previous_status': Feedbacks.status,
                'new_status': status, 'business_id': business_id
            })
        };
        fetch( process.env.REACT_APP_UPDATE_TICKET_STATUS , requestOptions)
        .then((Response) => Response.json())
        .then( async(StatusDetails) => {
            setShowProcedure(false);
            console.log("StatusDetails after api call in useeffect",StatusDetails);
            if(StatusDetails.success == true)
            {
                console.log("StatusDetails.data.tickets ",StatusDetails.data.status_info);
                setFeedbacks({ ...Feedbacks , status: StatusDetails.data.status_info.new_status })
                Swal.fire({
                    icon: 'success',
                    text: 'Ticket Status Updated Succefully',
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 1000,
                })
                let Feedback_Here = { ...Feedbacks , status: StatusDetails.data.status_info.new_status }
                console.log("Feedback_Here",Feedback_Here, Feedbacks)
                await localStorage.setItem('selected_Feedback_Details', JSON.stringify(Feedback_Here));
            }
            else if(StatusDetails.success == false){
                // setFeedbacks([]);
                Swal.fire({
                    icon: 'error',
                    text: StatusDetails.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 9000,
                })
            }
        })
        .catch(err => {
            setShowProcedure(false);
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

        // setFeedbacks({ ...Feedbacks , status: status })
        // handleToggleChangeStatus()
    }

    const createdDateFunc = (date, item) => {
        console.log("date in createdDateFunc is", date)
        let dateInString = JSON.stringify(date)
    
        console.log("date in straing is ", dateInString)
    
    
        var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
        console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
        var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
        console.log("myDateWithFormatIs",myDateWithFormatIs)
        console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 
    
        var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
        console.log("local_date using moments is",local_date)
    
        var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
        console.log("local_date day first",local_date_DayFirst)
    
        return(
            <Grid container style={{marginTop:'2%'}}>
                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Date : </text>
                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
                    &nbsp;{local_date_DayFirst}
                </text>
            </Grid>
        )
    }

    if( RedirectToShowFeedback === true ){
        return <Redirect to={`/Feedback`} />
    }
    else{
    return (
        console.log("Feedbacks in return",Feedbacks),
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
            </modal> : null}
            <div className={classes.wrapper}>

                <Grid container>
                    <Grid item xs={3} className={classes.casesDetailInfo}>
                        <Grid container>
                            <Grid item xs={12}>

                                <Button onClick={() => handleChangeBackButton()} className={classes.backButton}>
                                    <span className={classes.backArrow}>
                                        <ArrowBackIcon fontSize="small" />
                                    </span>
                                    <FormattedMessage id="backLabel" />
                                </Button>

                            </Grid>
                            {/* <Grid item xs={12} className={classes.casesNameInfo}>
                Feedback Subject : {Feedbacks && Feedbacks.subject}
              </Grid> */}

                            <Grid container item xs={12} style={{marginTop:"5%"}}>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Subject : </text>
                                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
                                    &nbsp;{Feedbacks && Feedbacks.subject}
                                </text>
                            </Grid>
                            <Grid container style={{marginTop:'2%'}}>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Description : </text>
                                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
                                    &nbsp;{Feedbacks && Feedbacks.description}
                                </text>
                            </Grid>
                            {/* <Grid container style={{marginTop:'2%'}}>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Date : </text>
                                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}> */}
                            {createdDateFunc(Feedbacks && Feedbacks.created, Feedbacks && Feedbacks)}
                                {/* </text>
                            </Grid> */}

                            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Divider />
                            </Grid>

                            <Grid container>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Category : </text>
                                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
                                    &nbsp;{Feedbacks && Feedbacks.category}
                                </text>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Divider />
                            </Grid>

                            <Grid container>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Severity : </text>
                                <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
                                    &nbsp;{Feedbacks && Feedbacks.severity}
                                </text>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Divider />
                            </Grid>

                            <Grid style={{marginTop:'1%'}}>
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Status:  </text>
                                {/* <text style={{ fontSize: 15, fontWeight: 'bold', color: 'black' }}>
                                    {Feedbacks && Feedbacks.status}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </text> */}

                                    {
                                        ( Feedbacks && Feedbacks.status === "INPROGRESS" )?
                                        (
                                            <text style={{ fontSize: 15, fontWeight: 'bold', color: 'black' }}>
                                               IN-PROGRESS &nbsp;&nbsp;&nbsp;
                                            </text>
                                        ):
                                        (
                                           
                                            <text style={{ fontSize: 15, fontWeight: 'bold', color: 'black' }}>
                                                {Feedbacks && Feedbacks.status}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </text>
                                        )
                                    }
                                
                            </Grid>

                            <Button
                                className={classes.setPriorityButton}
                                aria-controls={OpenStatus ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                id="setStatusLabel"
                                onClick={handleToggleChangeStatus}
                            >
                                Change Status
                                <span className={classes.verticalAlignTop}>
                                    <ExpandMoreIcon />
                                </span>
                            </Button>
                            <div>
                                <Popover placement="bottom" trigger="legacy" target="setStatusLabel"
                                    isOpen={OpenStatus}
                                    toggle={handleToggleChangeStatus}
                                    style={{
                                        backgroundColor: "white", color: "white", width: "140%", marginLeft: "-20%",
                                        padding: 10
                                    }}
                                >
                                    <PopoverBody>
                                        {
                                            (Feedbacks && Feedbacks.status == "OPEN") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "29%" }}
                                                        onClick={(e) => handleChangeStatus("OPEN")}
                                                    >
                                                        OPEN
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "29%" }}
                                                        onClick={(e) => handleChangeStatus("OPEN")}
                                                    >
                                                        OPEN
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {
                                            (Feedbacks && Feedbacks.status == "INPROGRESS") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "15%" }}
                                                        onClick={(e) => handleChangeStatus("INPROGRESS")}
                                                    >
                                                        IN-PROGRESS
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "15%" }}
                                                        onClick={(e) => handleChangeStatus("INPROGRESS")}
                                                    >
                                                        IN-PROGRESS
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {
                                            (Feedbacks && Feedbacks.status == "RESOLVED") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "22%" }}
                                                        onClick={(e) => handleChangeStatus("RESOLVED")}
                                                    >
                                                        RESOLVED
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "22%" }}
                                                        onClick={(e) => handleChangeStatus("RESOLVED")}
                                                    >
                                                        RESOLVED
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {
                                            (Feedbacks && Feedbacks.status == "CLOSE") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "28%" }}
                                                        onClick={(e) => handleChangeStatus("CLOSE")}
                                                    >
                                                        CLOSE
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "28%" }}
                                                        onClick={(e) => handleChangeStatus("CLOSE")}
                                                    >
                                                        CLOSE
                                                    </text>
                                                )
                                        }

                                    </PopoverBody>
                                </Popover>
                            </div>



                            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Divider />
                            </Grid>

                            <Grid >
                                <text style={{ fontSize: 15, fontWeight: 'bold' }}>Priority:  </text>
                                <text style={{ fontSize: 15, fontWeight: 'bold', color: 'black' }}>{Feedbacks && Feedbacks.ticket_priority}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                            </Grid>

                            <Button
                                className={classes.setPriorityButton}
                                aria-controls={open ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                id="setPriorityLabel"
                                onClick={handleTogglePriority}
                            >
                                Change Priority
                                <span className={classes.verticalAlignTop}>
                                    <ExpandMoreIcon />
                                </span>
                            </Button>

                            <div>
                                <Popover placement="bottom" trigger="legacy" target="setPriorityLabel"
                                    isOpen={open}
                                    toggle={handleTogglePriority}
                                    style={{
                                        backgroundColor: "white", color: "white", width: "140%", marginLeft: "-20%",
                                        padding: 10
                                    }}
                                >
                                    <PopoverBody>
                                        {
                                            (Feedbacks && Feedbacks.ticket_priority == "HIGH") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "25%" }}
                                                      onClick={(e) => handleChangePriority("HIGH")}
                                                    >
                                                        HIGH
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "25%" }}
                                                      onClick={(e) => handleChangePriority("HIGH")}
                                                    >
                                                        HIGH
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {
                                            (Feedbacks && Feedbacks.ticket_priority == "NORMAL") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "15%" }}
                                                        onClick={(e) => handleChangePriority("NORMAL")}
                                                    >
                                                        NORMAL
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "15%" }}
                                                        onClick={(e) => handleChangePriority("NORMAL")}
                                                    >
                                                        NORMAL
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {
                                            (Feedbacks && Feedbacks.ticket_priority == "LOW") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "25%" }}
                                                        onClick={(e) => handleChangePriority("LOW")}
                                                    >
                                                        LOW
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "25%" }}
                                                        onClick={(e) => handleChangePriority("LOW")}
                                                    >
                                                        LOW
                                                    </text>
                                                )
                                        }
                                        <hr />
                                        {/* {
                                            (Feedbacks && Feedbacks.ticket_priority == "MAJOR") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "27%" }}
                                                        onClick={(e) => handleChangePriority("MAJOR")}
                                                    >
                                                        MAJOR
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "27%" }}
                                                        onClick={(e) => handleChangePriority("MAJOR")}
                                                    >
                                                        MAJOR
                                                    </text>
                                                )
                                        }
                                        <hr /> */}
                                        
                                        {/* {
                                            (Feedbacks && Feedbacks.ticket_priority == "MINOR") ?
                                                (
                                                    <text style={{ cursor: 'pointer', color: "blue", padding: 10, marginLeft: "27%" }}
                                                        onClick={(e) => handleChangePriority("MINOR")}
                                                    >
                                                        MINOR
                                                    </text>
                                                )
                                                : (
                                                    <text style={{ cursor: 'pointer', color: "black", padding: 10, marginLeft: "27%" }}
                                                        onClick={(e) => handleChangePriority("MINOR")}
                                                    >
                                                        MINOR
                                                    </text>
                                                )
                                        } */}

                                    </PopoverBody>
                                </Popover>
                            </div>

                            <Grid item xs={12} style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Divider />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper elevation={0} className={classes.customPaperStyle}>
                        <div className={classes.casesDetailSubMenu}>
                            <Tabs
                                value={value}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                            <Tab
                                className={classes.tabCustomStyle}
                                // label={intl.formatMessage({ id: "feedbackMessagesLink" })}
                                label={"Messages"}
                            />                
                            <Tab
                                className={classes.tabCustomStyle}
                                // label={intl.formatMessage({ id: "feedbackImagesLink" })}
                                label={"Images"}
                            />
                            
                            </Tabs>
                        </div>
                        <TabPanel value={value} index={0}>
                            <FeedbackMessages />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <FeedbackImages />
                        </TabPanel>
                        
                        </Paper>
                    </Grid>
                </Grid>


            </div>
        </div>
    )
    }

}