import React, { useState ,useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { authHeader } from '../../helpers';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../actions";
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControll from '@mui/material/FormControl';
import Input from '@mui/material/Input';

import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CancelIcon from '@material-ui/icons/Cancel';

const theme = createTheme({
  palette: {
    primary: {
      main: '#001eaa',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "flex-end",
    background: "#ffffff",
  },
  wrapper: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "5%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  subHeadingLabelList: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "5px",
    // marginBottom: "10px",
    textAlign: "left",
  },
  UploadIcon: {
    fontWeight: "500",
    // fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "5px",
    // marginBottom: "10px",
    textAlign: "right",
  },
  marginRight: {
    marginRight: "4%",
  },
  textTransformNone: {
    textTransform: "none",
  },
  createStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  label: {
    fontSize: "14px",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  moreVertIcon:{
    textAlign: "left",
    cursor: "pointer",
    color: 'black',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
},
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  validationMaxTopMargin: {
    color: 'red',
    fontSize: 12,
    marginTop: '2%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  markQuesLineLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "600",
    fontSize: "10px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "right",
  },
  formControl: {
    // margin: theme.spacing(1),
    // marginTop: '4%',
    width: '100%',
    // minWidth: 170,
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    cursor: 'pointer',
},
buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
    cursor: 'pointer',
},
}));

function EditCaseUploadFileClientCases({ intl, onSelectUploadFiles}) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id'))); 
  const [caseDocuments, setcaseDocuments] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  // document form
  const [selectedDocumentId, setselectedDocumentId] = React.useState();
  const [documentNumber, setdocumentNumber] = React.useState("");
  const [docHavePass, setdocHavePass] = React.useState("");
  const [documentPassword, setdocumentPassword] = React.useState("");
  const [DocumentFile, setDocumentFile] = React.useState("");
  const [DocumentName, setDocumentName] = React.useState("");

  const [docHavePassErr, setdocHavePassErr] = React.useState("");
  const [docPasswordErr, setdocPasswordErr] = React.useState("");
  const [docFileErr, setdocFileErr] = React.useState("");

  // add more doc
  const[allDocTypeDetails, setallDocTypeDetails] = React.useState([]);
  const[serviceDocTypeDetails, setserviceDocTypeDetails] = React.useState([]);
  const[openAddDocumentsNew, setopenAddDocumentsNew] = React.useState(false);
  const[documentTypeNew, setdocumentTypeNew] = React.useState("");
  const[documentNumberNew, setdocumentNumberNew] = React.useState("");
  const[docHavePassNew, setdocHavePassNew] = React.useState("");
  const[documentPasswordNew, setdocumentPasswordNew] = React.useState("");
  const[DocumentFileNew, setDocumentFileNew] = React.useState("");
  const[DocumentNameNew, setDocumentNameNew] = React.useState("");
  const[docTypeNewErr, setdocTypeNewErr] = React.useState("");
  const[docHavePassNewErr, setdocHavePassNewErr] = React.useState("");
  const[docPasswordNewErr, setdocPasswordNewErr] = React.useState("");
  const[docFileNewErr, setdocFileNewErr] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const [DeleteDocumentDetails, setDeleteDocumentDetails] = React.useState({
    case_id: "",
    doctype_id: "",
    user_document_id: "",
    delete_comments: "",
    business_id: JSON.parse(localStorage.getItem('selected_business_id')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [OpenDeleteComment, setOpenDeleteComment] = React.useState(false);

  // to get service id
  const [selectedServiecId, setselectedServiecId ] = React.useState(JSON.parse(localStorage.getItem('service_id_of_case_for_doc')));
  // service_id_of_case_for_doc

  const [flag , setflag] = React.useState(false);

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  // redux
  const dispatch = useDispatch();

  const newCaseCreatedCaseIdRedux = useSelector(state => state.newCase.newCaseCreatedCaseId);

  useEffect(() => {
    // setflag(false);
    // readItemFromStorage();
    dispatch(userActions.checkSessionValidity());
    setShowProcedure(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"BUSINESS", 
        'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
      .then((Response) => Response.json())
      .then( caseDocuments => {
        setShowProcedure(false);
        // setShowProcedure(false);
        console.log("caseDocuments for last level",caseDocuments);
        if(caseDocuments.success == true)
        {
          if(caseDocuments.data.case_documents.length == "0"){
              onSelectUploadFiles("5","Yes Empty")
          }
          setcaseDocuments(caseDocuments.data.case_documents)

          let forvalidation = caseDocuments.data.case_documents
          // 
          // forvalidation.map((f)=>{
          //   console.log("forvalidation",f.user_uploaded.document)
          //   if( f.user_uploaded.document  == undefined ) {
          //     console.log("Files are not uploaded")
          //   }
          //   else if( f.user_uploaded.document ){
          //     console.log("Files are uploaded")
              
          //   }
          // })
          onSelectUploadFiles("5","")
          // addqsnListRedux(qsn_list)
          // {navigateToQuestionSetTwo()};
        }
        else{
          setcaseDocuments([])
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // to get all document types of business
    const requestOptionsAllDocType = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id':business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_DOC_TYPES , requestOptionsAllDocType)
        .then((Response) => Response.json())
        .then(allDocTypeDetails => {
            console.log("allDocTypeDetails on same page",allDocTypeDetails.data.doc_types);
            if(allDocTypeDetails.data.doc_types)
            {
              setallDocTypeDetails(allDocTypeDetails.data.doc_types)
            }
            if(allDocTypeDetails.success == false){
              setallDocTypeDetails([])
              Swal.fire({
                icon: 'error',
                text: allDocTypeDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    // to get service document types of business
    console.log("selectedServiecId foe get doc types", selectedServiecId)
    const requestOptionsServiceDocType = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': selectedServiecId,
        'business_id':business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsServiceDocType)
        .then((Response) => Response.json())
        .then(serviceDocTypeDetails => {
            console.log("serviceDocTypeDetails on same page",serviceDocTypeDetails.data.doc_types);
            if(serviceDocTypeDetails.data.doc_types)
            {
              setserviceDocTypeDetails(serviceDocTypeDetails.data.doc_types)
            }
            if(serviceDocTypeDetails.success == false){
              setserviceDocTypeDetails([])
              Swal.fire({
                icon: 'error',
                text: serviceDocTypeDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });


  }, []);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //       console.log("ERROR:",error);
  //   }
  // };

  const uploadDocumentFunc = (item) => {
    console.log("in uploadDocumentFunc",item)
    setselectedDocumentId(item.doc_type_id)
    setOpen(true);
  }

  const handelUploadDocumentClose = () => {
    setOpen(false);
    setselectedDocumentId("");
    setdocumentNumber("");
    setdocHavePass("");
    setdocumentPassword("");
    setDocumentFile("");
    setDocumentName("");

    setdocHavePassErr("");
    setdocPasswordErr("");
    setdocFileErr("");
    setShowPassword(false);
  }

  const changeRadioDocumentHavePass = (e) => {
    // setdocHavePass(JSON.parse(e.target.value));
    setdocHavePass(e.target.value);
  }

  const onUploadDocument= (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    console.log("event.target.files[0] name",event.target.files[0].name)
    console.log("uri",event.target.files.uri)
    setDocumentName(event.target.files[0].name);
    setDocumentFile(event.target.files[0])
  }

  const validate = () => {
    let docHavePassErr = '';
    let docPasswordErr = '';
    let docFileErr = '';

    if( !docHavePass ){
      docHavePassErr = "Please Select If Document Has Password or Not ";
    }

    if( docHavePass == "true" ) {
      if( !documentPassword ){
        docPasswordErr = "Please Enter Document Password";
      }
    }

    if( !DocumentFile ){
      docFileErr = "Please Select Document To Upload";
    }


    if( docHavePassErr || docPasswordErr || docFileErr){
        setdocHavePassErr(docHavePassErr);
        setdocPasswordErr(docPasswordErr);
        setdocFileErr(docFileErr);

        return false;
      }
      return true;
  }
  const handelSubmitUploadDocument = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const isValid = validate();
    if(isValid){
      setOpen(false);
      setShowProcedure(true);
      console.log("selectedDocumentId,documentNumber,docHavePass,documentPassword,DocumentFile",
        selectedDocumentId,documentNumber,docHavePass,documentPassword,DocumentFile)

      const requestOptionsSecond = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'doc_type': 'case_document', 'doc_name': DocumentFile.name, 'doc_extra_info': '',
          'device_token': device_token, 'session_id': session_id, 'business_id': business_id
        })
      }
      return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)

          if (checkResponseOfS3Upload.success === true) {
            // console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
            console.log("in success checkResponseOfS3Upload.data.upload_info.url", checkResponseOfS3Upload.data.upload_info.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", DocumentFile);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)
                
                // let formdata = new FormData();
                // formdata.append('case_id', newCaseCreatedCaseIdRedux);
                // formdata.append('doc_type_id', selectedDocumentId);
                // formdata.append('doc_no', documentNumber);
                // formdata.append('is_protected', JSON.parse(docHavePass));
                // formdata.append('password', documentPassword);
                // formdata.append('document', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);

                const requestOptions = {
                  method: 'POST',
                  headers:  {
                    Authorization: "Token " + user.key,
                  },
                  // body: formdata,
                  body: JSON.stringify({
                    'case_id': newCaseCreatedCaseIdRedux, 'doc_type_id': selectedDocumentId, 'doc_no': documentNumber, 
                    'is_protected': JSON.parse(docHavePass),
                    'password': documentPassword, 
                    // 'document': checkResponseOfS3Upload.data.public_url.url, 
                    'document': checkResponseOfS3Upload.data.upload_info.fields.key, 
                    "uuid": checkResponseOfS3Upload.data.upload_info.uuid,
                    'device_token': device_token, 'session_id': session_id,
                    'business_id': business_id
                  })
                };
                // for (var key of formdata.entries()) {
                //     console.log("in for : "+ key[0] + ', ' + key[1]);
                //   }
                console.log("requestOptions",requestOptions)
                return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
                  .then((Response) => Response.json())
                  .then( documentUploaded => {
                    setShowProcedure(false);
                    console.log("documentUploaded",documentUploaded.success, documentUploaded);
                      if(documentUploaded.success == true){
                        // setOpen(false);
                        Swal.fire({
                          icon: 'success',
                          text: "File Uploaded Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                        // setOpen(false);
                        setselectedDocumentId("");
                        setdocumentNumber("");
                        setdocHavePass("");
                        setdocumentPassword("");
                        setDocumentFile("");
                        setDocumentName("");

                        setdocHavePassErr("");
                        setdocPasswordErr("");
                        setdocFileErr("");
                        setShowPassword(false);
                        onSelectUploadFiles("5","")
                        refreshGetFilesFunction();
                      }
                      if(documentUploaded.success == false){
                        // setOpen(true);
                        Swal.fire({
                          icon: 'error',
                          text: documentUploaded.errors,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      }          
                  })
                  .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                  });

              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })

      // let formdata = new FormData();
      // formdata.append('case_id', newCaseCreatedCaseIdRedux);
      // formdata.append('doc_type_id', selectedDocumentId);
      // formdata.append('doc_no', documentNumber);
      // formdata.append('is_protected', JSON.parse(docHavePass));
      // formdata.append('password', documentPassword);
      // formdata.append('document', DocumentFile);
      // formdata.append('device_token', device_token);
      // formdata.append('session_id', session_id);

      // const requestOptions = {
      //   method: 'POST',
      //   headers:  {
      //     Authorization: "Token " + user.key,
      //   },
      //   body: formdata,
      // };
      // for (var key of formdata.entries()) {
      //     console.log("in for : "+ key[0] + ', ' + key[1]);
      //   }
      // console.log("requestOptions",requestOptions)
      // return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
      //   .then((Response) => Response.json())
      //   .then( documentUploaded => {
      //     setShowProcedure(false);
      //     console.log("documentUploaded",documentUploaded.success, documentUploaded);
      //       if(documentUploaded.success == true){
      //         // setOpen(false);
      //         Swal.fire({
      //           icon: 'success',
      //           text: "File Uploaded Succesfully",
      //           confirmButtonColor: 'primary',
      //           confirmButtonText: 'OK',
      //           timer: 5000,
      //         })
      //         // setOpen(false);
      //         setselectedDocumentId("");
      //         setdocumentNumber("");
      //         setdocHavePass("");
      //         setdocumentPassword("");
      //         setDocumentFile("");
      //         setDocumentName("");

      //         setdocHavePassErr("");
      //         setdocPasswordErr("");
      //         setdocFileErr("");
      //         onSelectUploadFiles("5","")
      //         refreshGetFilesFunction();
      //       }
      //       if(documentUploaded.success == false){
      //         // setOpen(true);
      //         Swal.fire({
      //           icon: 'error',
      //           text: documentUploaded.errors,
      //           confirmButtonColor: '#d33',
      //           confirmButtonText: 'OK',
      //           timer: 5000,
      //         })
      //       }          
      //   })
      //   .catch(err => {
      //     setShowProcedure(false);
      //     Swal.fire({
      //       icon: 'error',
      //       // text: "Something went wrong",
      //       text: "Server Error. Please try again.",
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK'
      //     })
      //   });

        // const url = process.env.REACT_APP_UPLOAD_USER_DOCUMENT ;
        // let formData = new FormData();
        // formData.append('case_id', newCaseCreatedCaseIdRedux);
        // formData.append('doc_type_id', selectedDocumentId);
        // formData.append('doc_no', documentNumber);
        // formData.append('is_protected', JSON.parse(docHavePass));
        // formData.append('password', documentPassword);
        // // formData.append('document', DocumentFile);
        // // formData.append('document',{"size":595527,"name":"vidyaskitchen-certificate.png","type":"image/png","uri":"content://com.android.providers.downloads.documents/document/4938"})
        // formData.append('device_token', device_token);
        // formData.append('session_id', session_id);

        // var options = { content: formData }

        // for (var key of formData.entries()) {
        //   console.log("in for : "+ key[0] + ', ' + key[1]);
        // }
        // console.log(" id :"+ session_id + " id "+device_token)
        // console.log("ExcelFile is",DocumentFile);
        // console.log("user key is",user.key);

        // const config = {     
        //   headers:  {
        //     // 'content-type': 'multipart/form-data',
        //     Authorization: "Token " + user.key,
        //   },
        //   }

        // axios.post(url, formData, config)
        // .then(response => {
        //   console.log(response);
        //   setShowProcedure(false);
        //   console.log("response",response);
        //   var data= response.data;
        //   console.log("data",data);
        //   var success= response.data.success;
        //   console.log("status",success);
        //     if(response.success == true){
        //       // setOpen(false);
        //       Swal.fire({
        //         icon: 'success',
        //         text: "Category Bulk Created Succesfully",
        //         confirmButtonColor: 'primary',
        //         confirmButtonText: 'OK',
        //         timer: 5000,
        //       })
        //       // setOpen(false);
        //       setselectedDocumentId("");
        //       setdocumentNumber("");
        //       setdocHavePass("");
        //       setdocumentPassword("");
        //       setDocumentFile("");
        //       setDocumentName("");

        //       setdocHavePassErr("");
        //       setdocPasswordErr("");
        //       setdocFileErr("");
        //     }
        //     if(response.success == false){
        //       // setOpen(true);
        //       Swal.fire({
        //         icon: 'error',
        //         text: response.errors,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'OK',
        //         timer: 5000,
        //       })
        //     }
        // })
      
    }
  }

  const refreshGetFilesFunction = () => {
    setShowProcedure(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"BUSINESS", 
        'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
      .then((Response) => Response.json())
      .then( async(caseDocuments) => {
        setShowProcedure(false);
        // setShowProcedure(false);
        console.log("caseDocuments for last level",caseDocuments);
        if(caseDocuments.success == true)
        {
          await setcaseDocuments(caseDocuments.data.case_documents)

          let forvalidation = caseDocuments.data.case_documents
          onSelectUploadFiles("5","")
        }
        else{
          await setcaseDocuments([])
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Upload Documents</h2>
      </div>
      <hr/>
      <div className={classes.modalBody}>

        <TextField
          margin="normal"
          fullWidth
          id="documentNumber"
          label="Document Details"
          name="documentNumber"
          autoComplete="documentNumber"
          autoFocus
          value={documentNumber}
          onChange={(e) => setdocumentNumber(e.target.value) }
        />
        <Grid style={{color:"grey", fontSize: 10, marginTop:"-2%"}}>
          Optional Field
        </Grid>

        <Grid container>
          <Grid item xs={12} className={classes.subHeadingLabel}>
            Is the document password protected?*
          </Grid>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={docHavePass}
            defaultValue={docHavePass}
            onChange={changeRadioDocumentHavePass}
          >
            <div className={classes.customRadioButton}>
              <FormControlLabel
                value="true"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Yes"
              />
            </div>
            <div className={classes.customRadioButton}>
              <FormControlLabel
                value="false"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "No"
              />
            </div>
          </RadioGroup>
        </Grid>
        <div className={classes.validationMaxTopMargin}>{(docHavePass)?(<div></div>):(docHavePassErr)}</div>

        {
          (docHavePass == "true")?
          (
            <div>
              {/* <TextField
                margin="normal"
                fullWidth
                id="documentPassword"
                label="Document Password"
                name="documentPassword"
                type="password"
                autoComplete="documentPassword"
                inputProps={{ maxLength: 15 }}
                value={documentPassword}
                onChange={(e) => setdocumentPassword(e.target.value) }
              /> */}
              <ThemeProvider theme={theme} >
              <FormControll sx={{ width: '100%' }} variant="outlined" style={{ marginTop: '4.5%' }}>
                <InputLabel style={{marginLeft:'-3.2%'}}>Document Password</InputLabel>
                <Input
                  color="primary"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword ? 'hide the password' : 'display the password'
                        }
                        onClick={() => handleClickShowPassword()}
                        onMouseDown={(e) => handleMouseDownPassword(e)}
                        onMouseUp={(e) => handleMouseUpPassword(e)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // inputProps={{ maxLength: 8 }}
                  required
                  name="documentPassword"
                  id="documentPassword"
                  label="Document Password"
                  value={documentPassword}
                  autoFocus
                  onChange={(e) => setdocumentPassword(e.target.value) }
                />
              </FormControll>
            </ThemeProvider>
              <div className={classes.validation}>{(documentPassword)?(<div></div>):(docPasswordErr)}</div>
            </div>
          )
          :(<div></div>)
        }

        <div style={{marginTop:"8%"}}>
          <input type='file' 
            // accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadDocument(e)} 
          />
        </div>
        <div className={classes.validationMaxTopMargin}>{(DocumentFile)?(<div></div>):(docFileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitUploadDocument}
          >
            Upload
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelUploadDocumentClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

    // add more doc functions
    const handleAddMoreDocumentsNewOpen = () => {
      setopenAddDocumentsNew(true)
    }
  
    const handleAddMoreDocumentsNewClose = () => {
      setopenAddDocumentsNew(false)
      setdocumentTypeNew("");
      setdocumentNumberNew("");
      setdocHavePassNew("");
      setdocumentPasswordNew("");
      setDocumentFileNew("");
      setDocumentNameNew("");
      setdocTypeNewErr("");
      setdocHavePassNewErr("");
      setdocPasswordNewErr("");
      setShowPassword(false);
      setdocFileNewErr("");
    }
  
    const changeRadioDocumentHavePassNew = (e) => {
      // setdocHavePass(JSON.parse(e.target.value));
      setdocHavePassNew(e.target.value);
    }
  
    const onUploadDocumentNew= (event) => {
      console.log("event.target.files[0]",event.target.files[0])
      console.log("event.target.files[0] name",event.target.files[0].name)
      console.log("uri",event.target.files.uri)
      setDocumentNameNew(event.target.files[0].name);
      setDocumentFileNew(event.target.files[0])
    }
  
    const validateAddMoreDoc = () => {
      let docTypeNewErr = '';
      let docHavePassNewErr = '';
      let docPasswordNewErr = '';
      let docFileNewErr = '';
  
      if ( !documentTypeNew ){
        docTypeNewErr = "Please Select Document Type";
      }
  
      if( !docHavePassNew ){
        docHavePassNewErr = "Please Select If Document Has Password or Not ";
      }
  
      if( docHavePassNew == "true" || docHavePassNew == true) {
        if( !documentPasswordNew ){
          console.log("in Please Enter Document Password")
          docPasswordNewErr = "Please Enter Document Password";
        }
      }
  
      if( !DocumentFileNew ){
        docFileNewErr = "Please Select Document To Upload";
      }
  
  
      if( docTypeNewErr || docHavePassNewErr || docPasswordNewErr || docFileNewErr){
          setdocTypeNewErr(docTypeNewErr);
          setdocHavePassNewErr(docHavePassNewErr);
          setdocPasswordNewErr(docPasswordNewErr);
          setdocFileNewErr(docFileNewErr);
  
          return false;
        }
        return true;
    }
  
    const handelSubmitAddtMoreDocumentsNew = () => {
      console.log("documentTypeNew,documentNumberNew,docHavePassNew,documentPasswordNew,DocumentFileNew",
      documentTypeNew,documentNumberNew,docHavePassNew,documentPasswordNew,DocumentFileNew);
      let user = JSON.parse(localStorage.getItem('user'));
      const isValid = validateAddMoreDoc();
      if(isValid){
        setopenAddDocumentsNew(false);
        setShowProcedure(true);

        const requestOptionsSecond = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({
            'doc_type': 'case_document', 'doc_name': DocumentFileNew.name, 'doc_extra_info': '',
            'device_token': device_token, 'session_id': session_id, 'business_id': business_id
          })
        }
        return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)

          if (checkResponseOfS3Upload.success === true) {
            // console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
            console.log("in success checkResponseOfS3Upload.data.upload_info.url", checkResponseOfS3Upload.data.upload_info.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", DocumentFileNew);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)


                // let formdata = new FormData();
                // formdata.append('case_id', newCaseCreatedCaseIdRedux);
                // formdata.append('doc_type_id', documentTypeNew);
                // formdata.append('doc_no', documentNumberNew);
                // formdata.append('is_protected', JSON.parse(docHavePassNew));
                // formdata.append('password', documentPasswordNew);
                // formdata.append('document', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);
          
                const requestOptions = {
                  method: 'POST',
                  headers:  {
                    Authorization: "Token " + user.key,
                  },
                  // body: formdata,
                  body: JSON.stringify({
                    'case_id': newCaseCreatedCaseIdRedux, 'doc_type_id': documentTypeNew, 'doc_no': documentNumberNew, 
                    'is_protected': JSON.parse(docHavePassNew),
                    'password': documentPasswordNew, 
                    // 'document': checkResponseOfS3Upload.data.public_url.url, 
                    'document': checkResponseOfS3Upload.data.upload_info.fields.key, 
                    "uuid": checkResponseOfS3Upload.data.upload_info.uuid,
                    'device_token': device_token, 'session_id': session_id,
                    'business_id': business_id
                  })
                };
          
                console.log("requestOptions of add more doc",requestOptions)
                return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
                  .then((Response) => Response.json())
                  .then( documentUploaded => {
                    setShowProcedure(false);
                    console.log("documentUploaded",documentUploaded.success, documentUploaded);
                      if(documentUploaded.success == true){
                        setopenAddDocumentsNew(false);
                        Swal.fire({
                          icon: 'success',
                          text: "File Uploaded Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                        setopenAddDocumentsNew(false)
                        setdocumentTypeNew("");
                        setdocumentNumberNew("");
                        setdocHavePassNew("");
                        setdocumentPasswordNew("");
                        setDocumentFileNew("");
                        setDocumentNameNew("");
                        setdocTypeNewErr("");
                        setdocHavePassNewErr("");
                        setdocPasswordNewErr("");
                        setdocFileNewErr("");
                        setShowPassword(false);
                        refreshGetFilesFunction();
                      }
                      if(documentUploaded.success == false){
                        setopenAddDocumentsNew(true);
                        Swal.fire({
                          icon: 'error',
                          text: documentUploaded.errors,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      }           
                  })
                  .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                  });
              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }

        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })

        // let formdata = new FormData();
        // formdata.append('case_id', newCaseCreatedCaseIdRedux);
        // formdata.append('doc_type_id', documentTypeNew);
        // formdata.append('doc_no', documentNumberNew);
        // formdata.append('is_protected', JSON.parse(docHavePassNew));
        // formdata.append('password', documentPasswordNew);
        // formdata.append('document', DocumentFileNew);
        // formdata.append('device_token', device_token);
        // formdata.append('session_id', session_id);
  
        // const requestOptions = {
        //   method: 'POST',
        //   headers:  {
        //     Authorization: "Token " + user.key,
        //   },
        //   body: formdata,
        // };
  
        // console.log("requestOptions of add more doc",requestOptions)
        // return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
        //   .then((Response) => Response.json())
        //   .then( documentUploaded => {
        //     setShowProcedure(false);
        //     console.log("documentUploaded",documentUploaded.success, documentUploaded);
        //       if(documentUploaded.success == true){
        //         setopenAddDocumentsNew(false);
        //         Swal.fire({
        //           icon: 'success',
        //           text: "File Uploaded Succesfully",
        //           confirmButtonColor: 'primary',
        //           confirmButtonText: 'OK',
        //           timer: 5000,
        //         })
        //         setopenAddDocumentsNew(false)
        //         setdocumentTypeNew("");
        //         setdocumentNumberNew("");
        //         setdocHavePassNew("");
        //         setdocumentPasswordNew("");
        //         setDocumentFileNew("");
        //         setDocumentNameNew("");
        //         setdocTypeNewErr("");
        //         setdocHavePassNewErr("");
        //         setdocPasswordNewErr("");
        //         setdocFileNewErr("");
        //       }
        //       if(documentUploaded.success == false){
        //         setopenAddDocumentsNew(true);
        //         Swal.fire({
        //           icon: 'error',
        //           text: documentUploaded.errors,
        //           confirmButtonColor: '#d33',
        //           confirmButtonText: 'OK',
        //           timer: 5000,
        //         })
        //       }           
        //   })
        //   .catch(err => {
        //     setShowProcedure(false);
        //     Swal.fire({
        //       icon: 'error',
        //       // text: "Something went wrong",
        //       text: "Server Error. Please try again.",
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK'
        //     })
        //   });
        
      }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
      const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
          
      const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
  
    const bodyAddMoreDocNew = (
      <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <h2>Add More Documents</h2>
        </div>
        <div className={classes.modalBody}>
  
          <FormControl className={classes.formControl}>
            <Grid item xs={12} style={{marginTop:"2%", color:"grey"}}>Document Type*</Grid>
            <Select
              autoFocus
              native
              labelId="documentTypeNew"
              id="documentTypeNew"
              value={documentTypeNew}
              onChange={(e) => setdocumentTypeNew(e.target.value) }
            >
              <option value="" style={{color:'grey'}}>Select Document Type</option>
                  {/* {allDocTypeDetails && allDocTypeDetails.map(item => */}
                  
                  {serviceDocTypeDetails && serviceDocTypeDetails.map(item =>
                    <option value={item.document_type_id}>{item.name}</option>
                )}
            </Select>
          </FormControl>
          <div className={classes.validation}>{(documentTypeNew)?(<div></div>):(docTypeNewErr)}</div>
  
          <TextField
            margin="normal"
            fullWidth
            id="documentNumberNew"
            label="Document Details"
            name="documentNumberNew"
            autoComplete="documentNumberNew"
            value={documentNumberNew}
            onChange={(e) => setdocumentNumberNew(e.target.value) }
          />
          <Grid style={{color:"grey", fontSize: 10, marginTop:"-2%"}}>
            Optional Field
          </Grid>
  
          <Grid container style={{marginTop:'2%'}}>
            <Grid item xs={12} className={classes.subHeadingLabel}>
              Is the document password protected?*
            </Grid>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={docHavePassNew}
              defaultValue={docHavePassNew}
              onChange={changeRadioDocumentHavePassNew}
            >
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
          <div className={classes.validation}>{(docHavePassNew)?(<div></div>):(docHavePassNewErr)}</div>
  
          <Grid style={{marginTop:'-3%'}}>
          {
            (docHavePassNew == "true")?
            (
              <div>
                {/* <TextField
                  margin="normal"
                  fullWidth
                  id="documentPasswordNew"
                  label="Document Password"
                  name="documentPasswordNew"
                  autoComplete="documentPasswordNew"
                  inputProps={{ maxLength: 15 }}
                  value={documentPasswordNew}
                  onChange={(e) => setdocumentPasswordNew(e.target.value) }
                /> */}
                <ThemeProvider theme={theme} >
                <FormControll sx={{ width: '100%' }} variant="outlined" style={{ marginTop: '4.5%' }}>
                  <InputLabel style={{marginLeft:'-3.2%'}}>Document Password</InputLabel>
                  <Input
                    color="primary"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword ? 'hide the password' : 'display the password'
                          }
                          onClick={() => handleClickShowPassword()}
                          onMouseDown={(e) => handleMouseDownPassword(e)}
                          onMouseUp={(e) => handleMouseUpPassword(e)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    // inputProps={{ maxLength: 8 }}
                    required
                    name="documentPasswordNew"
                    id="documentPasswordNew"
                    label="Document Password"
                    value={documentPasswordNew}
                    autoFocus
                    onChange={(e) => setdocumentPasswordNew(e.target.value) }
                  />
                </FormControll>
              </ThemeProvider>
                <div  className={classes.validation}>{(documentPasswordNew)?(<div></div>):(docPasswordNewErr)}</div>
              </div>
            )
            :(<div></div>)
          }
          </Grid>
  
          <div style={{marginTop:"8%"}}>
            <input type='file' 
              // accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadDocumentNew(e)} 
            />
          </div>
          <div className={classes.validationMaxTopMargin}>{(DocumentFileNew)?(<div></div>):(docFileNewErr)}</div>
  
          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitAddtMoreDocumentsNew}
            >
              Upload
            </Button>
            <div style={{marginLeft:'2%'}}></div>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick={handleAddMoreDocumentsNewClose}
              >
                Cancel
              </Button>
            </div>
        </div>
      </div>
    )


  const downloadDocumentFunc = (item) => {
    setShowProcedure(true);
    console.log("downloadDocumentFunc is clicked",item)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'doc_file_path': item.document,
        'device_token': device_token, 'session_id': session_id,
      })
    };
    return fetch(process.env.REACT_APP_GET_DOWNLOAD_INFO, requestOptions)
    .then((Response) => Response.json())
    .then(DownloadInfo => {
    // .then((response) => response.blob())
    // .then((blob) => {
      setShowProcedure(false);
      if (DownloadInfo.success == true) {
        console.log("DownloadInfo is", DownloadInfo)
        // const link = DownloadInfo.data.download_info
        // link.click();
        window.open(DownloadInfo.data.download_info, "_blank");

        // href=DownloadInfo.data.download_info
        // Swal.fire({
        //   icon: 'success',
        //   text: ,
        //   confirmButtonColor: '#d33',
        //   confirmButtonText: 'OK'
        // })
      }
      else{
        Swal.fire({
          icon: 'error',
          text: DownloadInfo.errors,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        text: "Something went wrong",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    })
  }

  // delete document
  const handleOpneModalDeleteIdProof = (item,itemdoc ) => {
    console.log("in handleOpneModalDeleteIdProof", item)
    setDeleteDocumentDetails({ ...DeleteDocumentDetails , 
        case_id: newCaseCreatedCaseIdRedux,
        doctype_id: item.doc_type_id,
        user_document_id: itemdoc.user_document_id,
    })
    setOpenDeleteComment(true);
}

const handleDeleteCommentClose = () => {
  setDeleteDocumentDetails({ ...DeleteDocumentDetails , 
      case_id: "",
      doctype_id: "",
      user_document_id: "",
      delete_comments: "",
  })
  setOpenDeleteComment(false);
}

const bodyDeleteComment = (
  <div className={classes.paper}>
    <div className={classes.addNewBusinessHeaderBackgroud}>
      <h2>Delete Document</h2>
    </div>
    <div className={classes.modalBody}>
      <Grid style={{color: 'grey', fontSize: '12px', marginLeft:'1%' }}>
        Note: You can delete this document only if you have uploaded the document.
      </Grid>
      <Grid style={{marginTop:'4%'}}>
        <TextField
          id="deleteComment"
          name="deleteComment"
          variant="outlined"
          autoFocus
          fullWidth
          label="Comment"
          value={DeleteDocumentDetails.delete_comments}
          onChange={(e) => setDeleteDocumentDetails({ ...DeleteDocumentDetails , delete_comments: e.target.value }) }
        />
      </Grid>

      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'4%' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => handleDeletDocument()}
        >
          Delete
        </Button>
        <div style={{ marginLeft: '2%' }}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleDeleteCommentClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
)

  const handleDeletDocument = async (item) => {
  
    console.log("handleDeletDocument cleicked", item)

    // const documentDeleteData = {
    //   'case_id': newCaseCreatedCaseIdRedux,
    //   'doctype_id': item.doc_type_id,
    //   'user_document_id': item.user_uploaded.user_document_id,
    //   'delete_comments': "",
    //   'business_id': business_id,
    //   'device_token': device_token,
    //   'session_id': session_id,
    // }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(DeleteDocumentDetails)
        };
        return fetch(process.env.REACT_APP_DELETE_CASE_DOCUMENT, requestOptions)
          .then((Response) => Response.json())
          .then(DocDetailsAfterDelete => {
            setShowProcedure(false);
            setDeleteDocumentDetails({ ...DeleteDocumentDetails , 
              case_id: "",
              doctype_id: "",
              user_document_id: "",
              delete_comments: "",
            })
            setOpenDeleteComment(false);
            if (DocDetailsAfterDelete.success == true) {
              setflag(false);
              refreshGetFilesFunction();
              Swal.fire({
                icon: 'success',
                text: "Document deleted.",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK'
              })
            } else {
              Swal.fire({
                icon: 'error',
                text: DocDetailsAfterDelete.errors.toString(),
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
            return true;
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })

  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  return (
    console.log("caseDocuments",caseDocuments),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <div className={classes.wrapper}>
        <Grid>
          <Grid className={classes.markQuesLineLabel}>
            All documents marked as star(*) are mandatory
          </Grid>
        </Grid>
        <Grid container>
          <Grid container>
            {
              ( caseDocuments.length == "0")?
              (
                <Grid item xs={8} className={classes.subHeadingLabel}>
                  No documents to upload. You can add another documents using "Add more documents" or You can go to next step 
                </Grid>
              ):
              (
                <Grid item xs={8} className={classes.subHeadingLabel}>
                  Please upload following tax related "Business" documents 
                </Grid>
              )
            }
            
            {
              (role_of_user && role_of_user == "client" )?
              (
                <Grid item xs={4} style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B',}}
                >
                  <text style={{cursor:'pointer'}} 
                  onClick={ () => handleAddMoreDocumentsNewOpen()}
                > 
                  Add More Documents?
                  </text>    
                </Grid>

              ):
              (
                (role_of_user && role_of_user !== "client")?
                (
                  (user_permissions && user_permissions.permissions.add_documents )?
                    (
                      <Grid item xs={4} style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B',}}
                      >
                        <text style={{cursor:'pointer'}} 
                        onClick={ () => handleAddMoreDocumentsNewOpen()}
                      > 
                        Add More Documents?
                      </text>    
                    </Grid>
                    ):
                    (
                      <Grid></Grid>
                    )
                ):
                (
                <div></div>
                )
              )

              
            }

          </Grid>
          {/* <Grid item xs={12} ></Grid> */}
          <Grid item xs={12} className={classes.subHeadingLabelList}>
            <ul style={{listStyle: "none"}}>
              {/* {caseDocuments.user_uploaded.map((item, index) => */}
              {caseDocuments.map((item, index) =>
                // {/* List of doc types */}
                <li key={item.doc_type_id}>

                  <Grid>
                        <Grid container item xs={12} >
                          <Grid item xs={6} style={{ color:'black'}}>
                            {index + 1}{"."}&nbsp;&nbsp;{item.doc_type_name} { item.is_optional == false ? <text>*</text> : null}
                          </Grid>
                          <Grid item xs={6} className={classes.moreVertIcon} justifyContent="flex-start">
                            <Tooltip
                              title="Select Document"
                              placement="left"
                            >
                              <BackupIcon onClick={() => uploadDocumentFunc(item)}/>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      {
                        ( item.user_uploaded.length > 0)?
                        (
                          <Grid>
                            {item.user_uploaded.map((itemdoc, indexdoc) =>
                              <li>
                                <Grid container item xs={12} >
                                  <Grid item xs={6} style={{ color:'black'}}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{indexdoc + 1}{")"}&nbsp;&nbsp;{itemdoc.document.substring(itemdoc.document.lastIndexOf('/')+1)} 
                                    {/* { item.is_optional == false ? <Grid>*</Grid> : null} */}
                                  </Grid>
                                  <Grid container item xs={6}>
                                    <Grid item  className={classes.moreVertIcon} >
                                      <a
                                        onClick={() => downloadDocumentFunc(itemdoc)}
                                        target = "_blank"
                                      >
                                        <CloudDownloadIcon style={{color:'black'}}/>
                                      </a>
                                    </Grid>
                                    <Grid item style={{marginLeft:"5%"}} className={classes.moreVertIcon}>
                                      <CancelIcon 

                                        onClick={() => handleOpneModalDeleteIdProof(item,itemdoc)}
                                      />
                                    </Grid>
                                  </Grid>
                                  
                                </Grid>
                              </li>
                            )}
                          </Grid>
                          
                        ):
                        (
                          <>
                          </>
                        )
                      }
                  </Grid>
                </li>
            )}
            </ul>
          </Grid>
          <Modal
            open={open}
            onClose={handelUploadDocumentClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>

          <Modal
            open={openAddDocumentsNew}
            onClose={handleAddMoreDocumentsNewClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddMoreDocNew}
          </Modal>

        </Grid>
      </div>
      <Modal
                    open={OpenDeleteComment}
                    onClose={handleDeleteCommentClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyDeleteComment}
                </Modal>
    </div>
  );
}

export default injectIntl(EditCaseUploadFileClientCases);


{/* <li key={item.doc_type_id}>
                {console.log("item.user_uploaded",item.user_uploaded)}
                <Grid container>
                  <Grid container item xs={11} style={{ color:'black'}}>
                    {
                      (item.user_uploaded.document)?
                      (
                        <>
                        {index + 1}{"."}&nbsp;&nbsp;{item.user_uploaded.document.substring(item.user_uploaded.document.lastIndexOf('/')+1)} { item.is_optional == false ? <Grid>*</Grid> : null}
                        </>
                      ):
                      (
                        <>
                        {index + 1}{"."}&nbsp;&nbsp;{item.doc_type_name} { item.is_optional == false ? <Grid>*</Grid> : null}
                        </>
                      )
                    }
                  </Grid>
                  {
                    ( item.user_uploaded.document == undefined )?
                    (
                      <Grid item xs={1} className={classes.moreVertIcon}>
                        <Tooltip
                          title="Select Document"
                          placement="left"
                        >
                          <BackupIcon onClick={() => uploadDocumentFunc(item)}/>
                        </Tooltip>
                      </Grid>
                    ):
                    (
                      <Grid container item xs={1}>
                        <Grid item  className={classes.moreVertIcon} >
                          <a
                            onClick={() => downloadDocumentFunc(item)}
                            target = "_blank"
                          >
                            <CloudDownloadIcon style={{color:'black'}}/>
                          </a>
                        </Grid>
                        <Grid item style={{marginLeft:"10%"}} className={classes.moreVertIcon}>
                          <CancelIcon 

                            onClick={() => handleOpneModalDeleteIdProof(item)}
                          />
                        </Grid>
                      </Grid>
                    )
                  }
                
                </Grid>
              </li> */}