import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //   display: "flex",
    // 
    // background: "#ffffff",
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },

  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
    marginLeft: "-10%",
  },
  subheadingTitle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    // fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  ProcessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "5%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "500",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    // marginBottom: "10px",
    textAlign: "left",
  },
  createButtonContainer: {
    marginTop: "2%",
  },

}));

export default function EditClientSelf(props) {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [navigatedFrom, setNavigatedFrom] = React.useState(JSON.parse(localStorage.getItem('navigated_to_edit_self_client_details')));
  const [ClientDetailsToEdit, setClientDetailsToEdit] = React.useState(JSON.parse(localStorage.getItem('selected_Client_To_Edit_Self_Details')));
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in', 'us', 'gb', 'ca', 'ae', 'sg', 'np', 'jp', 'de', 'bt', 'au'
  ]);
  const [ClientDetailsEdit, setClientDetailsEdit] = React.useState({

    // is_active: true,
    contact_phone: "",
    business_id: JSON.parse(localStorage.getItem('selected_business_id')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  })

  const [PhoneNumber, setPhoneNumber] = React.useState();

  const [CountryCode, setCountryCode] = React.useState();
  const [showPhoneIp, setshowPhoneIp] = React.useState(true);
  const [OtherContactCountryCode, setOtherContactCountryCode] = React.useState();

  const [nameErr, setnameErr] = React.useState("");
  const [emailErr, setemailErr] = React.useState("");
  const [phoneErr, setphoneErr] = React.useState("");
  const [panErr, setpanErr] = React.useState("");
  const [firstNameErr, setfirstNameErr] = React.useState();
  // const[middleNameErr, setmiddleNameErr] = React.useState();
  const [lastNameErr, setlastNameErr] = React.useState();
  const [contactEmailErr, setcontactEmailErr] = React.useState();
  const [contactPanErr, setcontactPanErr] = React.useState();
  const [contactPhoneErr, setcontactPhoneErr] = React.useState();

  const [RedirectToClientTable, setRedirectToClientTable] = React.useState(false);
  const [RedirectToSearchResult, setRedirectToSearchResult] = React.useState(false);
  const [RedirectToTaxManagementCasesTableTrpAdmin, setRedirectToTaxManagementCasesTableTrpAdmin] = React.useState(false);
  const [RedirectToTaxManagemantCaseDetails, setRedirectToTaxManagemantCaseDetails] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setShowProcedure(true);

    let clientDetailsHere = JSON.parse(localStorage.getItem('selected_Client_To_Edit_Self_Details'));
    console.log("clientDetailsHere", clientDetailsHere, clientDetailsHere.name);
    setClientDetailsEdit({
      ...ClientDetailsEdit,
      client_type: clientDetailsHere.client_type,
      
      client_id: clientDetailsHere.client_id,
      // name: clientDetailsHere.name,
      first_name: clientDetailsHere.first_name,
      middle_name: clientDetailsHere.middle_name,
      surname: clientDetailsHere.surname,

      email: clientDetailsHere.email,
      phone: clientDetailsHere.phone,
      country_code: clientDetailsHere.country_code,
      client_pan: clientDetailsHere.client_pan,
      address: "",

      // org_name: clientDetailsHere.email,
      org_name: clientDetailsHere.org_name,
      contact_name: clientDetailsHere.contact_name,
      contact_email: clientDetailsHere.contact_email,
      contact_pan: clientDetailsHere.contact_pan,
      contact_countrycode: clientDetailsHere.contact_countrycode,
      contact_phone: clientDetailsHere.contact_phone,

      self_assessment: clientDetailsHere.self_assessment,
      // phone_verified: clientDetailsHere.phone_verified,
    })

    let phoneNumbereHere = clientDetailsHere.country_code + clientDetailsHere.phone
    console.log("phoneNumbereHere",phoneNumbereHere)
    setPhoneNumber(phoneNumbereHere)

    setShowProcedure(false);

  }, []);

  const phoneNumberChange = (clientNumber, e, formattedValue, value) => {
    console.log("phoneNumberChange clientNumber", clientNumber)
    console.log("phoneNumberChange e", e)
    console.log("phoneNumberChange value", value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""
    if (e.countryCode == "in") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "us") {
      // splitNoIs = value.split(" ")[1];
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "gb") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ca") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ae") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "sg") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "np") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "jp") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "de") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "bt") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "au") {
      splitNoIs = value.split(" ")[1];
    }
    console.log("splitNoIs", splitNoIs)
    let noHereIs = "+" + e.dialCode + "-" + splitNoIs;
    console.log("noHereIs", noHereIs)
    setCountryCode(e.countryCode)
    // setClientDetailsEdit({ ...ClientDetailsEdit, phone: noHereIs })
    setClientDetailsEdit({...ClientDetailsEdit, phone:splitNoIs , country_code:countryCodeIs })
  }

  const phoneNumberChangeContact = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumber Change clientNumber",clientNumber)
    console.log("phoneNumber Change e",e)
    console.log("phoneNumber Change value",value, e.dialCode)
    let splitNoIs = ""
    let countryCodeIs = "+" + e.dialCode
    console.log("countryCodeIs",countryCodeIs)
    if(e.countryCode == "in"){
       splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      //  splitNoIs = value.split(" ")[1];

      // this is showing 1st digit before white space
      // splitNoIs = value.substr(0,value.indexOf(' ')); 

      // this is showing digits after white space
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }
    
    console.log("splitNoIs",splitNoIs)
    // if(formattedValue !== null){
    // console.log("formattedValue",formattedValue.target.value)
    // }
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setOtherContactCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    // setclientDetails({...clientDetails, phone:noHereIs })
    setClientDetailsEdit({...ClientDetailsEdit, contact_phone:splitNoIs , contact_countrycode:countryCodeIs })
  }

  const validate = () => {
    let nameErr = '';
    let panErr = '';
    let emailErr = '';
    let phoneErr = '';
    let firstNameErr = '';
    // let middleNameErr = '';
    let lastNameErr = '';
    let contactEmailErr = '';
    let contactPanErr = '';
    let contactPhoneErr = '';

    // if (!ClientDetailsEdit.name) {
    //   nameErr = 'Please Enter Name';
    // }
    if( ClientDetailsEdit.client_type === "person" ){
      if(!ClientDetailsEdit.first_name)  {
        firstNameErr = 'Please Enter First Name';
      }
      // if(!ClientDetailsEdit.middle_name)  {
      //   middleNameErr = 'Please Enter Middle Name';
      // }
      if(!ClientDetailsEdit.surname)  {
        lastNameErr = 'Please Enter Last Name';
      }
    }

    else if(ClientDetailsEdit.client_type === "business")
    {
      if(!ClientDetailsEdit.org_name)  {
        nameErr = 'Please Enter Name';
      }

      if(ClientDetailsEdit.contact_email) {
        if(typeof ClientDetailsEdit.contact_email !== "undefined"){
          let lastAtPos = ClientDetailsEdit.email.lastIndexOf('@');
          let lastDotPos = ClientDetailsEdit.email.lastIndexOf('.');
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
            contactEmailErr = "Email is not valid";
              if(contactEmailErr) {
                setcontactEmailErr(contactEmailErr);
              }
          }
        else {
          setcontactEmailErr();    
          }
          }
        else{
          setcontactEmailErr();
        }
      }

      if(ClientDetailsEdit.contact_pan){
        if (typeof ClientDetailsEdit.contact_pan !== "undefined") {
          var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
          if(ClientDetailsEdit.contact_pan.length !== 10){
            console.log("in != 10")
            contactPanErr = "PAN Number is Not Valid";
          }
          else if (!pattern.test(ClientDetailsEdit.contact_pan)) {
            contactPanErr = "PAN Number is Not Valid";
          } 
        }
      }
      
      if(ClientDetailsEdit.contact_phone){
        if (OtherContactCountryCode == "in"){
          console.log("ClientDetailsEdit.contact_phone.length",ClientDetailsEdit.phone.length)
            if(ClientDetailsEdit.contact_phone.length !== 10){
              contactPhoneErr = "Please enter valid phone number.";
            }
        }
        else if(OtherContactCountryCode == "us"){
          console.log("ClientDetailsEdit.contact_phone.length us",ClientDetailsEdit.phone.length)
            if(ClientDetailsEdit.contact_phone.length !== 10){
              contactPhoneErr = "Please enter valid phone number.";
            }
        }
        else if(OtherContactCountryCode == "gb"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
            if(ClientDetailsEdit.contact_phone.length !== 10){
              contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "ca"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
            if(ClientDetailsEdit.contact_phone.length !== 10){
              contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "ae"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 12){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "sg"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 8){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "np"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 12){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "jp"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 10){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "de"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 12){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "bt"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 12){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if(OtherContactCountryCode == "au"){
          console.log("ClientDetailsEdit.contact_phone.length gb",ClientDetailsEdit.phone.length)
          if(ClientDetailsEdit.contact_phone.length !== 13){
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
      }

    }

    if (!ClientDetailsEdit.client_pan) {
      console.log("iN 1ST CONDITION PAN")
      panErr = 'Please enter PAN';
    }
    else if (typeof ClientDetailsEdit.client_pan !== "undefined") {
      var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
      if (ClientDetailsEdit.client_pan.length !== 10) {
        console.log("in != 10")
        panErr = "PAN number is not valid";
      }
      else if (!pattern.test(ClientDetailsEdit.client_pan)) {
        console.log("in string check")
        panErr = "PAN number is not valid";
      }
      else if(ClientDetailsEdit.client_type === "person"){
        console.log("4th pan letter is",ClientDetailsEdit.client_pan[3])
        if( ClientDetailsEdit.client_pan[3] !== "P" ){
          panErr = "PAN number is not valid";
        }
      }
    }

    if (!ClientDetailsEdit.email) {
      emailErr = 'Please enter email';
    }
    else if (typeof ClientDetailsEdit.email !== "undefined") {
      let lastAtPos = ClientDetailsEdit.email.lastIndexOf('@');
      let lastDotPos = ClientDetailsEdit.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
        emailErr = "Email is not valid";
        if (emailErr) {
          setemailErr(emailErr);
        }
      }
      else {
        setemailErr();
      }
    }
    else {
      setemailErr();
    }

    if (!ClientDetailsEdit.phone) {
      phoneErr = 'Please enter phone';
    }
    else if (typeof ClientDetailsEdit.phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/)
      if (CountryCode == "in") {
        if (ClientDetailsEdit.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "us") {
        if (ClientDetailsEdit.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "gb") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "ca") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "ae") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "sg") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 8) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "np") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "jp") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "de") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "bt") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "au") {
        console.log("ClientDetailsEdit.phone.length gb", ClientDetailsEdit.phone.length)
        if (ClientDetailsEdit.phone.length !== 13) {
          phoneErr = "Please enter valid phone number.";
        }
      }
    }

    if (nameErr || panErr || emailErr || phoneErr || firstNameErr || lastNameErr || contactEmailErr ||
      contactPanErr || contactPhoneErr) 
    {
      setnameErr(nameErr);
      setpanErr(panErr);
      setemailErr(emailErr);
      setphoneErr(phoneErr);
      // setselfAssErr(selfAssErr);
      setfirstNameErr(firstNameErr);
      // setmiddleNameErr(middleNameErr);
      setlastNameErr(lastNameErr);
      setcontactEmailErr(contactEmailErr);
      setcontactPanErr(contactPanErr);
      setcontactPhoneErr(contactPhoneErr);

      return false;
    }
    return true;

  }

  const handleAddButton = async () => {
    const isValid = validate();
    if (isValid) {
      console.log("handleAddButton-==========", ClientDetailsEdit);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ClientDetailsEdit)
      };
      return fetch(process.env.REACT_APP_CREATE_CLIENT, requestOptions)
        .then((Response) => Response.json())
        .then(async (clientEdited) => {
          console.log("clientEdited", clientEdited.success);
          if (clientEdited.success == true) {
            Swal.fire({
              icon: 'success',
              // text: "Client Details Updated Succesfully ",
              html: `
                  <b>Client Details Updated Succesfully</b><br/>
                  Please send the invite again for successful client registration
                  
                `,
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK'
            })
            await setshowPhoneIp(false);
            await setClientDetailsEdit({
              client_id: "",
              // name: "",
              // email: "",
              // phone: "",
              // client_pan: "",
              client_type: "person",

              first_name: "",
              middle_name: "",
              surname: "",

              // name: "",
              client_pan: "",
              email: "",
              phone: "",
              country_code: "",
              address: "",

              org_name: "",
              contact_name: "",
              contact_email: "",
              contact_pan: "",
              contact_countrycode: "",
              contact_phone: "",

              self_assessment: true,
              
              // is_active: true,
              business_id: JSON.parse(localStorage.getItem('selected_business_id')),
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            });
            await setshowPhoneIp(true);
            setnameErr("");
            setpanErr("");
            setemailErr("");
            setphoneErr("");
            setfirstNameErr();
            // setmiddleNameErr();
            setlastNameErr();
            setcontactEmailErr();
            setcontactPanErr();
            setcontactPhoneErr();
            // await setRedirectToClientTable(true)

            if (navigatedFrom == "TaxManagemantClient"){
              await setRedirectToClientTable(true)
            }
            if (navigatedFrom == "SerachClient"){
              await setRedirectToSearchResult(true)
            }
            if (navigatedFrom == "TaxManagemantCasesTableTrpAdmin"){
              await setRedirectToTaxManagementCasesTableTrpAdmin(true)
            }
            if (navigatedFrom == "TaxManagemantCaseDetails"){
              await setRedirectToTaxManagemantCaseDetails(true)
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              text: clientEdited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            setnameErr("");
            setpanErr("");
            setemailErr("");
            setphoneErr("");
            setfirstNameErr();
            // setmiddleNameErr();
            setlastNameErr();
            setcontactEmailErr();
            setcontactPanErr();
            setcontactPhoneErr();
          }
        })
        // .catch(err => {
        //   Swal.fire({
        //     icon: 'error',
        //     // text: "Something went wrong",
        //     text: "Server Error. Please try again.",
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        //   })
        // });

    }
  }

  const handleReset = async () => {
    await setshowPhoneIp(false);
    console.log("rset", ClientDetailsEdit);
    await setClientDetailsEdit({
      ...ClientDetailsEdit,
      // client_id: "",

      // name: "",
      first_name: "",
      middle_name: "",
      surname: "",

      email: "",
      phone: "",  
      country_code: "",
      client_pan: "",
      
      org_name: "",
      contact_name: "",
      contact_email: "",
      contact_pan: "",
      contact_countrycode: "",
      contact_phone: "",

      // is_active: true,
      // business_id: JSON.parse(localStorage.getItem('selected_business_id')),
      // device_token: JSON.parse(localStorage.getItem('device_token')),
      // session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    await setshowPhoneIp(true);
  }

  const handleCancel = async () => {
    if (navigatedFrom == "TaxManagemantClient"){
      await setRedirectToClientTable(true)
    }
    if (navigatedFrom == "SerachClient"){
      await setRedirectToSearchResult(true)
    }
    if (navigatedFrom == "TaxManagemantCasesTableTrpAdmin"){
      await setRedirectToTaxManagementCasesTableTrpAdmin(true)
    }
    if (navigatedFrom == "TaxManagemantCaseDetails"){
      await setRedirectToTaxManagemantCaseDetails(true)
    }
  }

  if (RedirectToClientTable == true) {
    return <Redirect to={`/client`} />
  }
  if (RedirectToSearchResult == true){
    return <Redirect to={`/searchresult`} />
  }
  if (RedirectToTaxManagementCasesTableTrpAdmin == true){
    return <Redirect to={`/cases`} />
  }
  if ( RedirectToTaxManagemantCaseDetails == true ){
    return <Redirect to={`/TaxManagementCaseDetails`} />
  }
  else {
    return (
      // console.log("ClientDetailsEdit in reurn async storage",ClientDetailsToEdit),
      console.log("ClientDetailsToEdit in reurn from api",ClientDetailsToEdit),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
        </modal> : null}
        <div className={classes.wrapper}>
          <Grid container>
            <Grid>
              {
                (navigatedFrom && navigatedFrom == "TaxManagemantClient") ?
                (
                  <Button href="/client" className={classes.backButton}>
                    <span className={classes.backArrow}>
                      <ArrowBackIcon fontSize="small" />
                    </span>
                    <FormattedMessage id="backLabel" />
                  </Button>
                ) :
                (
                  (navigatedFrom && navigatedFrom == "SerachClient") ?
                  (
                    <Button href="/searchresult" className={classes.backButton}>
                      <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                      </span>
                      <FormattedMessage id="backLabel" />
                    </Button>
                  ) :
                  (
                    ( navigatedFrom && navigatedFrom == "TaxManagemantCasesTableTrpAdmin" )?
                    (
                      <Button href="/cases" className={classes.backButton}>
                        <span className={classes.backArrow}>
                          <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                      </Button>
                    ):
                    (
                      ( navigatedFrom && navigatedFrom == "TaxManagemantCaseDetails"  )?
                      (
                        <Button href="/TaxManagementCaseDetails" className={classes.backButton}>
                          <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                          </span>
                          <FormattedMessage id="backLabel" />
                        </Button>
                      ):
                      (<Grid></Grid>)
                    )
                  )
                )
              }
            </Grid>
            <Grid container className={classes.pageHeaderContainer} style={{ marginTop: '1%' }}>
              <Grid item xs={12} className={classes.pageHeaderLabel}>
                Edit Client Details
              </Grid>
            </Grid>
          </Grid>
          <Paper className={classes.paper} style={{ marginTop: '-3%' }}>

            <Grid>
              
              <Grid>
                {
                  (ClientDetailsEdit.client_type === "person")?
                  (
                    <Grid>
                      <Grid className={classes.subheadingTitle}>
                        Client Name : {ClientDetailsEdit.first_name}
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            autoFocus
                            margin="normal"
                            required
                            id="name"
                            // id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            // size="small"
                            label="First Name"
                            // name="name"
                            value={ClientDetailsEdit.first_name}
                            onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, first_name:e.target.value }) }
                          />
                          <div className={classes.validation}>{(ClientDetailsEdit.first_name)?(<div></div>):(firstNameErr)}</div>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            margin="normal"
                            // required
                            id="name"
                            // id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            // size="small"
                            label="Middle Name"
                            name="name"
                            value={ClientDetailsEdit.middle_name}
                            onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, middle_name:e.target.value }) }
                          />
                          {/* <div className={classes.validation}>{(ClientDetailsEdit.middle_name)?(<div></div>):(middleNameErr)}</div> */}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            margin="normal"
                            required
                            id="name"
                            // id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            // size="small"
                            label="Surname"
                            name="name"
                            value={ClientDetailsEdit.surname}
                            onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, surname:e.target.value }) }
                          />
                          <div className={classes.validation}>{(ClientDetailsEdit.surname)?(<div></div>):(lastNameErr)}</div>
                        </Grid>  
                      </Grid>

                    </Grid>
                  ):
                  (
                    console.log("ClientDetailsEdit.contact_phone in ui",ClientDetailsEdit && ClientDetailsEdit.contact_phone.length),
                    <Grid>
                      <Grid className={classes.subheadingTitle}>
                        Client Name : {ClientDetailsToEdit.org_name}
                      </Grid>

                      <Grid item xs={12}>
                        <Grid>
                          <TextField
                            autoFocus
                            margin="normal"
                            required
                            id="name"
                            // id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            // size="small"
                            // label="Client Name"
                            label="Organization Name"
                            name="name"
                            value={ClientDetailsEdit.org_name}
                            onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, org_name:e.target.value }) }
                          />
                          <div className={classes.validation}>{(ClientDetailsEdit.org_name)?(<div></div>):(nameErr)}</div>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              label="Contact Name"
                              name="name"
                              value={ClientDetailsEdit.contact_name}
                              onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, contact_name:e.target.value }) }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              label="Contact Email"
                              name="name"
                              value={ClientDetailsEdit.contact_email}
                              onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, contact_email:e.target.value }) }
                            />
                            <div className={classes.validation}>{(contactEmailErr)?(contactEmailErr):(<div></div>)}</div>
                          </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              placeholder="XXXXX0000X"
                              label="Contact Pan"
                              name="name"
                              value={ClientDetailsEdit.contact_pan}
                              onChange={(e) => setClientDetailsEdit({...ClientDetailsEdit, contact_pan:e.target.value.toUpperCase() }) }
                            />
                            <div className={classes.validation}>{(contactPanErr)?(contactPanErr):(<div></div>)}</div>
                          </Grid>

                          <Grid item xs={6} style={{marginTop:'1.5%'}}>
                            <PhoneInput
                              inputStyle={{
                                width: "100%",
                                height: "55px",
                                fontSize: "15px",
                              }}
                              countryCodeEditable={false}
                              onlyCountries={onlyCountryArray}
                              country={'in'}
                              specialLabel="Contact Mobile Number"
                              masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                                      sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                                      au: '.............'  }}

                              // value={ClientDetailsEdit && ClientDetailsEdit.contact_countrycode + ClientDetailsEdit.contact_phone}
                              value={ ClientDetailsEdit && ClientDetailsEdit.contact_phone.length !== 0 ? ClientDetailsEdit && ClientDetailsEdit.contact_countrycode + ClientDetailsEdit.contact_phone : ""}
                              onChange={(clientNumber,e,formattedValue,value) => 
                                phoneNumberChangeContact(clientNumber,e,formattedValue,value)}
                            />
                            <div className={classes.validation}>{(contactPhoneErr)?(contactPhoneErr):(<div></div>)}</div>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  )
                }
              </Grid>
              

              {/* <Grid item xs={12} style={{ marginTop: '1%' }}>
                <TextField
                  autoFocus
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  name="name"
                  label="Name"
                  defaultValue={ClientDetailsEdit && ClientDetailsEdit.name}
                  value={ClientDetailsEdit.name}
                  onChange={(e) => setClientDetailsEdit({ ...ClientDetailsEdit, name: e.target.value })}
                />
                <div className={classes.validation}>{(ClientDetailsEdit.name) ? (<div></div>) : (nameErr)}</div>
              </Grid> */}

              <Grid item xs={12} style={{ marginTop: '1%' }}>
                {/* <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                          Email ID*
                      </Grid> */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  label="Email ID"
                  // inputProps={{ style: { textTransform: "lowercase" } }}
                  value={ClientDetailsEdit.email}
                  onChange={(e) => setClientDetailsEdit({ ...ClientDetailsEdit, email: e.target.value.toLowerCase() })}
                />
                <div className={classes.validation}>{emailErr}</div>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '1%' }}>
                {/* <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                          PAN*
                      </Grid> */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="XXXXX0000X"
                  label="PAN"
                  name="pan"
                  inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                  value={ClientDetailsEdit.client_pan}
                  onChange={(e) => setClientDetailsEdit({ ...ClientDetailsEdit, client_pan: e.target.value.toUpperCase() })}
                />
                <div className={classes.validation}>{panErr}</div>
              </Grid>

              {/* <Grid item xs={12} className={classes.subHeadingLabel} style={{marginTop:'3%'}}>
                        Phone Number*
                    </Grid> */}
              <Grid item xs={12} style={{ marginTop: '2%' }}>
                {

                  (showPhoneIp == true) ?
                    (
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          height: "50px",
                          fontSize: "15px",
                        }}
                        countryCodeEditable={false}
                        onlyCountries={onlyCountryArray}
                        masks={{
                          in: '..........', us: '..........', gb: '..........', ca: '..........', ae: '............',
                          sg: '........', np: '............', jp: '..........', de: '............', bt: '............',
                          au: '.............'
                        }}
                        country={'in'}
                        specialLabel="Phone Number*"
                        // value={PhoneNumber}
                        value={ClientDetailsEdit.country_code + ClientDetailsEdit.phone}
                        onChange={(usersNumber, e, formattedValue, value) => phoneNumberChange(usersNumber, e, formattedValue, value)}
                      />
                    ) :
                    (<Grid></Grid>)
                }
              </Grid>
              <div className={classes.validation}>{phoneErr}</div>

              <Grid container item xs={12} style={{ marginLeft: '35%' }} className={classes.createButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleAddButton}
                >
                  Update
                </Button>
                <div style={{ marginLeft: '2%' }}></div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <div style={{ marginLeft: '2%' }}></div>
                <Button
                  variant="contained"
                  // color="primary"
                  className={classes.submit}
                  // href="/client"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>

            </Grid>

          </Paper>

        </div>
      </div>
    )
  }

}