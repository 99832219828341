import React, { useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Paper from "@material-ui/core/Paper";
import AntSwitch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ClientPhoneNumbers, comparator) {
  const stabilizedThis = ClientPhoneNumbers && ClientPhoneNumbers.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'group_id', numeric: false, label: 'Group Id' ,},
  { id: '', numeric: false, label: '#', },
  { id: 'phone', numeric: false, label: 'Contact Number', },
  { id: 'phone_type', numeric: false, label: 'Contact Number Type', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions" || headCell.id === "") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17 }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, }}
                // style= {headCell.id === "" ?  {width:"5%",fontWeight: 'bold', fontSize: 17, } : {fontWeight: 'bold', fontSize: 17,}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    // <span className={classes.visuallyHidden}>
                    <span style={{border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                      // width: 1,
                    }}
                    >
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    lexGrow: 1,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModalEditPhone: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display:'block'
  },
  editPhoneHeaderBackgroud: {
    marginTop:"1%",
    backgroundColor: 'white',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  modalBodyEditPhone: {
    padding: theme.spacing(1, 4, 3),
    width: '100%',
    height:'70%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'auto',
  },
  modalFooter: {
    padding: theme.spacing(0, 4, 2),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },

}));

export default function ClientMobileNumbers() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [flag, setflag] = React.useState(false);
  const [ShowProcedure, setShowProcedure] = React.useState(false);

  const [ClientPhoneNumbers, setClientPhoneNumbers] = React.useState([]);
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const [deletePhoneNumberDetails, setdeletePhoneNumberDetails] = React.useState({
    phone_id: "",
    phone: "",
    country_code: "",
    phone_type: "",
    is_active: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')), 
  });
  const [EditPhoneNumberDetails, setEditPhoneNumberDetails] = React.useState({
    phone_id: "",
    phone: "",
    country_code: "",
    phone_type: "",
    is_active: "",
    client_id: JSON.parse(localStorage.getItem('selected_Client_Showin_Ui')),
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')), 
  });
  const [OpenEditPhoneModal, setOpenEditPhoneModal] = React.useState(false);
  const [CountryCodeClients, setCountryCodeClients] = React.useState("");
  const [phoneNumberErr, setphoneNumberErr] = React.useState("");
  const [phoneTypeErr, setphoneTypeErr] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ClientPhoneNumbers && ClientPhoneNumbers.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };


  useEffect(() => {
    
    setflag(true);
    let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'client_id': selected_Client_Showin_Ui,
        'device_token': device_token, 'session_id': session_id, 'business_id': business_id
      })
    };
    fetch(process.env.REACT_APP_GET_CLIENT_DETAILS, requestOptions)
      .then((Response) => Response.json())
      .then(async (ClientPhoneNumbers) => {
        setShowProcedure(false);
        // console.log("ClientPhoneNumbers from api", ClientPhoneNumbers.data.client_details.phone_numbers,
        // ClientPhoneNumbers.data.client_details.user_id);
        // console.log("ClientPhoneNumbers.data.client_details.phone_numbers.lenght", 
        //   ClientPhoneNumbers.data.client_details.phone_numbers.length,
        //   );

        if (ClientPhoneNumbers.success == true) {

          let LengthofFirstArray = ClientPhoneNumbers.data.client_details.phone_numbers.length
          console.log(" LengthofFirstArray === ", LengthofFirstArray)
  
          if (LengthofFirstArray === 0) {
            console.log(" array length is 0")
            setClientPhoneNumbers([]);
          }
          else {
            console.log(" array length is not 0")
            let ActiveNumbers = ClientPhoneNumbers.data.client_details.phone_numbers.phonenumbers;
            let InActiveNumbers = ClientPhoneNumbers.data.client_details.phone_numbers.phonenumbers_log;
            let CombineArrayOfNumbers = [...ActiveNumbers, ...InActiveNumbers]
            setClientPhoneNumbers(CombineArrayOfNumbers)
          }

            // if( ClientPhoneNumbers.data.client_details.phone_numbers.lenght > 0 ){
            //   let ActiveNumbers = ClientPhoneNumbers.data.client_details.phone_numbers.phonenumbers;
            //   let InActiveNumbers = ClientPhoneNumbers.data.client_details.phone_numbers.phonenumbers_log;
            //   let CombineArrayOfNumbers = [...ActiveNumbers, ...InActiveNumbers]
            //   setClientPhoneNumbers(CombineArrayOfNumbers)
            // }
            // else{
            //   setClientPhoneNumbers([]);
            // }

            // setClientPhoneNumbers(ClientPhoneNumbers.data.client_details.phone_numbers.phonenumbers)
        }
        
        else if (ClientPhoneNumbers.success == false) {
            setClientPhoneNumbers([]);
          Swal.fire({
            icon: 'error',
            text: ClientPhoneNumbers.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
    // .catch(err => {
    //   setShowProcedure(false);
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Server Error. Please try again.",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // });

  }, [flag]);



const handleDeletePhoneNumber = async(item) => {
    setShowProcedure(true);
    console.log("in delete",item);
    let idtoDelete =  item.phone_id;
    await setdeletePhoneNumberDetails({
      phone_id: item.phone_id,
      phone: item.phone,
      country_code: item.country_code,
      phone_type: item.phone_type,
      is_active: !item.is_active,
      client_id: JSON.parse(localStorage.getItem('selected_Client_Showin_Ui')),
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    console.log("deletePhoneNumberDetails",deletePhoneNumberDetails);

    let updated_by_here = '';
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    console.log("Role of user",role_of_user)
    if( role_of_user === "client"){
      updated_by_here = "self"
    }
    else{
      updated_by_here = "business"
    }

    let deletePhoneNumberDetailsFinal = ({
      phone_id: item.phone_id,
      phone: item.phone,
      country_code: item.country_code,
      phone_type: item.phone_type,
      is_active: !item.is_active,
      updated_by: updated_by_here,
      client_id: JSON.parse(localStorage.getItem('selected_Client_Showin_Ui')),
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    console.log("deletePhoneNumberDetailsFinal",deletePhoneNumberDetailsFinal);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(deletePhoneNumberDetailsFinal)
      };
      return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
        .then((Response) => Response.json())
        .then( phoneDeleted => {
          setShowProcedure(false);
          
          console.log("phoneDeleted",phoneDeleted);
          if(phoneDeleted.success == true){
            console.log("phoneDeleted success",phoneDeleted);
            setdeletePhoneNumberDetails({
              phone_id: "",
              phone: "",
              country_code: "",
              phone_type: "",
              is_active: false,
              business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')), 
            });
          }
          if(phoneDeleted.success == false ){
            Swal.fire({
              icon: 'error',
              text: phoneDeleted.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          } 
        setflag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handelEditPhoneNumber = (item) => {
    console.log("handelEditPhoneNumber", item)
    setEditPhoneNumberDetails({
      ...EditPhoneNumberDetails,
      phone_id: item.phone_id,
      phone: item.phone,
      country_code: item.country_code,
      phone_type: item.phone_type,
      is_active: item.is_active,
    })
    setOpenEditPhoneModal(true)
  }

  // const handelEditPhoneOpen = () => {
    
  // }

  const handelEditPhoneClose = () => {
    setOpenEditPhoneModal(false)
    setEditPhoneNumberDetails({
      ...EditPhoneNumberDetails,
      phone_id: "",
      phone: "",
      country_code: "",
      phone_type: "",
      is_active: "",
    })
    setphoneNumberErr("");
    setphoneTypeErr("");
  }

  const phoneNumberEditClients = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange clientNumber",clientNumber)
    console.log("phoneNumberChange e",e)
    console.log("phoneNumberChange value",value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""

    if(e.countryCode == "in"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      // splitNoIs = value.split(" ")[1];
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }
    console.log("splitNoIs",splitNoIs)
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setCountryCodeClients(e.countryCode)
    setEditPhoneNumberDetails({...EditPhoneNumberDetails, phone: splitNoIs, country_code: countryCodeIs })
  }

  const validateAddNewPhoneNumber = () => {
    console.log("CountryCodeClients is",CountryCodeClients)
    let phoneNumberErr = '';
    let phoneTypeErr = '';

    if(!EditPhoneNumberDetails.phone)  {
      phoneNumberErr = 'Please Enter Phone Number';
    }
    else if (typeof EditPhoneNumberDetails.phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/)

      if (CountryCodeClients == "in"){
          if(EditPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCodeClients == "us"){
        if(EditPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCodeClients == "gb"){
        if(EditPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "ca"){
        if(EditPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "ae"){
        if(EditPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "sg"){
        if(EditPhoneNumberDetails.phone.length !== 8){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "np"){
        if(EditPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "jp"){
        if(EditPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "de"){
        if(EditPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "bt"){
        if(EditPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "au"){
        if(EditPhoneNumberDetails.phone.length !== 13){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
    }
    if(!EditPhoneNumberDetails.phone_type)  {
      phoneTypeErr = 'Please Select Phone Type';
    }

    if(phoneNumberErr || phoneTypeErr ){
      setphoneNumberErr(phoneNumberErr);
      setphoneTypeErr(phoneTypeErr);
      return false;
    }
    return true;
  }

  const handleEditPhoneSubmit = () => {
    console.log(" update phone details ", EditPhoneNumberDetails)
    setShowProcedure(true);
    let updated_by_here = '';
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    console.log("Role of user",role_of_user)
    if( role_of_user === "client"){
      updated_by_here = "self"
    }
    else{
      updated_by_here = "business"
    }

    let EditPhoneNumberDetailsHere = { ...EditPhoneNumberDetails, 
      'updated_by': updated_by_here
    }
    console.log(" update phone details EditPhoneNumberDetailsHere ", EditPhoneNumberDetailsHere)

    const isValid = validateAddNewPhoneNumber();
    if(isValid){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(EditPhoneNumberDetailsHere)
      };
      return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
        .then((Response) => Response.json())
        .then( phoneEdited => {
          setShowProcedure(false);
          
          console.log("phoneEdited",phoneEdited);
          if(phoneEdited.success == true){
            console.log("phoneEdited success",phoneEdited);
            setEditPhoneNumberDetails({
              ...EditPhoneNumberDetails,
              phone_id: "",
              phone: "",
              country_code: "",
              phone_type: "",
              is_active: "",
            })
            setphoneNumberErr("");
            setphoneTypeErr("");
            setOpenEditPhoneModal(false);
            Swal.fire({
              icon: 'success',
              text: "Contact Updated Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
          if(phoneEdited.success == false ){
            Swal.fire({
              icon: 'error',
              text: phoneEdited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          } 
        setflag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
    
  }

  const bodyEditPhone = (
    <div className={classes.paperModalEditPhone}>
      <div className={classes.editPhoneHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'43%'}}>Edit Contact</h2>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBodyEditPhone}>

        <Grid item xs={12} className={classes.subHeadingLabel}>
          Phone Number
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            inputProps={{
              autoFocus: true,
            }}
            inputStyle={{
              width: "100%",
              height: "50px",
              fontSize: "15px",
            }}
            countryCodeEditable={false}
            onlyCountries={onlyCountryArray}
            specialLabel=""
            country={'in'}
            masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                      sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                      au: '.............'  }}
            value={EditPhoneNumberDetails.country_code + EditPhoneNumberDetails.phone}
            onChange={(usersNumber,e,formattedValue,value) => phoneNumberEditClients(usersNumber,e,formattedValue,value)}
          />
          <div className={classes.validation}>{phoneNumberErr}</div>
        </Grid>

        <FormControl className={classes.formControl}>
        <InputLabel id="phone_type">Phone Type*</InputLabel>
        <Select
          labelId="phone_type"
          id="phone_type"
          value={EditPhoneNumberDetails.phone_type}
          onChange={(e) => setEditPhoneNumberDetails({...EditPhoneNumberDetails, phone_type:e.target.value }) }
        >
            <MenuItem value="primary">Primary</MenuItem>
            <MenuItem value="secondary">Secondary</MenuItem>
            <MenuItem value="emergency">Emergency</MenuItem>
            <MenuItem value="other">Other</MenuItem>
        </Select>
        </FormControl>
        <div className={classes.validation}>{(EditPhoneNumberDetails.phone_type)?(<div></div>):(phoneTypeErr)}</div>

      </div>
      <div className={classes.modalFooter}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleEditPhoneSubmit}
            >
              Update
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submit}
              onClick={handelEditPhoneClose}
            >
              Cancel
            </Button>
        </div>
    </div>
  )


  return (
    console.log("ClientPhones in new page", ClientPhoneNumbers),
    <div className={classes.root}>
      <Grid>
        <TableContainer style={{ width: '95%' }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              // classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ClientPhoneNumbers && ClientPhoneNumbers.length}
            />
            <TableBody>
              {stableSort(ClientPhoneNumbers && ClientPhoneNumbers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  console.log("row in table map mobile numbers",item, item.phone)
                  return (
                    <TableRow
                      // key={item.state_id}
                      hover={true} classes={{ hover: classes.hover }}
                      // hover
                      tabIndex={-1}
                      align="center"
                    >
                      <TableCell width="5%" align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item.country_code}-{item.phone}</TableCell>
                      <TableCell align="center">{item.phone_type}</TableCell>
                      <TableCell align="center">
                        <AntSwitch 
                            checked={item.is_active} 
                            onChange={() => handleDeletePhoneNumber(item)} 
                            size="small" 
                            name="item.is_active" 
                            value="item.is_active"
                            color="primary"
                        /> 

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginLeft:'3%' }}
                          onClick={() => handelEditPhoneNumber(item)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientPhoneNumbers && ClientPhoneNumbers.length }]}
                  colSpan={8}
                  count={ClientPhoneNumbers && ClientPhoneNumbers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      
      <Modal
        open={OpenEditPhoneModal}
        onClose={handelEditPhoneClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyEditPhone}
      </Modal>
    </div>
  );
}
