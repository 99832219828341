import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import { Link , Route, Redirect} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      cursor: "pointer",
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
    // transform: 'translate(-50%, -50%)',
    // top: '50%',
    // left: '50%',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top:'1%',
    display:'block'
  },
  paperModalCreateRole: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    marginTop: '-2%',
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Users() {
    const classes = useStyles();
    // const [redirectUserList, setredirectUserList] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [userDetails, setuserDetails] = React.useState({
      // name: "",
      first_name: "",
      middle_name: "",
      surname: "",
      username: "",
      email: "",
      password: "",
      role: "business-user",
      is_active: "",
      business_shortcode: "",
      business_role: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
      // client_type: "business",
      client_type: "",
      org_name: "",
    });
    
    const [nameErr, setnameErr] = React.useState();
    const [surnameErr, setsurnameErr] = React.useState();
    // const [userNameErr, setuserNameErr] = React.useState();
    const [emailErr, setemailErr] = React.useState();
    const [passwordErr, setpasswordErr] = React.useState();
    const [roleErr, setroleErr] = React.useState();
    const [activeErr, setactiveErr] = React.useState();
    // const [shortCodeErr, setshortCodeErr] = React.useState();
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);

    const [openCreateRole, setopenCreateRole] = React.useState(false);
    const [roleDetails, setroleDetails] = React.useState({
        shortcode: "",
        name: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [roleShortCodeErr, setroleShortCodeErr] = React.useState();
    const [roleNameErr, setroleNameErr] = React.useState();
    
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [businessRoles, setBusinessRoles] = React.useState([]);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
      let selected_business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      console.log("selected_business_id in use Effect===== ",selected_business_id);
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id': selected_business_id,'device_token': device_token, 
          'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_BUSINESS_DETAIL , requestOptions)
        .then((Response) => Response.json())
        .then( async(businessDetails) => {
            console.log("businessDetails on same page",businessDetails.data.business_detail);
            if (businessDetails.data.business_detail)
            {
                // setbusinessDetails(businessDetails.data.business_detail);
                // await localStorage.setItem('selected_business_shortcode', JSON.stringify(businessDetails.data.business_detail.shortcode));
              await setuserDetails({...userDetails, business_shortcode: businessDetails.data.business_detail.shortcode })
            }
            if(businessDetails.success == false){
              await setuserDetails({...userDetails, business_shortcode: "" })  
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        // to get existed roles list 
        fetch( process.env.REACT_APP_GET_BUSINESS_ROLES , requestOptions)
        .then((Response) => Response.json())
        .then( roleDetails => {
            console.log("roleDetails on same page", roleDetails.data.roles);
            if(roleDetails.data.roles)
            {
                setBusinessRoles(roleDetails.data.roles)
            }
            if(roleDetails.success == false){
              setBusinessRoles([])
                Swal.fire({
                icon: 'error',
                text: roleDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }, [flag, open]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    // const handleUserList = () => {
    //     // console.log('user list click');
    //     setredirectUserList(true)
    // }

    const handelAddNewUserOpen = () => {
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }

    const handelAddNewUserClose = () => {
        setOpen(false);
        // setnameErr();
        // setuserNameErr();
        // setemailErr();
        // setpasswordErr();
        // setroleErr();
        // setactiveErr();
        // setshortCodeErr();
        dispatch(userActions.checkSessionValidity());
    };

    const handelAddNewUserReset = async() => {
      await setuserDetails({
        ...userDetails,
        // name: "",
         first_name: "",
         middle_name: "",
         surname: "",
         username: "",
         email: "",
         password: "",
         role: "business-user",
         is_active: "",
         business_role: "",
          // business_shortcode: "",
         // device_token: JSON.parse(localStorage.getItem('device_token')),
         // session_id: JSON.parse(localStorage.getItem('session_id')),
         client_type: "",
         org_name: "",
      })
      // this setopen false and then true is for is_active it's not clearing without that
      setOpen(false);
      setOpen(true);
      // ////////////////////////////////////////////////////////////////////////////////
      setnameErr();
      setsurnameErr();
      // setuserNameErr();
      setemailErr();
      setpasswordErr();
      setroleErr();
      setactiveErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelAddNewUserCloseCross = () => {
      setOpen(false);
      setuserDetails({
        ...userDetails,
         // name: "",
          first_name: "",
          middle_name: "",
          surname: "",
          username: "",
          email: "",
          password: "",
          role: "business-user",
          is_active: "",
          business_role: "",
           // business_shortcode: "",
          // device_token: JSON.parse(localStorage.getItem('device_token')),
          // session_id: JSON.parse(localStorage.getItem('session_id')),
          client_type: "",
          org_name: "",
      })
      setnameErr();
      setsurnameErr();
      // setuserNameErr();
      setemailErr();
      setpasswordErr();
      setroleErr();
      setactiveErr();
      // setshortCodeErr();
      dispatch(userActions.checkSessionValidity());
  };

    const validate = () => {
      let nameErr = '';
      let surnameErr = '';
      // let userNameErr = '';
      let emailErr = '';
      let passwordErr = '';
      let roleErr = '';
      let activeErr = '';
      // let shortCodeErr = '';

      if(!userDetails.first_name)  {
          nameErr = 'Please Enter First Name';
      }
      if(!userDetails.surname)  {
        surnameErr = 'Please Enter Surname';
    }
      // if(!userDetails.username)  {
      //     userNameErr = 'Please Enter User Name';
      // }
      if(!userDetails.email)  {
          emailErr = 'Please Enter Email';
      }
        else if(typeof userDetails.email !== "undefined"){
            let lastAtPos = userDetails.email.lastIndexOf('@');
            let lastDotPos = userDetails.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                emailErr = "Email is not valid";
                if(emailErr) {
                  setemailErr(emailErr);
                }
            }
          else {
              setemailErr();    
            }
        }
      else{
            setemailErr();
      }


      if(!userDetails.password)  {
          passwordErr = 'Please Enter Password';
      }
      else if(userDetails.password)  {
        let countPasswordString = userDetails.password.length
        console.log("countPasswordString is",countPasswordString)
        if(userDetails.password.length < 8){
          console.log("countPasswordString is",countPasswordString)
          passwordErr = 'Password must contain atleast 8 characters';
        }
      }

      if(!userDetails.business_role)  {
          roleErr = 'Please Enter Role';
      }

      if(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true )  {
          activeErr = 'Please Select is Active';
      }
      // if(!userDetails.business_shortcode)  {
      //     shortCodeErr = 'Please Enter Business Shortcode';
      // }

      if(nameErr || surnameErr || emailErr || passwordErr || roleErr || activeErr ){
        setnameErr(nameErr);
        setsurnameErr(surnameErr);
        // setuserNameErr(userNameErr);
        setemailErr(emailErr);
        setpasswordErr(passwordErr);
        setroleErr(roleErr);
        setactiveErr(activeErr);
        // setshortCodeErr(shortCodeErr);
        return false;
      }

      return true;
    }

    const handelSubmitAddNewUser = () => {
      console.log("user arrayy is", userDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(userDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_USER , requestOptions)
            .then((Response) => Response.json())
            .then(userAdded => {
                setShowProcedure(false);
                console.log("userAdded on same page",userAdded.success);
                  console.log("userAdded on same page",userAdded);
                  if(userAdded.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "User Added Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })  
                    setuserDetails({
                      // name: "",
                      first_name: "",
                      middle_name: "",
                      surname: "",
                      username: "",
                      email: "",
                      password: "",
                      role: "business-user",
                      is_active: "",
                      business_role: "",
                      // business_shortcode: "",
                      // device_token: JSON.parse(localStorage.getItem('device_token')),
                      // session_id: JSON.parse(localStorage.getItem('session_id')),
                      client_type: "",
                      org_name: "",
                    })
                    setnameErr();
                    setsurnameErr();
                    // setuserNameErr();
                    setemailErr();
                    setpasswordErr();
                    setroleErr();
                    setactiveErr();
                    // setshortCodeErr();
                  }
                  if(userAdded.success == false || userAdded.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: userAdded.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
        
      }
    }

    const body = (
        <div className={classes.paperModal}>
        {/* <h2 className={classes.addNewBusinessHeader}>Add New Business</h2> */}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Add New User</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelAddNewUserClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelAddNewUserCloseCross()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>         
        </div>
        <div className={classes.modalBody}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  //   variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="First Name"
                    name="firstname"
                    autoComplete="firstname"
                    autoFocus
                    value={userDetails.first_name}
                    onChange={(e) => setuserDetails({...userDetails, first_name:e.target.value }) }
                  />
                  <div className={classes.validation}>{(userDetails.first_name)?(<div></div>):(nameErr)}</div>
              </Grid>
              <Grid item xs={6}>
               <TextField
                //   variant="outlined"
                  margin="normal"
                  fullWidth
                  id="middleName"
                  label="Middle Name"
                  name="middleName"
                  autoComplete="middleName"
                  value={userDetails.middle_name}
                  onChange={(e) => setuserDetails({...userDetails, middle_name:e.target.value }) }
                />
              </Grid>

            </Grid>
            
            
            <TextField
            //   variant="outlined"
              margin="normal"
              required
              fullWidth
              id="surname"
              label="Surname"
              name="surname"
              autoComplete="surname"
              value={userDetails.surname}
              onChange={(e) => setuserDetails({...userDetails, surname:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.surname)?(<div></div>):(surnameErr)}</div>

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={userDetails.username}
              onChange={(e) => setuserDetails({...userDetails, username:e.target.value.trim() }) }
            />
            <div className={classes.validation}>{(userDetails.username)?(<div></div>):(userNameErr)}</div> */}

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              // inputProps={{ style: { textTransform: "lowercase" } }}
              label="EmailID"
              name="email"
              autoComplete="email"
              value={userDetails.email}
              onChange={(e) => setuserDetails({...userDetails, email:e.target.value.toLowerCase(), username:e.target.value.toLowerCase() }) }
            />
            {/* <div className={classes.validation}>{(userDetails.email)?(<div></div>):(emailErr)}</div> */}
            <div className={classes.validation}>{emailErr}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              value={userDetails.password}
              onChange={(e) => setuserDetails({...userDetails, password:e.target.value }) }
            />
            {/* <div className={classes.validation}>{(userDetails.password)?(<div></div>):(passwordErr)}</div> */}
            <div className={classes.validation}>{(passwordErr)?(passwordErr):(<div></div>)}</div>

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="role"
              label="Role"
              name="role"
              autoComplete="role"
              value={userDetails.role}
              onChange={(e) => setuserDetails({...userDetails, role:e.target.value }) }
            /> */}
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="role">Role*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Role*</Grid>
              <Select
                // style={{marginTop:'5%'}}
                native
                labelId="role"
                id="role"
                value={userDetails.business_role}
                // onChange={(e) => setuserDetails({...userDetails, role: JSON.parse(e.target.value) }) }
                // onChange={(e) => setuserDetails({...userDetails, role: e.target.value }) }
                onChange={(e) => setuserDetails({...userDetails, business_role: e.target.value }) }
              >
                {/* <MenuItem value={'trp'}>TRP</MenuItem>
                <MenuItem value={'trpdoc'}>TRP Doc</MenuItem>
                <MenuItem value={'trpadmin'}>TRP Admin</MenuItem> */}
                <option value="" style={{color:'grey'}}>Select Role</option>
                {
                  businessRoles && businessRoles.map(item =>
                    <option value={item.shortcode}>{item.name}</option>
                  )
                }

                {/* role_id   shortcode */}
                
                {/* <option value="trp" style={{color:'grey'}}>TRP</option>
                <option value="trpdoc" style={{color:'grey'}}>TRP Doc</option>
                <option value="trpadmin" style={{color:'grey'}}>TRP Admin</option> */}

                {/* {yesNo.map(item =>
                  <option value={item.value}>{item.name}</option>
                )} */}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(userDetails.business_role)?(<div></div>):(roleErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(userDetails.is_active)}
                  onChange={ (e) => setuserDetails({...userDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_active"
                id="is_active"
                value={userDetails.is_active}
                onChange={(e) => setuserDetails({...userDetails, is_active:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true)?(activeErr):(<div></div>)}</div>
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="is_active"
              label="Is Active"
              name="is_active"
              autoComplete="is_active"
              value={userDetails.is_active}
              onChange={(e) => setuserDetails({...userDetails, is_active:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.is_active)?(<div></div>):(activeErr)}</div> */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="business_shortcode"
              label="Business Shortcode"
              name="business_shortcode"
              autoComplete="business_shortcode"
              value={userDetails.business_shortcode}
              onChange={(e) => setuserDetails({...userDetails, business_shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.business_shortcode)?(<div></div>):(shortCodeErr)}</div> */}

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitAddNewUser}
                >
                Add
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelAddNewUserReset}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelCreateNewRoleOpen = () => {
      setopenCreateRole(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateNewRoleMinimize = () => {
      setopenCreateRole(false);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateNewRoleClose = () => {
      setopenCreateRole(false);
      setroleDetails({
        shortcode: "",
        name: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setroleNameErr();
      setroleShortCodeErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateNewRoleClear = () => {
      setroleDetails({
        shortcode: "",
        name: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setroleNameErr();
      setroleShortCodeErr();
      dispatch(userActions.checkSessionValidity());
    }

    const validateCreateRole = () => {
      let roleShortCodeErr = '';
      let roleNameErr = '';

      if(!roleDetails.shortcode)  {
          roleShortCodeErr = 'Please Enter Short Code';
      }
      if(!roleDetails.name)  {
          roleNameErr = 'Please Enter Name';
      }

      if(roleNameErr || roleShortCodeErr ){
        setroleNameErr(roleNameErr);
        setroleShortCodeErr(roleShortCodeErr);
        return false;
      }

      return true;
    }

    const handelSubmitCreateNewRole = () => {
      // console.log("roleDetails",roleDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validateCreateRole();
      if(isValid){
        setShowProcedure(true);
        setopenCreateRole(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(roleDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_ROLE , requestOptions)
            .then((Response) => Response.json())
            .then(roleCreated => {
                setShowProcedure(false);
                console.log("roleCreated on same page",roleCreated.success);
                  console.log("roleCreated on same page",roleCreated);
                  if(roleCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Role Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setroleDetails({
                      shortcode: "",
                      name: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setroleNameErr();
                    setroleShortCodeErr();
                  }
                  if(roleCreated.success == false || roleCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: roleCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenCreateRole(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bodyCreateRole = (
      <div className={classes.paperModalCreateRole}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Create New Role</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={10} >
          <h2 style={{marginLeft:'40%'}}>Create New Role</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelCreateNewRoleMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handelCreateNewRoleClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="shortcode"
          label="Short Code for Role"
          name="shortcode"
          autoComplete="shortcode"
          autoFocus
          value={roleDetails.shortcode}
          onChange={(e) => setroleDetails({...roleDetails, shortcode:e.target.value }) }
        />
        <div className={classes.validation}>{(roleDetails.shortcode)?(<div></div>):(roleShortCodeErr)}</div>

        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          value={roleDetails.name}
          onChange={(e) => setroleDetails({...roleDetails, name:e.target.value }) }
        />
        <div className={classes.validation}>{(roleDetails.name)?(<div></div>):(roleNameErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCreateNewRole}
          >
            Create
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelCreateNewRoleClear}
            >
              Clear
            </Button>
          </div>
      </div>
      </div>
    )

    return(
      // console.log("business_shortcode on users",business_shortcode),
    // ( redirectUserList == true )?( <Redirect to="/UsersList" /> ):(
        <Grid item xs={4} >
            <div>
              {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
              </modal>: null}
            </div>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={1} className={classes.moreVertIcon}>
                        <PersonIcon className={classes.personIconColor}/>
                    </Grid>
                    <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                        Users
                    </Grid>
                </Grid>
                <hr/>
                <Grid container>
                    {/* <Grid onClick={handleUserList} item xs={12}  className={classes.userAddUpdateDelete}> */}
                    <Grid item xs={12}  className={classes.userAddUpdateDelete}>
                      <Link to="UsersList" style={{ textDecoration: 'none', color:'black' }}>
                        Users List
                      </Link>
                    </Grid>
                    <Grid onClick={handelAddNewUserOpen} item xs={12} className={classes.userAddUpdateDelete}>
                        Add User
                    </Grid>
                    <Modal
                        open={open}
                        onClose={handelAddNewUserClose}
                        className={classes.modal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                    {/* <Grid onClick={handelCreateNewRoleOpen} item xs={12} className={classes.userAddUpdateDelete}>
                        Create Role
                    </Grid> */}
                    <Modal
                        open={openCreateRole}
                        onClose={handelCreateNewRoleClose}
                        className={classes.modal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {bodyCreateRole}
                    </Modal>
                </Grid>
            </Paper>
        </Grid>
        )
    // )

}
