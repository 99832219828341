import React from 'react';
import TaxManagementSearch from "./TaxManagementSearch";
import TaxManagementCases from "./TaxManagementCases";
import TaxManagementClient from "./TaxManagementClient";
import TaxManagementRoles from "./TaxManagementRoles";
import TaxManagementAlerts from "./TaxManagementAlerts";
import TaxManagementNewCase from "./TaxManagementNewCase";
import TaxManagementSearchResult from './TaxManagementSearchResult';
import TaxManagementNewClient from './TaxManagementNewClient';
import {useRoutes} from 'hookrouter';


import BusinessDashboard from './BusinessDashboard';
import Businesses from "./Businesses";
import RoleBasedAccess from "./RoleBasedAccess";
import UsersList from "./BusinessDashboard/Users/UsersList";
import CategoryList from "./BusinessDashboard/Category/CategoryList";
import CategoryTreeList from "./BusinessDashboard/CategoryTree/CategoryTreeList";
import ProcessList from "./BusinessDashboard/Process/ProcessList";
import StepList from "./BusinessDashboard/Step/StepList";
import StepLinkList from "./BusinessDashboard/StepLinks/StepLinkList";
import StepStateList from "./BusinessDashboard/StepStates/StepStateList";
import StateList from "./BusinessDashboard/States/StateList";
import QuestionList from "./BusinessDashboard/Questions/QuestionList";
import DocumentTypesList from "./BusinessDashboard/DocumentTypes/DocumentTypesList";
import QuestionLinksList from "./BusinessDashboard/QuestionLinks/QuestionLinksList";
import AnswerDocumentLinkList from "./BusinessDashboard/AnswerDocumentLink/AnswerDocumentLinkList";
import TagList from "./BusinessDashboard/Tag/TagList";
import TaskList from "./BusinessDashboard/Task/TaskList";
import TaskListStepLinkList from "./BusinessDashboard/TaskListsStepLink/TaskListStepLinkList";
import ClientsList from "./BusinessDashboard/Clients/ClientsList";

import ShowProcess from "./BusinessDashboard/Process/ShowProcess";

import CategoryBulkTrial from "./BusinessDashboard/Category/CategoryBulkTrial";

import TaxManagementSignUp from "./TaxManagementSignUp/TaxManagementSignUp";
import SignUpVerification from "./TaxManagementSignUp/SignUpVerification";
import SignUpReferalCode from "./TaxManagementSignUp/SignUpReferalCode";

import TaxManagementForgotPassword from "./TaxManagementForgotPassword/TaxManagementForgotPassword";
import EnterOtpForgotPassword from "./TaxManagementForgotPassword/EnterOtpForgotPassword";
import ResetForgotPassword from "./TaxManagementForgotPassword/ResetForgotPassword"

import TaxManagementClientCases from "./TaxManagementClientCases";
import EditExistingCase from "./TaxManagementClientCases/EditExistingCase";

import UsersProfile from "./ProfilePicture/UsersProfile";
import UserAddressDetails from "./ProfilePicture/UserAddressDetails";
import UserPhoneNumberDetails from "./ProfilePicture/UserPhoneNumberDetails";
import UsersIdProofDetails from "./ProfilePicture/UsersIdProofDetails";
import UserOtherContactDetails from "./ProfilePicture/UserOtherContactDetails";

import CommunicationInterface from "./Communication/CommunicationInterface"

import AddNewClient from "./TaxManagementNewCase/AddNewClient";
import TrpEditCase from "./TaxManagementNewCase/TrpEditCase";

import TaxManagementCaseDetails from "./TaxManagementCaseDetails";
import TrpsPaymentInfo from "./TaxManagementPayment/TrpsPaymentInfo"
import ClientsPaymentInfo from "./TaxManagementPayment/ClientsPaymentInfo";
import PaymentPage from "./TaxManagementPayment/PaymentPage";

import TaxManagementClientDetailsUI from "./TaxManagementClientDetailsUI/TaxManagementClientDetailsUI";
import AddNewContactForClient from "./TaxManagementClientDetailsUI/AddNewContactForClient";

import AlertConfiguration from "./TaxManagementAlerts/AlertConfiguration";

import TaxManagementExplorer from "./TaxManagementExplorer";
import TaxManagementServiceExplorer from "./TaxManagementServiceExplorer";
import TaxManagementProcessCreation from "./TaxManagementProcessCreation";
import TaxManagementCategoryCreation from "./TaxManagementCategoryCreation";
import TaxManagementNoticeBoard from "./TaxManagementNoticeBoard";
import NoticeHistory from "./TaxManagementNoticeBoard/NoticeHistory";
import TaxManagementClientGroups from "./TaxManagementClientGroups";
import TaxManagementClientGroupDetails from "./TaxManagementClientGroups/TaxManagementClientGroupDetails";

import UpdatePermissions from "./UpdatePermissions";

import EditClientSelf from "./TaxManagementClient/EditClientSelf";

import TaxManagementUsers from "./TaxManagementUsers";
import TaxManagementTags from "./TaxManagementTags";
import TaxManagementClientBulkDashboard from "./TaxManagementClientBulkDashboard";

import ShowFeedback from './ShowFeedback';
import FeedbackDetail from './ShowFeedback/FeedbackDetail';

import TermsAndConditions from './TermsAndConditions';

import SamaiHomePage from "./Samai";

const routes = [
    {
        path: "/search",
        main: () => <TaxManagementSearch />
      },
      {
        path: "/cases",
        main: () => <TaxManagementCases />
      },
      {
        path: "/client",
        main: () => <TaxManagementClient />
      },
      {
        path: "/roles",
        main: () => <TaxManagementRoles />
      },
      {
        path: "/alerts",
        main: () => <TaxManagementAlerts />
      },
      {
        path: "/newcase",
        main: () => <TaxManagementNewCase />
      },
      {
        path: "/searchresult",
        main: () => <TaxManagementSearchResult />
      },
      {
        path: "/newclient",
        main: () => <TaxManagementNewClient />
      },

      {
        path: "/BusinessDashboard",
        // path: "/BusinessDashboard/:itemname",
        main: () => <BusinessDashboard />,
        // '/BusinessDashboard/:id':({id})=><BusinessDashboard id={id}/>,
        // '/BusinessDashboard/:itemname':({itemname})=><BusinessDashboard itemname={itemname}/>,
      },
      {
        path: "/Businesses",
        main: () => <Businesses />
      },
      {
        path: "/RoleBasedAccess",
        main: () => <RoleBasedAccess />
      },
      {
        path: "/UsersList",
        main: () => <UsersList />
      },
      {
        path: "/CategoryList",
        main: () => <CategoryList />
      },
      {
        path: "/CategoryTreeList",
        main: () => <CategoryTreeList />
      },
      {
        path: "/ProcessList",
        main: () => <ProcessList />
      },
      {
        path: "/StepList",
        main: () => <StepList />
      },
      {
        path: "/StepLinkList",
        main: () => <StepLinkList />
      },
      {
        path: "/StepStateList",
        main: () => <StepStateList />
      },
      {
        path: "/StateList",
        main: () => <StateList />
      },
      {
        path: "/QuestionList",
        main: () => <QuestionList />
      },
      {
        path: "/DocumentTypesList",
        main: () => <DocumentTypesList />
      },
      {
        path: "/QuestionLinksList",
        main: () => <QuestionLinksList />
      },
      {
        path: "/AnswerDocumentLinkList",
        main: () => <AnswerDocumentLinkList />
      },
      {
        path: "/TagList",
        main: () => <TagList />
      },
      {
        path: "/TaskList",
        main: () => <TaskList />
      },
      {
        path: "/TaskListStepLinkList",
        main: () => <TaskListStepLinkList />
      },

      {
        path: "/ClientsList",
        main: () => <ClientsList />
      },

      {
        path: "/CategoryBulkTrial",
        main: () => <CategoryBulkTrial />
      },

      {
        path: "/ShowProcess",
        main: () => <ShowProcess />
      },
      {
        path: "/TaxManagementSignUp",
        main: () => <TaxManagementSignUp />
      },
      {
        path: "/SignUpVerification",
        main: () => <SignUpVerification />
      },
      {
        path: "/SignUpReferalCode",
        main: () => <SignUpReferalCode />
      },
      {
        path: "/TaxManagementForgotPassword",
        main: () => <TaxManagementForgotPassword />
      },
      {
        path: "/EnterOtpForgotPassword",
        main: () => <EnterOtpForgotPassword />
      },
      {
        path: "/ResetForgotPassword",
        main: () => <ResetForgotPassword />
      },

      {
        path: "/TaxManagementClientCases",
        main: () => <TaxManagementClientCases />
      },
      {
        path: "/EditCase",
        main: () => <EditExistingCase />
      },

      {
        path: "/UsersProfile",
        main: () => <UsersProfile />
      },
      {
        path: "/UserAddressDetails",
        main: () => <UserAddressDetails />
      },
      {
        path: "/UserPhoneNumberDetails",
        main: () => <UserPhoneNumberDetails />
      },
      {
        path: "/UsersIdProofDetails",
        main: () => <UsersIdProofDetails />
      },
      {
        path: "/UserOtherContactDetails",
        main: () => <UserOtherContactDetails />
      },


      {
        path: "/AddNewClient",
        main: () => <AddNewClient />
      },
      {
        path: "/TrpEditCase",
        main: () => <TrpEditCase />
      },

      {
        path: "/TaxManagementCaseDetails",
        main: () => <TaxManagementCaseDetails/>
      },

      {
        path: "/TrpsPaymentInfo",
        main: () => <TrpsPaymentInfo/>
      },

      {
        path: "/ClientsPaymentInfo",
        main: () => <ClientsPaymentInfo/>
      },

      {
        path: "/PaymentPage",
        main: () => <PaymentPage/>
      },

      {
        path: "/TaxManagementClientDetailsUI",
        main: () => <TaxManagementClientDetailsUI/>
      },
      {
        path: "/AddNewContactForClient",
        main: () => <AddNewContactForClient/>
      },

      {
        path: "/AlertConfiguration",
        main: () => <AlertConfiguration/>
      },

      {
        path: "/TaxManagementExplorer",
        main: () => <TaxManagementExplorer/>
      },
      {
        path: "/TaxManagementServiceExplorer",
        main: () => <TaxManagementServiceExplorer/>
      },

      {
        path: "/TaxManagementProcessCreation",
        main: () => <TaxManagementProcessCreation/>
      },
      {
        path: "/TaxManagementCategoryCreation",
        main: () => <TaxManagementCategoryCreation/>
      },
      {
        path: "/TaxManagementNoticeBoard",
        main: () => <TaxManagementNoticeBoard/>
      },
      {
        path: "/NoticeHistory",
        main: () => <NoticeHistory/>
      },
      {
        path: "/TaxManagementClientGroups",
        main: () => <TaxManagementClientGroups/>
      },
      {
        path: "/TaxManagementClientGroupDetails",
        main: () => <TaxManagementClientGroupDetails/>
      },
      {
        path: "/EditClientSelf",
        main: () => <EditClientSelf/>
      },
      {
        path: "/UpdatePermissions",
        main: () => <UpdatePermissions />
      },
      {
        path: "/users",
        main: () => <TaxManagementUsers />
      },
      {
        path: "/tags",
        main: () => <TaxManagementTags />
      },
      {
        path: "/clientBulkDashboard",
        main: () => <TaxManagementClientBulkDashboard />
      },
      {
        path: "/Feedback",
        main: () => <ShowFeedback />
      },
      {
        path: "/FeedbackDetail",
        main: () => <FeedbackDetail />
      },
      {
        path: "/CommunicationInterface",
        main: () => <CommunicationInterface />
      },
      
      {
        path: "/TermsAndConditions",
        main: () => <TermsAndConditions />
      },

      {
        path: "HomePage",
        main: () => <SamaiHomePage />
      }

      
      

]

export default routes;
