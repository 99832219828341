import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link, Redirect } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Modal } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { userActions } from "../actions";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  // },
  wrapper: {
    // margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginLeft: "10%",
    marginBottom: "2%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    // marginTop: "5%",
    // marginBottom: "2%",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  wrapperInner: {
    // width: "100%",
    marginTop: '-3%',
    background: "#ffffff",
  },
  wrapperInnerCreateCategory: {
    width: "100%",
    height: "100%",
  },
  rootInner: {
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "4%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "left",
  },
  createCatHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "17px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
  },
  createCatHeadingLabelClickHere: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "17px",
    color: "blue",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  showProcedureStyle: {
    // display: "flex",
    // alignItems: "center",
    // alignSelf: "center",
    // marginLeft: "50%",
    // marginTop: "-20%",
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  AddNewClientButton: {
    color: "Blue",
  },
  validation: {
    marginTop: '0.5%',
    color: 'red',
    fontSize: 12,
    textAlign: "left",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  subHeadingLabelSelect: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "2%",
  },
  formControl: {
    marginLeft: "-5%",
    marginTop: '2%',
    width: '90%',
  },
  formControlOtherContact: {
    // marginLeft: "-5%",
    marginTop: '2%',
    width: '100%',
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  datePickerStyle: {
    width: '94%',
    height: '50px',
    borderColor: 'grey',
    borderRadius: '5px',
    borderWidth: '0.5px',
    marginTop: '2%',
  }
}));


function TaxManagementNewCase({ intl, props }) {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [ShowProcedure2, setShowProcedure2] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [clientList, setclientList] = React.useState([]);
  const [selectedClient, setselectedClient] = React.useState("");
  const [selectedClientPAN, setselectedClientPAN] = React.useState("");
  const [selectedClientNameIs, setselectedClientNameIs] = React.useState("");
  const [selectedClientState, setSelectedClientState] = React.useState("");
  const [newCaseDetails, setnewCaseDetails] = React.useState({
    created_by: "business",
    service_id: "",
    // client_id:"",
    name: "",
    description: "",
    assessment_year: "",
    self_assessment: "",
    assesse_id: "",
    assesse_name: "",
    assesse_email: "",
    assesse_pan: "",
    assesse_phone: "",
    assesse_country_code: "",
    is_batch: false,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [newCaseForSingleClient, setnewCaseForSingleClient] = React.useState(false);
  const [newCaseForBulkCreation, setnewCaseForBulkCreation] = React.useState(false);
  const [BusinessClientGroupDetails, setBusinessClientGroupDetails] = React.useState([]);
  const [newCaseDetailsBulk, setnewCaseDetailsBulk] = React.useState({
    client_groups: [],
    service_id: "",
    assessment_year: "",
    case_name: "",
    case_desc: "",
    is_batch: true,
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [fillingForClient, setfillingForClient] = React.useState("");
  const [toggleRadio, settoggleRadio] = React.useState(true);
  const [AddNewContactFlag, setAddNewContactFlag] = React.useState();
  const [AssenameOrOtherContactSelected, setAssenameOrOtherContactSelected] = React.useState();
  const [ClientContactDetails, setClientContactDetails] = React.useState("");
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in', 'us', 'gb', 'ca', 'ae', 'sg', 'np', 'jp', 'de', 'bt', 'au'
  ]);
  const [CountryCode, setCountryCode] = React.useState("");
  const [ToCheckErrForSelectContact, setToCheckErrForSelectContact] = React.useState(true);

  const [superCategoryList, setsuperCategoryList] = React.useState([]);
  const [superCategoryValue, setsuperCategoryValue] = React.useState("");
  const [subCategoryList, setsubCategoryList] = React.useState([]);
  const [SubCtegoryFetched, setSubCtegoryFetched] = React.useState(false);
  const [subCategoryValue, setsubCategoryValue] = React.useState("");
  const [SelectedCategoryIsCategory, setSelectedCategoryIsCategory] = React.useState(false);
  const [categoryDetailsLastList, setcategoryDetailsLastList] = React.useState([]);
  const [lastCategoryValue, setlastCategoryValue] = React.useState("");

  const [selectedAssesmentYear, setselectedAssesmentYear] = React.useState("");

  const [caseNameErr, setcaseNameErr] = React.useState("");
  const [caseDescriptionErr, setcaseDescriptionErr] = React.useState("");
  const [caseServiceErr, setcaseServiceErr] = React.useState("");
  const [caseAssesYearErr, setcaseAssesYearErr] = React.useState("");

  const [caseAsseNameErr, setcaseAsseNameErr] = React.useState("");
  const [caseAsseEmailErr, setcaseAsseEmailErr] = React.useState("");
  const [caseAssePanErr, setcaseAssePanErr] = React.useState("");
  const [caseAssePhoneErr, setcaseAssePhoneErr] = React.useState("");

  const [RedirectAddNewClient, setRedirectAddNewClient] = React.useState(false);
  const [RedirectCaseTable, setRedirectCaseTable] = React.useState(false);
  const [RedirectCategoryCreation, setRedirectCategoryCreation] = React.useState(false);
  const [RedirectTaxManagementExplorer, setRedirectTaxManagementExplorer] = React.useState(false);

  const [ClientIsRegisteredOrNot, setClientIsRegisteredOrNot] = React.useState("");

  const [checkCaseIsForSelectedClientOrNot, setcheckCaseIsForSelectedClientOrNot] = React.useState("");
  // const [AssesmentYearArray, setAssesmentYearArray] = React.useState(
  //   [
  //     {value:"N/A", item:"N/A"}
  //   ]
  // )
  const [AssesmentYearArray, setAssesmentYearArray] = React.useState([])

  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

  const [CategoryCreation, setCategoryCreation] = React.useState();

  // const [dueDate, setdueDate] = React.useState(new Date());
  // const [minDate, setminDate] = React.useState(new Date());

  const currentYear = new Date(); // 2020

  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1

  console.log(" currentYear in new case is", currentYear);
  console.log("previousYear and nextYear in new case is", previousYear); // 2019
  console.log(" nextYear in new case is", previousYear, currentYear);

  // const [dueDate, setdueDate] = React.useState(nextYear);
  // const [minDate, setminDate] = React.useState(previousYear);

  // const [dueDate, setdueDate] = React.useState("Mon Apr 03 2020 15:56:00 GMT+0530 (India Standard Time)");
  // const [minDate, setminDate] = React.useState("Mon Apr 03 2024 15:56:00 GMT+0530 (India Standard Time)");

  var d1 = new Date();
  var pastYear = d1.getFullYear() - 10;
  d1.setFullYear(pastYear);
  console.log("d==", d1);

  var d2 = new Date();
  var pastYear = d2.getFullYear() + 2;
  d2.setFullYear(pastYear);
  console.log("d==", d2);

  const [dueDate, setdueDate] = React.useState(d2);
  const [minDate, setminDate] = React.useState(d1);

  const getAssesmentYear = () => {
    var year1 = new Date();
    var pastYear = year1.getFullYear() - 10;
    year1.setFullYear(pastYear);
    console.log("d== d1.getFullYear()", year1, year1.getFullYear());
    var year1Final = year1.getFullYear()

    var year2 = new Date();
    var pastYear = year2.getFullYear() + 2;
    year2.setFullYear(pastYear);
    console.log("d== d1.getFullYear()", year2, year2.getFullYear());
    var year2Final = year2.getFullYear()

    // let arrayHere = [];
    // let i ;
    // for(i = year1Final ; i <=year2Final ; i++ ){
    //   console.log("i is",i, i.toString())
    //   let a = {value:i.toString(), item:i.toString()}
    //   console.log("a is", a)
    //   let b = AssesmentYearArray.concat(a)
    //   setAssesmentYearArray(b)
    //   // let b = arrayHere.concat(a)
    //   console.log("AssesmentYearArray in useeffect is",AssesmentYearArray)
    //   // console.log("arrayHere====",arrayHere)
    // }

    const value = [{ value: "N/A", item: "N/A" }];
    for (let i = year1Final; i <= year2Final; i++) {
      // if (options[i].selected) {
      // let item = i.
      const last2 = i.toString().slice(-2);
      const lastis = parseInt(last2) + 1;
      console.log("last2 DIGITS ARE", last2);
      console.log("lastis DIGITS ARE", lastis);

      // let num1 = "30";
      // let num2 = "34";

      // let num 3 = parseInt(num1) + parseInt(num2);

      let LastValueIs = 'AY' + '-' + i.toString() + '-' + lastis

      console.log("LastValueIs of assesment year", LastValueIs)

      // let a = {value:i.toString(), item:i.toString()}
      let a = { value: LastValueIs.toString(), item: LastValueIs.toString() }


      value.push(a);
      // AssesmentYearArray.push(a)
      // }
      console.log("value array is", value)
    }
    console.log("value array is", value)
    setAssesmentYearArray({ ...AssesmentYearArray, value })
  }


  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  useEffect(() => {
    setflag(true)
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());
    // localStorage.removeItem('view_clients_active_cases');

    let checkCaseIsForSelectedClient = localStorage.getItem('createNewCaseForSelectedClient')
    console.log("checkCaseIsForSelectedClient====",checkCaseIsForSelectedClient)
    
    let selectedClientDetailsToCreateCase = JSON.parse(localStorage.getItem("view_clients_active_cases"))
    console.log("create case for client", selectedClientDetailsToCreateCase)

    // let a = selectedClientDetailsToCreateCase
    // a.client_name = a.first_name + " " + a.middle_name + " " + a.surname + " " + "(" + a.client_pan + ")";
    // console.log("check a====",a)
    // setclientList(a)

    if( checkCaseIsForSelectedClient == 'true' ){
      console.log("checkCaseIsForSelectedClient is === true")
      setcheckCaseIsForSelectedClientOrNot(true)
      settoggleRadio(true)
       console.log("selectedClientDetailsToCreateCase in handleClienSelect", selectedClientDetailsToCreateCase)
       setselectedClient(selectedClientDetailsToCreateCase.client_id);
       setselectedClientNameIs(selectedClientDetailsToCreateCase.client_name);
       setselectedClientPAN(selectedClientDetailsToCreateCase.client_pan);
       setSelectedClientState(selectedClientDetailsToCreateCase.client_state);
       setnewCaseDetails({ ...newCaseDetails, client_id: selectedClientDetailsToCreateCase.client_id })
       setnewCaseForSingleClient(true);
       setnewCaseForBulkCreation(false);
       setnewCaseDetailsBulk({...newCaseDetailsBulk, client_groups: [] })
       
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'client_id': selectedClientDetailsToCreateCase.client_id,
          'device_token': device_token, 'session_id': session_id, 'business_id': business_id
        })
      };
      fetch(process.env.REACT_APP_GET_CLIENT_CONTACTS, requestOptions)
        .then((Response) => Response.json())
        .then(async (clientContactDetails) => {
          settoggleRadio(false)
          if (clientContactDetails.success == true) {
            if (clientContactDetails.data.contacts_info) {
              settoggleRadio(true)
              console.log("clientContactDetails for list", clientContactDetails.data.contacts_info.contacts);
              setClientContactDetails(clientContactDetails.data.contacts_info.contacts)
              setClientIsRegisteredOrNot("Yes")
              // await setnewCaseDetails({...newCaseDetails, self_assessment: false })
              setfillingForClient("");
            }
          }
          if (clientContactDetails.success == false) {
            setClientIsRegisteredOrNot("No")
            setnewCaseDetails({ ...newCaseDetails, self_assessment: true, client_id: selectedClientDetailsToCreateCase.client_id })
            setfillingForClient("true");
            settoggleRadio(true)
            setClientContactDetails([])
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
    else{
      console.log("checkCaseIsForSelectedClient is === false")
      setcheckCaseIsForSelectedClientOrNot(false)
    }

    getAssesmentYear()
    // var year1 = new Date();
    // var pastYear = year1.getFullYear() - 10;
    // year1.setFullYear(pastYear);
    // console.log("d== d1.getFullYear()",year1, year1.getFullYear());
    // var year1Final = year1.getFullYear()

    // var year2 = new Date();
    // var pastYear = year2.getFullYear() + 2;
    // year2.setFullYear(pastYear);
    // console.log("d== d1.getFullYear()",year2, year2.getFullYear());
    // var year2Final = year2.getFullYear()

    // let arrayHere = [];
    // let i ;
    // for(i = year1Final ; i <=year2Final ; i++ ){
    //   console.log("i is",i, i.toString())
    //   let a = {value:i.toString(), item:i.toString()}
    //   console.log("a is", a)
    //   // let b = AssesmentYearArray.concat(a)
    //   // setAssesmentYearArray(AssesmentYearArray.concat(a))
    //   let b = arrayHere.concat(a)
    //   // setAssesmentYearArray(AssesmentYearArray.concat(a))
    //   console.log("AssesmentYearArray in useeffect is",AssesmentYearArray)
    //   console.log("arrayHere====",arrayHere)
    // }

    let category_type = "supercategory"

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'category_type': category_type, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };

    fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        console.log("super categoryDetails for category list", superCategoryDetails.data.categories);
        if (superCategoryDetails.data.categories) {
          setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true))
        }
        else {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // all category info
    const requestOptionsCatInfo = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_ALL_CATEGORY_INFO, requestOptionsCatInfo)
      .then((Response) => Response.json())
      .then(async (categoryDetails) => {
        setShowProcedure(false);
        console.log("categoryDetails", categoryDetails);
        if (categoryDetails.success == true) {
          setCategoryCreation(categoryDetails.data.category_tree.filter(item => item.is_live == true));
        }
        else if (categoryDetails.success == false) {
          setCategoryCreation([]);
        }
      })
      .catch(err => {
        setCategoryCreation([]);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to fetch groups
    const requestOptionsGroups = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS, requestOptionsGroups)
      .then((Response) => Response.json())
      .then(async (businessClientGroupDetails) => {
        setShowProcedure(false);
        console.log("businessClientGroupDetails from api", businessClientGroupDetails);
        if (businessClientGroupDetails.success == true) {

          let BusinessClientGroupDetailsHere = [];
          businessClientGroupDetails.data.client_groups.map((e, i) => {
            console.log("element in businessClientGroupDetails map is", e)
            if (e.is_active === true) {
              BusinessClientGroupDetailsHere.push(e);
            }
          })
          console.log("BusinessClientGroupDetailsHere is", BusinessClientGroupDetailsHere)

          setBusinessClientGroupDetails(BusinessClientGroupDetailsHere)

        }
        else if (businessClientGroupDetails.success == false) {
          setBusinessClientGroupDetails([]);
          Swal.fire({
            icon: 'error',
            text: businessClientGroupDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, [flag]);

  const handleClientSearch = (e) => {
    console.log("in handleClientSearch", e.target.value)
    let search_string = e.target.value;
    console.log("search_string", search_string)
    console.log("client search device_token,session_id, ", device_token, session_id);

    if (search_string !== "") {
      // setShowProcedure2(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'search_string': search_string, 'device_token': device_token,
          'session_id': session_id, 'business_id': business_id, "client_type": "ALL"
        })
      };

      fetch(process.env.REACT_APP_SEARCH_CLIENTS, requestOptions)
        .then((Response) => Response.json())
        .then(clientDetails => {
          // setShowProcedure2(false);
          console.log("clientDetails in search", clientDetails);
          if (clientDetails.success == true) {

            // var ClientsAssesmentAfterName = [];
            // clientDetails.data.clients_assesse.map((data) => {
            //   console.log("data is", data)
            //   data.client_name = data.first_name + " " + data.middle_name + " " + data.surname + "(" + data.client_pan + ")";
            //   ClientsAssesmentAfterName = ClientsAssesmentAfterName.concat(data);
            //   // console.log("data join : ",ClientsAfterName);
            // })
            // settrial("ok");

            // const wholeSearchResultIs = [...clientDetails.data.clients,
            //   ...clientDetails.data.clients_assesse];

            // const wholeSearchResultIs = [...clientDetails.data.clients,
            // ...clientDetails.data.clients_assesse];

            // wholeSearchResultIs.map((item,index)=>{
            //   console.log("wholeSearchResultIs item",item)

            // })
            // setclientList(wholeSearchResultIs)

            let wholeSearchResultIsWithClientNameAndPan = [];
            // wholeSearchResultIs.map((data) => {
            clientDetails.data.clients.map((data) => {
              console.log("data is======", data)
              // data.client_name = data.first_name + " " + data.middle_name + " " + data.surname + " " + "(" + data.client_pan + ")";
              data.client_name = data.client_name + " " + "(" + data.client_pan + ")";
              wholeSearchResultIsWithClientNameAndPan = wholeSearchResultIsWithClientNameAndPan.concat(data);
            })
            setclientList(wholeSearchResultIsWithClientNameAndPan)

            // setflag(false)
            if (clientList.lenght == 0) {
              setclientList({ client_id: 0, name: "+Add New Client", })
            }
            // if( clientList.lenght >= 1){
            //   let joined = {client_id: 0, name: "+Add New Client",}
            //   setclientList({...clientList, joined })
            //   console.log("setclientList in >= 1",clientList)
            // }
          }
          if (clientDetails.success == false) {
            setclientList({ client_id: 0, name: "+Add New Client", })
            Swal.fire({
              icon: 'error',
              text: clientDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
      // .catch(err => {
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });
    }
  }

  const handleGroupSelect = async(event,value) => {
    console.log("handle GroupSelect event.target.value ============",event.target.value, event.target)
    console.log("handle GroupSelect value ============",value)
    console.log("handle GroupSelect value.length ============",value.lenght)
    let eventHere =  event.target.value 
    let ogvalueHere = value

    let checkValueIsEmpty = "Yes";
    ogvalueHere.map((item, index)=>{
      if(item){
        console.log("item in map of ogvalue",item,index)
        checkValueIsEmpty = "No"
      }
      // else {
      //   console.log("item in map of ogvalue == item not found in map of ogvalue",item,index)
      // }
    })
    console.log("checkValueIsEmpty",checkValueIsEmpty)

    // if ( ogvalueHere == [] ){
    //   console.log("value is ==== blank")
    // }
    // else{
    //   console.log("value is ==== not blank")
    // }

    // if( event.target.value ===  0 )
    if( checkValueIsEmpty == "No" )
    {
      let valueHere = []
      ogvalueHere.map((item, index)=>{
        let a = valueHere
        a.push(item.client_group_id)
        valueHere = a
      })
      console.log("valueHere, grpsHere",valueHere)
      await setnewCaseDetailsBulk({...newCaseDetailsBulk, client_groups: valueHere })

      await setnewCaseForBulkCreation(true);
      await setnewCaseForSingleClient(false);
      await setselectedClient("");
      await setselectedClientPAN("");
      await setSelectedClientState("");
      await setnewCaseDetails({ ...newCaseDetails, client_id: "" })
    }
    else {
      // await setnewCaseForBulkCreation(false);
      // await setnewCaseForSingleClient(false);
      await setselectedClient("");
      await setselectedClientPAN("");
      await setSelectedClientState("");
      await setnewCaseDetails({
        created_by: "business",
        service_id: "",
        client_id: "",
        name: "",
        description: "",
        assessment_year: "",
        self_assessment: "",
        assesse_id: "",
        assesse_name: "",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
        is_batch: false,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      await setfillingForClient("")
      await setnewCaseForBulkCreation(false);
      await setnewCaseForSingleClient(false);


      setnewCaseDetailsBulk({
        ...newCaseDetailsBulk,
        client_groups: [],
        service_id: "",
        assessment_year: "",
        case_name: "",
        case_desc: "",
        is_batch: true,
      })

      setAddNewContactFlag(false);
      setClientContactDetails([]);
      setclientList([]);
      setsuperCategoryValue("");
      setsubCategoryValue("");
      setlastCategoryValue("");

      await setSubCtegoryFetched(false);
      await setSelectedCategoryIsCategory(false);
  
      setcaseNameErr("");
      setcaseDescriptionErr("");
      setcaseServiceErr("");
      setcaseAssesYearErr("");
  
      setcaseAsseNameErr("");
      setcaseAsseEmailErr("");
      setcaseAssePanErr("");
      setcaseAssePhoneErr("");
    }
  }

  const handleChangeSuperAcategoryForBulkCreation  = (e) => {
    console.log("e of super category", e.target.value)
    setsuperCategoryValue(Number(e.target.value));
    setsubCategoryValue("");
    setSelectedCategoryIsCategory(false);
    setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    setnewCaseDetails({ ...newCaseDetails, service_id: "" })

    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'parent_category_id': e.target.value, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };

    fetch(process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY, requestOptions)
      .then((Response) => Response.json())
      .then(subCategoryDetails => {
        console.log("subCategoryDetails for list", subCategoryDetails.data.category_children);
        if (subCategoryDetails.data.category_children) {
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true))
        }
        else {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategoryBulkCreation = async (e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e of handleChangeSubcategory", e)
    console.log("e of handleChangeSubcategory", e.target.value)


    if (serviceIdFinalIs == "") {
      setnewCaseDetailsBulk({ ...newCaseDetailsBulk, service_id: "" })
      setSelectedCategoryIsCategory(false)
      setcategoryDetailsLastList([])
    }

    let matchedCategory = "";
    subCategoryList.map((item, index) => {
      if (e.target.value == item.category_id) {
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory", matchedCategory)

    console.log("e of catType matchedCategory", matchedCategory)
    await setsubCategoryValue(Number(e.target.value));
    setlastCategoryValue("")

    // if( catType == "service" ){
    if (matchedCategory.category_type == "service") {
      setSelectedCategoryIsCategory(false)
      console.log("service is selected now set service to object")
      await setnewCaseDetailsBulk({ ...newCaseDetailsBulk, service_id: JSON.parse(serviceIdFinalIs) })
      console.log('serviceID is newCaseDetailsBulk', newCaseDetailsBulk)
    }
    else if (matchedCategory.category_type == "category") {
      setSelectedCategoryIsCategory(true)
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'parent_category_id': serviceIdFinalIs, 'business_id': business_id,
          'device_token': device_token, 'session_id': session_id
        })
      };

      fetch(process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY, requestOptions)
        .then((Response) => Response.json())
        .then(categoryLastDetails => {
          console.log("categoryLastDetails for list", categoryLastDetails.data.category_children);
          if (categoryLastDetails.data.category_children) {
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true))
          }
          else {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleChangeLastCategoryBulkCration = async (e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category", e.target.value)

    if (serviceIdFinalIs == "") {
      setlastCategoryValue("");
      setnewCaseDetailsBulk({ ...newCaseDetailsBulk, service_id: "" })
    }
    else {
      await setlastCategoryValue(Number(e.target.value));

      console.log("last category service is selected now set it to object")
      await setnewCaseDetailsBulk({ ...newCaseDetailsBulk, service_id: JSON.parse(serviceIdFinalIs) })
      console.log('serviceID is newCaseDetailsBulk', newCaseDetailsBulk)
    }
  }

  const validateBulkCaseCreation = () => {
    let caseNameErr = '';
    let caseDescriptionErr = '';
    let caseServiceErr = '';
    let caseAssesYearErr = '';

    if (!newCaseDetailsBulk.case_name) {
      caseNameErr = 'Please Enter Case Name';
    }
    if (!newCaseDetailsBulk.case_desc) {
      caseDescriptionErr = 'Please Enter Case Description';
    }
    if (!newCaseDetailsBulk.service_id) {
      caseServiceErr = 'Please Select Service';
    }
    if (!newCaseDetailsBulk.assessment_year) {
      caseAssesYearErr = 'Please Select Assessment Year';
    }


    if (caseNameErr || caseDescriptionErr || caseServiceErr || caseAssesYearErr) {
      setcaseNameErr(caseNameErr);
      setcaseDescriptionErr(caseDescriptionErr);
      setcaseServiceErr(caseServiceErr);
      setcaseAssesYearErr(caseAssesYearErr);

      return false;
    }
    return true;
  }

  const handelSubmitCreateBulkCases = () => {
    console.log('serviceID is newCaseDetailsBulk in submit', newCaseDetailsBulk, selectedAssesmentYear);

    const isValid = validateBulkCaseCreation();
    if (isValid) {
      setShowProcedure2(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(newCaseDetailsBulk)
      };
      fetch(process.env.REACT_APP_BULK_CASE_CREATION, requestOptions)
        .then((Response) => Response.json())
        .then(bulkCaseCreatedDetails => {
          setShowProcedure2(false)
          console.log("bulkCaseCreatedDetails on Admin", bulkCaseCreatedDetails);
          if (bulkCaseCreatedDetails.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Cases created successfully.",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setnewCaseDetailsBulk({
              ...newCaseDetailsBulk,
              client_groups: [],
              service_id: "",
              assessment_year: "",
              case_name: "",
              case_desc: "",
              is_batch: true,
            })
            setRedirectCaseTable(true);

            setsuperCategoryValue("");
            setsubCategoryValue("");
            setlastCategoryValue("");
            setselectedClient("");
            
            setSubCtegoryFetched(false);

            // setcaseNameErr("");
            // setcaseDescriptionErr("");
            setcaseServiceErr("");
            setcaseAssesYearErr("");

          }
          if (bulkCaseCreatedDetails.success == false) {
            Swal.fire({
              icon: 'error',
              text: bulkCaseCreatedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure2(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleClienSelect = async (value) => {
    console.log("value in handleClienSelect", value)
    setfillingForClient("");

    if (value !== null) {
      settoggleRadio(true)
      await console.log("value in handleClienSelect.client_id", value.client_id)
      await setselectedClient(value.client_id);
      await setselectedClientPAN(value.client_pan);
      await setSelectedClientState(value.client_state);
      await setnewCaseDetails({ ...newCaseDetails, client_id: value.client_id })
      await setnewCaseForSingleClient(true);
      await setnewCaseForBulkCreation(false);
      await setnewCaseDetailsBulk({...newCaseDetailsBulk, client_groups: [] })

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'client_id': value.client_id,
          'device_token': device_token, 'session_id': session_id, 'business_id': business_id
        })
      };
      fetch(process.env.REACT_APP_GET_CLIENT_CONTACTS, requestOptions)
        .then((Response) => Response.json())
        .then(async (clientContactDetails) => {
          settoggleRadio(false)
          if (clientContactDetails.success == true) {
            if (clientContactDetails.data.contacts_info) {
              settoggleRadio(true)
              console.log("clientContactDetails for list", clientContactDetails.data.contacts_info.contacts);
              setClientContactDetails(clientContactDetails.data.contacts_info.contacts)
              setClientIsRegisteredOrNot("Yes")
              // await setnewCaseDetails({...newCaseDetails, self_assessment: false })
              setfillingForClient("");
            }
          }
          if (clientContactDetails.success == false) {
            setClientIsRegisteredOrNot("No")
            setnewCaseDetails({ ...newCaseDetails, self_assessment: true, client_id: value.client_id })
            setfillingForClient("true");
            settoggleRadio(true)
            setClientContactDetails([])
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
    else if (value == null) {
      console.log("value in handleClienSelect.client_id is null",)
      await setselectedClient("");
      await setselectedClientPAN("");
      await setSelectedClientState("");
      await setnewCaseDetails({
        created_by: "business",
        service_id: "",
        client_id: "",
        name: "",
        description: "",
        assessment_year: "",
        self_assessment: "",
        assesse_id: "",
        assesse_name: "",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
        is_batch: false,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      await setfillingForClient("")
      await setnewCaseForBulkCreation(false);
      await setnewCaseForSingleClient(false);


      setnewCaseDetailsBulk({
        ...newCaseDetailsBulk,
        client_groups: [],
        service_id: "",
        assessment_year: "",
        case_name: "",
        case_desc: "",
        is_batch: true,
      })

      setAddNewContactFlag(false);
      setClientContactDetails([]);
      setclientList([]);
      setsuperCategoryValue("");
      setsubCategoryValue("");
      setlastCategoryValue("");

      await setSubCtegoryFetched(false);
      await setSelectedCategoryIsCategory(false);
  
      setcaseNameErr("");
      setcaseDescriptionErr("");
      setcaseServiceErr("");
      setcaseAssesYearErr("");
  
      setcaseAsseNameErr("");
      setcaseAsseEmailErr("");
      setcaseAssePanErr("");
      setcaseAssePhoneErr("");

    }
  }

  const fillingForRadioChange = async (e) => {
    console.log("filling for client", e.target.value)
    let valueHere = e.target.value
    await setnewCaseDetails({ ...newCaseDetails, self_assessment: JSON.parse(valueHere) })
    await setfillingForClient(valueHere)
    if (valueHere == "true") {
      setAddNewContactFlag(false)
    }

    // this 3 lines causing issue
    // setnewCaseDetails({...newCaseDetails, service_id: ""})
    // setSelectedCategoryIsCategory(false)
    // setlastCategoryValue("");

    // await setnewCaseDetails({
    //   ...newCaseDetails,
    //   created_by:"business",
    //   service_id: "",
    //   name:"",
    //   description: "",
    //   self_assessment : "",
    //   assessment_year: "",
    //   assesse_name: "",
    //   assesse_email: "",
    //   assesse_pan: "",
    //   assesse_phone: "",
    // assesse_country_code: "",
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })
    // setsuperCategoryValue("");
    // setsubCategoryValue("");
    // setlastCategoryValue("");
    // setselectedClient("");
    // setselectedClientPAN("");
    // await setSubCtegoryFetched(false);
    // await setSelectedCategoryIsCategory(false);

    // setcaseNameErr("");
    // setcaseDescriptionErr("");
    // setcaseServiceErr("");
    // setcaseAssesYearErr("");

    // setcaseAsseNameErr("");
    // setcaseAsseEmailErr("");
    // setcaseAssePanErr("");
    // setcaseAssePhoneErr("");


  }

  const handleChangeSelectOtherContact = async (e) => {
    console.log("handleChangeSelect OtherContact for client", e.target.value)
    let valueHere = e.target.value
    if (valueHere == "Add New Contact") {
      console.log("in handleChangeSelect OtherContact in == Add New Contact", valueHere)
      await setAddNewContactFlag(true);
      await setAssenameOrOtherContactSelected(true);
      await setnewCaseDetails({ ...newCaseDetails, assesse_id: "" })
    }
    else if (valueHere !== "") {
      console.log("in handleChangeSelect OtherContact in !== blank ", valueHere)
      await setAddNewContactFlag(false);
      await setAssenameOrOtherContactSelected(true);
      await setnewCaseDetails({ ...newCaseDetails, assesse_id: valueHere })
    }
    else if (valueHere == "") {
      console.log("in handleChangeSelect OtherContact in == blank ", valueHere)
      await setAddNewContactFlag(false);
      await setAssenameOrOtherContactSelected(false);
      await setnewCaseDetails({ ...newCaseDetails, assesse_id: "" })
    }
  }

  const otherContactNumberChange = async (assessePhoneNumber, e, formattedValue, value) => {
    console.log("phoneNumberChange assessePhoneNumber", assessePhoneNumber)
    console.log("phoneNumberChange e", e)
    console.log("phoneNumberChange value", value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""
    if (e.countryCode == "in") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "us") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "gb") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ca") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ae") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "sg") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "np") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "jp") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "de") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "bt") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "au") {
      splitNoIs = value.split(" ")[1];
    }

    console.log("splitNoIs", splitNoIs)
    let noHereIs = "+" + e.dialCode + "-" + splitNoIs;
    console.log("noHereIs", noHereIs)
    setCountryCode(e.countryCode)

    // await setnewCaseDetails({...newCaseDetails, assesse_phone:noHereIs })
    await setnewCaseDetails({ ...newCaseDetails, assesse_phone: splitNoIs, assesse_country_code: countryCodeIs })
  }

  const handleChangeSuperAcategory = (e) => {
    console.log("e of super category", e.target.value)
    setsuperCategoryValue(Number(e.target.value));
    setsubCategoryValue("");
    setSelectedCategoryIsCategory(false);
    setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    setnewCaseDetails({ ...newCaseDetails, service_id: "" })

    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'parent_category_id': e.target.value, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };

    fetch(process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY, requestOptions)
      .then((Response) => Response.json())
      .then(subCategoryDetails => {
        console.log("subCategoryDetails for list", subCategoryDetails.data.category_children);
        if (subCategoryDetails.data.category_children) {
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true))
        }
        else {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategory = async (e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e of handleChangeSubcategory", e)
    console.log("e of handleChangeSubcategory", e.target.value)


    if (serviceIdFinalIs == "") {
      setnewCaseDetails({ ...newCaseDetails, service_id: "" })
      setSelectedCategoryIsCategory(false)
      setcategoryDetailsLastList([])
    }

    let matchedCategory = "";
    subCategoryList.map((item, index) => {
      if (e.target.value == item.category_id) {
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory", matchedCategory)

    console.log("e of catType matchedCategory", matchedCategory)
    await setsubCategoryValue(Number(e.target.value));
    setlastCategoryValue("")

    // if( catType == "service" ){
    if (matchedCategory.category_type == "service") {
      setSelectedCategoryIsCategory(false)
      console.log("service is selected now set service to object")
      await setnewCaseDetails({ ...newCaseDetails, service_id: JSON.parse(serviceIdFinalIs) })
      console.log('serviceID is newCaseDetails', newCaseDetails)
    }
    else if (matchedCategory.category_type == "category") {
      setSelectedCategoryIsCategory(true)
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'parent_category_id': serviceIdFinalIs, 'business_id': business_id,
          'device_token': device_token, 'session_id': session_id
        })
      };

      fetch(process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY, requestOptions)
        .then((Response) => Response.json())
        .then(categoryLastDetails => {
          console.log("categoryLastDetails for list", categoryLastDetails.data.category_children);
          if (categoryLastDetails.data.category_children) {
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true))
          }
          else {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleChangeLastCategory = async (e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category", e.target.value)

    if (serviceIdFinalIs == "") {
      setlastCategoryValue("");
      setnewCaseDetails({ ...newCaseDetails, service_id: "" })
    }
    else {
      await setlastCategoryValue(Number(e.target.value));

      console.log("last category service is selected now set it to object")
      await setnewCaseDetails({ ...newCaseDetails, service_id: JSON.parse(serviceIdFinalIs) })
      console.log('serviceID is newCaseDetails', newCaseDetails)
    }
  }

  const handleChangeAssesmentYear = (date) => {
    console.log("in handleChangeAssesment Year", date)
    console.log("date.getFullYear()", JSON.stringify(date.getFullYear()))
    let assesmentYearHere = JSON.stringify(date.getFullYear())
    setnewCaseDetails({ ...newCaseDetails, assessment_year: assesmentYearHere })
  }

  const validate = () => {
    let caseNameErr = '';
    let caseDescriptionErr = '';
    let caseServiceErr = '';
    let caseAssesYearErr = '';

    let caseAsseNameErr = '';
    let caseAsseEmailErr = '';
    let caseAssePanErr = '';
    let caseAssePhoneErr = '';

    if (!newCaseDetails.name) {
      caseNameErr = 'Please Enter Case Name';
    }
    if (!newCaseDetails.description) {
      caseDescriptionErr = 'Please Enter Case Description';
    }
    if (!newCaseDetails.service_id) {
      caseServiceErr = 'Please Select Service';
    }
    if (!newCaseDetails.assessment_year) {
      caseAssesYearErr = 'Please Select Assessment Year';
    }

    if (AddNewContactFlag == true) {
      console.log("in add new contact validation")
      if (!newCaseDetails.assesse_name) {
        caseAsseNameErr = 'Please Enter ASSESSE Name';
      }

      if (!newCaseDetails.assesse_email) {
        caseAsseEmailErr = 'Please Enter ASSESSE Email';
      }
      else if (typeof newCaseDetails.assesse_email !== "undefined") {
        let lastAtPos = newCaseDetails.assesse_email.lastIndexOf('@');
        let lastDotPos = newCaseDetails.assesse_email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
          caseAsseEmailErr = "Email is not valid";
          if (caseAsseEmailErr) {
            setcaseAsseEmailErr(caseAsseEmailErr);
          }
        }
        else {
          setcaseAsseEmailErr();
        }
      }
      else {
        setcaseAsseEmailErr();
      }

      if (!newCaseDetails.assesse_pan) {
        caseAssePanErr = 'Please Enter ASSESSE PAN';
      }
      else if (typeof newCaseDetails.assesse_pan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if (newCaseDetails.assesse_pan.length !== 10) {
          console.log("in != 10")
          caseAssePanErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(newCaseDetails.assesse_pan)) {
          console.log("in string check")
          caseAssePanErr = "PAN Number is Not Valid";
        }
      }

      if (!newCaseDetails.assesse_phone) {
        caseAssePhoneErr = 'Please Enter ASSESSE Phone Number';
      }
      else if (typeof newCaseDetails.assesse_phone !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)
        if (CountryCode == "in") {
          if (newCaseDetails.assesse_phone.length !== 10) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "us") {
          if (newCaseDetails.assesse_phone.length !== 10) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "gb") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 10) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "ca") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 10) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "ae") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 12) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "sg") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 8) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "np") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 12) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "jp") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 10) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "de") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 12) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "bt") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 12) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
        else if (CountryCode == "au") {
          console.log("newCaseDetails.assesse_phone.length gb", newCaseDetails.assesse_phone.length)
          if (newCaseDetails.assesse_phone.length !== 13) {
            caseAssePhoneErr = "Please enter valid phone number.";
          }
        }
      }

    }
    else if (AddNewContactFlag == false) {
      caseAsseNameErr = '';
      caseAsseEmailErr = '';
      caseAssePanErr = '';
      caseAssePhoneErr = '';
    }


    if (caseNameErr || caseDescriptionErr || caseServiceErr || caseAssesYearErr || caseAsseNameErr ||
      caseAsseEmailErr || caseAssePanErr || caseAssePhoneErr) {
      setcaseNameErr(caseNameErr);
      setcaseDescriptionErr(caseDescriptionErr);
      setcaseServiceErr(caseServiceErr);
      setcaseAssesYearErr(caseAssesYearErr);
      setcaseAsseNameErr(caseAsseNameErr);
      setcaseAsseEmailErr(caseAsseEmailErr);
      setcaseAssePanErr(caseAssePanErr);
      setcaseAssePhoneErr(caseAssePhoneErr);

      return false;
    }
    return true;
  }

  const handelSubmitCreateCase = () => {
    console.log('serviceID is newCaseDetails in submit', newCaseDetails, selectedAssesmentYear);

    const isValid = validate();
    if (isValid) {
      setShowProcedure2(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ ...newCaseDetails, 'business_id': business_id })
      };
      fetch(process.env.REACT_APP_CREATE_CASE, requestOptions)
        .then((Response) => Response.json())
        .then(caseCreatedDetails => {
          setShowProcedure2(false)
          console.log("caseCreatedDetails on Admin", caseCreatedDetails);
          if (caseCreatedDetails.success == true) {
            // localStorage.removeItem('view_clients_active_cases');
            localStorage.removeItem('createNewCaseForSelectedClient');
            Swal.fire({
              icon: 'success',
              text: "Case created successfully.",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setnewCaseDetails({
              created_by: "business",
              service_id: "",
              // client_id:"",
              name: "",
              description: "",
              assessment_year: "",
              self_assessment: "",
              assesse_id: "",
              assesse_name: "",
              assesse_email: "",
              assesse_pan: "",
              assesse_phone: "",
              assesse_country_code: "",
              is_batch: false,
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            })
            setRedirectCaseTable(true);

            setsuperCategoryValue("");
            setsubCategoryValue("");
            setlastCategoryValue("");
            setselectedClient("");
            setselectedClientPAN("");
            setSelectedClientState("");
            setSubCtegoryFetched(false);

            setcaseNameErr("");
            setcaseDescriptionErr("");
            setcaseServiceErr("");
            setcaseAssesYearErr("");

            setcaseAsseNameErr("");
            setcaseAsseEmailErr("");
            setcaseAssePanErr("");
            setcaseAssePhoneErr("");

          }
          if (caseCreatedDetails.success == false) {
            Swal.fire({
              icon: 'error',
              text: caseCreatedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure2(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handelSubmitCreateCaseReset = async () => {
    console.log("reset is cliecked");
    setnewCaseDetails({
      ...newCaseDetails,
      created_by: "business",
      service_id: "",
      // client_id:"",
      name: "",
      description: "",
      assessment_year: "",
      assesse_name: "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      is_batch: false,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setnewCaseDetailsBulk({
      ...newCaseDetailsBulk,
      client_groups: [],
      service_id: "",
      assessment_year: "",
      case_name: "",
      case_desc: "",
      is_batch: true,
    })
    setnewCaseForBulkCreation(false);
    setnewCaseForSingleClient(false);

    setfillingForClient("");
    setAddNewContactFlag(false);
    setClientContactDetails([]);
    setclientList([]);
    setsuperCategoryValue("");
    setsubCategoryValue("");
    setlastCategoryValue("");
    setselectedClient("");
    setselectedClientPAN("");
    setSelectedClientState("");
    await setSubCtegoryFetched(false);
    await setSelectedCategoryIsCategory(false);

    setcaseNameErr("");
    setcaseDescriptionErr("");
    setcaseServiceErr("");
    setcaseAssesYearErr("");

    setcaseAsseNameErr("");
    setcaseAsseEmailErr("");
    setcaseAssePanErr("");
    setcaseAssePhoneErr("");

    // await setselectedClient("");
    setflag(false)
  }

  const handelNewClientSelect = (e) => {
    console.log("Add new client clicked");
    setRedirectAddNewClient(true);
    // <Link to="/AddNewClient"/>
    // return <Link to="/AddNewClient" />

    // <Link to="/newclient"/>

  }

  const handelRemoveCaseForSelectedClient = () => {
    console.log("in handelRemoveCaseForSelectedClient")
    setcheckCaseIsForSelectedClientOrNot(false)
    localStorage.removeItem('view_clients_active_cases');
    localStorage.removeItem('createNewCaseForSelectedClient');
    setnewCaseDetails({
      ...newCaseDetails,
      created_by: "business",
      service_id: "",
      // client_id:"",
      name: "",
      description: "",
      assessment_year: "",
      assesse_name: "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      is_batch: false,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setnewCaseDetailsBulk({
      ...newCaseDetailsBulk,
      client_groups: [],
      service_id: "",
      assessment_year: "",
      case_name: "",
      case_desc: "",
      is_batch: true,
    })
    setnewCaseForBulkCreation(false);
    setnewCaseForSingleClient(false);

    setfillingForClient("");
    setAddNewContactFlag(false);
    setClientContactDetails([]);
    setclientList([]);
    setsuperCategoryValue("");
    setsubCategoryValue("");
    setlastCategoryValue("");
    setselectedClient("");
    setselectedClientPAN("");
    setSelectedClientState("");
     setSubCtegoryFetched(false);
     setSelectedCategoryIsCategory(false);

    setcaseNameErr("");
    setcaseDescriptionErr("");
    setcaseServiceErr("");
    setcaseAssesYearErr("");

    setcaseAsseNameErr("");
    setcaseAsseEmailErr("");
    setcaseAssePanErr("");
    setcaseAssePhoneErr("");

    // await setselectedClient("");
    setflag(false)
  }

  const handelCreateCategoryTree = () => {
    console.log("handel Create Category Tree clicked");
    setRedirectCategoryCreation(true);
  }

  const handleTaxManagementExplorer = () => {
    console.log("handle make category live clicked");
    setRedirectTaxManagementExplorer(true);
  }

  if (RedirectCaseTable == true) {
    return <Redirect to={`/cases`} />
  }
  else if (RedirectAddNewClient == true) {
    // return <Redirect to={`/AddNewClient`} />
    return <Redirect to={`/newclient`} />
  }
  else if (RedirectCategoryCreation == true) {
    return <Redirect to={`/TaxManagementCategoryCreation`} />
  }
  else if (RedirectTaxManagementExplorer == true) {
    return <Redirect to={'/TaxManagementExplorer'} />
  }
  else {
    return (
      // console.log("AssesmentYearArray in return is",AssesmentYearArray),
      // console.log("CategoryCreation.length return",CategoryCreation.length),
      // console.log("clientList in return",clientList),
      // console.log("selectedClient in return",selectedClient,newCaseDetails),
      // console.log("newCaseDetails in return", newCaseDetails),
      // console.log("newCaseDetailsBulk in return", newCaseDetailsBulk),
      // console.log("newCaseForBulkCreation in return====",newCaseForBulkCreation),
      // console.log("newCaseForSingleClient in return====",newCaseForSingleClient),
      // console.log("ClientContactDetails",ClientContactDetails),
      console.log("selectedClientPAN in return",selectedClientPAN),
      <div className={classes.root}>
        <div>
          {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </Modal> : null}

          {ShowProcedure2 ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <div className={classes.wrapper}>
            {/* <Grid container>
            <Grid item xs={1} className={classes.pageHeaderLabel} >
              <Button href="/cases" className={classes.backButton}>
                  <span className={classes.backArrow}>
                  <ArrowBackIcon fontSize="small" />
                </span>
                Back
              </Button>
            </Grid>
          </Grid> */}
            <Grid container className={classes.pageHeaderContainer} style={{ marginTop: '1%' }}>
              <Grid item xs={6} className={classes.pageHeaderLabel}>
                {/* <FormattedMessage id="newCasesLabel" /> */}
                New Case
              </Grid>

              {user_permissions && user_permissions.permissions.view_client_cases &&
                <Grid item xs={6} className={classes.linkBackToCase}>
                  <Link to="/cases" className={classes.linkNoUnderline}>
                    {/* <FormattedMessage id="navigateToCase" /> */}
                    View List of Cases
                  </Link>
                </Grid>
              }

            </Grid>
            {/* <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/cases" className={classes.linkNoUnderline}>
                {/* <FormattedMessage id="navigateToCase" /> */}
            {/* View List of Cases
              </Link>
            </Grid>
          </Grid> */}

            {/* here category empty */}
            {
              ( CategoryCreation && CategoryCreation.length == 0) ?
                (
                  <div>
                    {
                      (ShowProcedure == false) ?
                        (
                          <Grid className={classes.wrapperInnerCreateCategory}>
                            <Grid className={classes.createCatHeadingLabel}>
                              {/* At least one Category Hierarchy must be made live to create Cases. */}
                              Create atleast one hierarchy and make it live to start creating cases.
                            </Grid>

                            <Grid container justify="center">
                              <Grid className={classes.createCatHeadingLabel}>
                                Click on&nbsp;
                              </Grid>
                              <Grid
                                className={classes.createCatHeadingLabelClickHere}
                                onClick={handelCreateCategoryTree}
                              >
                                Create Category
                              </Grid>
                              <Grid className={classes.createCatHeadingLabel}>
                                &nbsp;to create a hierarchy.
                              </Grid>
                            </Grid>

                            <Grid container justify="center">
                              <Grid className={classes.createCatHeadingLabel}>
                                Click on&nbsp;
                              </Grid>
                              <Grid
                                className={classes.createCatHeadingLabelClickHere}
                                onClick={handleTaxManagementExplorer}
                              >
                                Make Categories Live
                              </Grid>
                              <Grid className={classes.createCatHeadingLabel}>
                                &nbsp;to make them live.
                              </Grid>
                            </Grid>

                          </Grid>
                        ) :
                        (
                          <Grid></Grid>
                        )
                    }
                  </div>
                )
                : (
                  <div>
                    {
                      (ShowProcedure == false) ?
                        (
                          <Grid container className={classes.wrapperInner}>
                            <Grid item xs={12} className={classes.rootInner}>

                              {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                                  Client Name or PAN *
                                </Grid> */}
                              <Grid item xs={12} style={{ marginTop: '2%' }}>
                                {/* Condition to cehck if client is already selected */}
                                {
                                  ( checkCaseIsForSelectedClientOrNot == true ) ? 
                                  (
                                    <Grid container item xs={12} className={classes.subHeadingLabel} style={{marginBottom:'-1%'}}>
                                      <Grid item xs={10} className={classes.subHeadingLabel}>
                                        Client Name : {selectedClientNameIs}
                                      </Grid>
                                      <Grid item xs={2} className={classes.subHeadingLabel}>
                                        <Tooltip
                                          title="Create case for another client or group"
                                          placement="center"
                                        >
                                          <CloseIcon 
                                            onClick={() => handelRemoveCaseForSelectedClient()} 
                                            style={{ fontSize: 20, cursor: 'pointer' }} 
                                          />
                                        </Tooltip>
                                        
                                      </Grid>

                                    </Grid>
                                  ):
                                  (
                                    <Grid item xs={12}>
                                      {/* {console.log("clientList before autocomplete", clientList)} */}
                                      <Grid container item xs={12} spacing={2}>
                                        {/* To select Client for case */}
                                        <Grid item xs={6}>
                                          {/* To select client for case */}
                                          <Autocomplete
                                            disabled={newCaseForBulkCreation && newCaseForBulkCreation === true ? true : false}
                                            options={clientList}
                                            // {user_permissions && user_permissions.permissions.search && }
                                            noOptionsText={
                                              (user_permissions && user_permissions.permissions.create_client === true) ?
                                                (
                                                  <Button
                                                    className={classes.AddNewClientButton}
                                                    onMouseDown={(e) => {
                                                      handelNewClientSelect(e)
                                                    }}
                                                  >
                                                    + Add New Client
                                                  </Button>
                                                ) :
                                                (
                                                  <div></div>
                                                )
                                            }

                                            getOptionLabel={(option) => option.client_name}
                                            // getOptionLabel={(option) => {option.client_name + option.client_pan}}
                                            // getOptionLabel={(option) => option.name}
                                            // getOptionLabel={(option) => option.email}

                                            // getOptionLabel={(option) => option.first_name}
                                            // getOptionLabel={(option) => (option.client_type === "person" ?  option.name : option.email)}


                                            // getOptionLabel={(option) => option.name && option.client_pan}

                                            // getOptionLabel={(option) => (option.name ? option.name : option.client_pan)}

                                            // renderOption={(props, option) => [props, option]}

                                            // getOptionLabel={(option) => option.client_pan || option.name }
                                            // getOptionLabel={(option) => (option.client_pan ? option.client_pan : option.name)}
                                            // onChange={(event, value) => console.log(value)}
                                            // getOptionLabel={(option) => option}

                                            onChange={(event, value) => handleClienSelect(value)}
                                            renderInput={(params) =>
                                              <TextField
                                                {...params}
                                                placeholder="Search Client"
                                                variant="outlined"
                                                value={selectedClient}
                                                onChange={handleClientSearch}
                                                // label=" Client Name or PAN"
                                                autoFocus
                                                label="Client Name"
                                                required
                                              />
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          {/* To select groups for case */}
                                          <Autocomplete
                                            multiple
                                            disabled={newCaseForSingleClient && newCaseForSingleClient === true ? true : false}
                                            // id="tags-outlined"
                                            options={BusinessClientGroupDetails}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => handleGroupSelect(event, value)}
                                            defaultValue={newCaseDetailsBulk.client_groups}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Select Groups"
                                                variant="outlined"
                                                label="Select Groups"
                                                required
                                              />
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )
                                }
                              </Grid>

                              {
                                (selectedClientPAN && selectedClientPAN !== "") ?
                                  (<Grid item xs={12} className={classes.subHeadingLabel} >
                                    Client PAN : {selectedClientPAN}
                                  </Grid>) :
                                  (<div></div>)
                              }
                              {
                                (selectedClientState !== "") ?
                                  (<Grid item xs={12} className={classes.subHeadingLabel} >
                                    Client State : {(selectedClientState === "CREATED" || selectedClientState === "INVITED") ? "UNREGISTERED" : "REGISTERED"}
                                  </Grid>) :
                                  (<div></div>)
                              }


                              {
                                (selectedClient !== null && selectedClient !== "") ?
                                  (
                                    <Grid container>
                                      <Grid item xs={12} className={classes.subHeadingLabel}>
                                        {/* Form Filling for? */}
                                        Case Filling for?
                                      </Grid>
                                      {
                                        (toggleRadio == true) ?
                                          (
                                            <RadioGroup
                                              row
                                              aria-label="position"
                                              name="position"
                                              defaultValue={fillingForClient}
                                              onChange={fillingForRadioChange}
                                            >
                                              <div className={classes.customRadioButton}>
                                                <FormControlLabel
                                                  value="true"
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label="For Client"
                                                />
                                              </div>
                                              <div className={classes.customRadioButton}>
                                                <FormControlLabel
                                                  value="false"
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label="For Clients Other Contact"
                                                  disabled={ClientIsRegisteredOrNot == "No" ? true : false}
                                                />
                                              </div>
                                            </RadioGroup>
                                          ) :
                                          (<Grid></Grid>)
                                      }

                                      <Grid>
                                        {
                                          (ClientIsRegisteredOrNot == "No") ?
                                            (
                                              <Grid style={{ color: "grey", fontSize: '12px', marginLeft: '-10%' }}>
                                                *Option will enable once client is registered
                                              </Grid>
                                            ) :
                                            (<Grid></Grid>)
                                        }
                                      </Grid>
                                    </Grid>
                                  ) :
                                  (<Grid></Grid>)
                              }

                              {
                                (fillingForClient == "true") ?
                                  (
                                    // <div>client is selected</div>
                                    <Grid item xs={12}>
                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          autoFocus
                                          required
                                          label="Case Name"
                                          id="name"
                                          name="name"
                                          value={newCaseDetails.name}
                                          onChange={(e) => setnewCaseDetails({ ...newCaseDetails, name: e.target.value })}
                                        />
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetails.name) ? (<div></div>) : (caseNameErr)}</div>

                                      {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                              Case Description *
                            </Grid> */}
                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          required
                                          label="Case Description"
                                          id="description"
                                          name="description"
                                          value={newCaseDetails.description}
                                          onChange={(e) => setnewCaseDetails({ ...newCaseDetails, description: e.target.value })}
                                        />
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetails.description) ? (<div></div>) : (caseDescriptionErr)}</div>

                                      <Grid container item xs={12}>
                                        <Grid item xs={4} style={{ marginTop: '2%' }}>
                                          <Grid className={classes.subHeadingLabelSelect} >
                                            Super Category *
                                          </Grid>
                                          {/* {
                                  superCategoryList.map((item, index) =>{
                                  // console.log("item map is",item);
                                    return(
                                      <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={superCategoryValue}
                                        // onChange={(event, value) => setsuperCategoryValue(value) }
                                        onChange={handleChangeSuperAcategory}
                                      >
                                        <div className={classes.customRadioButton}>
                                          <FormControlLabel
                                            key={index}
                                            value={item.category_id}
                                            classes={{ label: classes.label }}
                                            control={<Radio size="small" color="primary" />}
                                            label={item.name}
                                          />
                                        </div>
                                      </RadioGroup>
                                    )
                                  })
                                } */}
                                          <Grid>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                              <Select
                                                native
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={superCategoryValue}
                                                // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                onChange={handleChangeSuperAcategory}
                                              >
                                                <option value="" style={{ color: 'grey' }}>Select Super Category</option>
                                                {superCategoryList && superCategoryList.map(item =>
                                                  <option value={item.category_id}>{item.name}</option>
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                            {/* { (newCaseDetails.service_id) ? (<div></div>):(caseServiceErr)} */}
                                            {
                                              (caseServiceErr) ?
                                                (
                                                  <Grid>
                                                    {
                                                      (superCategoryValue) ? (<div></div>) : (
                                                        <div>Please select super category </div>
                                                      )
                                                    }
                                                  </Grid>
                                                ) :
                                                (
                                                  <Grid></Grid>
                                                )
                                            }
                                          </div>
                                        </Grid>

                                        {/* second sub category options */}
                                        {
                                          (SubCtegoryFetched == true) ?
                                            (
                                              <Grid item xs={4} style={{ marginTop: '2%' }} >
                                                <Grid className={classes.subHeadingLabelSelect}>
                                                  Category or Service *
                                                </Grid>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={subCategoryValue}
                                                    // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                    onChange={(e) => handleChangeSubcategory(e)}
                                                  >
                                                    <option value="" style={{ color: 'grey' }}>Select Category or Service</option>
                                                    {subCategoryList && subCategoryList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                {/* <Grid container item xs={12}>
                                    {
                                      subCategoryList.map((item, index) =>{
                                      // console.log("item map is",item);
                                        return(
                                          <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            value={subCategoryValue}
                                            onChange={(e) => handleChangeSubcategory(e, item.category_type)}
                                          >
                                            <div className={classes.customRadioButton}>
                                              <FormControlLabel
                                                key={index}
                                                value={item.category_id}
                                                classes={{ label: classes.label }}
                                                control={<Radio size="small" color="primary" />}
                                                label={item.name}
                                              />
                                            </div>
                                          </RadioGroup>
                                        )
                                      })
                                    }
                                    </Grid> */}
                                                <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                  {/* { (newCaseDetails.service_id) ? (<div></div>):(caseServiceErr)} */}
                                                  {
                                                    (caseServiceErr) ?
                                                      (
                                                        <Grid>
                                                          {
                                                            (subCategoryValue) ? (<div></div>) : (
                                                              <div>Please select category or service </div>
                                                            )
                                                          }
                                                        </Grid>
                                                      ) :
                                                      (
                                                        <Grid></Grid>
                                                      )
                                                  }
                                                </div>
                                              </Grid>
                                            ) :
                                            (<div></div>)
                                        }

                                        {/* Third if service navigate else if category then show options */}
                                        {
                                          (SelectedCategoryIsCategory == true) ?
                                            (
                                              <Grid item xs={4} style={{ marginTop: '2%' }}>
                                                <Grid className={classes.subHeadingLabelSelect}>
                                                  {/* Case Category */}
                                                  Service *
                                                </Grid>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={lastCategoryValue}
                                                    // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                    onChange={(e) => handleChangeLastCategory(e)}
                                                  >
                                                    <option value="" style={{ color: 'grey' }}>Select Service</option>
                                                    {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                {/* <Grid container item xs={12}>
                                    {
                                      categoryDetailsLastList.map((item, index) =>{
                                      // console.log("item map is",item);
                                        return(
                                          <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            value={lastCategoryValue}
                                            onChange={handleChangeLastCategory}
                                          >
                                            <div className={classes.customRadioButton}>
                                              <FormControlLabel
                                                key={index}
                                                value={item.category_id}
                                                classes={{ label: classes.label }}
                                                control={<Radio size="small" color="primary" />}
                                                label={item.name}
                                              />
                                            </div>
                                          </RadioGroup>
                                        )
                                      })
                                    }
                                    </Grid> */}
                                                <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                  {(newCaseDetails.service_id) ? (<div></div>) : (caseServiceErr)}
                                                </div>
                                              </Grid>

                                            ) :
                                            (<div></div>)
                                        }
                                      </Grid>

                                      <Grid item xs={4} style={{ marginTop: '2%' }}>
                                        <Grid className={classes.subHeadingLabelSelect}>
                                          Assesment Year *
                                        </Grid>
                                        <Grid style={{ textAlign: "left", marginLeft: '2%' }}>
                                          {/* <DatePicker
                                // Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) 
                                  // selected={newCaseDetails.assessment_year}
                                  selected={ Date.parse(moment(newCaseDetails.assessment_year, 'YYYY').toISOString()) }
                                  // onChange={(date) => setnewCaseDetails({...newCaseDetails, assessment_year: date.getFullYear() }) }
                                  // onChange={(date) => console.log("date.getFullYear()",JSON.stringify(date.getFullYear())) }
                                  onChange={(date) => handleChangeAssesmentYear(date) }
                                  placeholderText="Select Year"
                                  showYearPicker
                                  dateFormat="yyyy"
                                  yearItemNumber={8}
                                  minDate={minDate}
                                  maxDate={dueDate}
                                  // dateFormat={"yyyy"}
                                  className={classes.datePickerStyle}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  // containerstyle={{height:'50%'}}
                                /> */}
                                          <FormControl variant="outlined" className={classes.formControl} style={{ marginLeft: '1%' }} >
                                            <Select
                                              native
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={newCaseDetails.assessment_year}
                                              onChange={(e) => setnewCaseDetails({ ...newCaseDetails, assessment_year: e.target.value })}
                                            >
                                              <option value="" style={{ color: 'grey' }}>Select Assesment Year</option>
                                              {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                                <option value={item.value}>{item.item}</option>
                                              )}
                                              {/* <option value="AY-2014-15" >AY-2014-15</option>
                                    <option value="AY-2015-16" >AY-2015-16</option>
                                    <option value="AY-2016-17" >AY-2016-17</option>
                                    <option value="AY-2017-18" >AY-2017-18</option>
                                    <option value="AY-2018-19" >AY-2018-19</option>
                                    <option value="AY-2019-20" >AY-2019-20</option>
                                    <option value="AY-2020-21" >AY-2020-21</option>
                                    <option value="AY-2021-22" >AY-2021-22</option>
                                    <option value="AY-2022-23" >AY-2022-23</option>
                                    <option value="AY-2023-24" >AY-2023-24</option>
                                    <option value="AY-2024-25" >AY-2024-25</option>
                                    <option value="N/A" >N/A</option> */}


                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetails.assessment_year) ? (<div></div>) : (caseAssesYearErr)}</div>

                                      <Grid alignItems={'center'} style={{ marginTop: '2%' }}>
                                        <Button
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          color="primary"
                                          className={classes.submit}
                                          onClick={handelSubmitCreateCase}
                                        >
                                          Create Case
                                        </Button>

                                        <Button
                                          style={{ marginLeft: '1%' }}
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          color="primary"
                                          className={classes.submit}
                                          onClick={handelSubmitCreateCaseReset}
                                        >
                                          Reset
                                        </Button>

                                        <Button
                                          style={{ marginLeft: '1%' }}
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          // color="primary"
                                          className={classes.submit}
                                          href="/cases"
                                        // onClick={handelSubmitCreateCase}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>

                                    </Grid>
                                  ) :
                                  (<div></div>)
                              }

                              {
                                (fillingForClient == "false") ?
                                  (
                                    // <div>client is selected</div>
                                    <Grid item xs={12}>

                                      {
                                        (fillingForClient == "false") ?
                                          (
                                            <Grid item xs={12} style={{ marginTop: '1%' }}>
                                              <FormControl variant="outlined" className={classes.formControlOtherContact}>
                                                <Select
                                                  native
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  // value={newCaseDetails.assesse_name}
                                                  value={newCaseDetails.assesse_id}
                                                  autoFocus
                                                  // onChange={(e) => setnewCaseDetails({...newCaseDetails, assesse_name:e.target.value }) }
                                                  onChange={handleChangeSelectOtherContact}
                                                >
                                                  <option value="" style={{ color: 'grey' }}>Select Other Contact</option>
                                                  {
                                                    (user_permissions && user_permissions.permissions.add_contacts === true) ?
                                                      (
                                                        <option value="Add New Contact" style={{ color: 'blue' }}>Add New Contact</option>
                                                      ) :
                                                      (
                                                        <div></div>
                                                      )
                                                  }
                                                  {ClientContactDetails && ClientContactDetails.map(item =>
                                                    <option value={item.assesse_id}>{item.name}</option>
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </Grid>

                                          )
                                          : (<Grid></Grid>)
                                      }

                                      {
                                        (AssenameOrOtherContactSelected == true) ?
                                          (
                                            <Grid>
                                              {
                                                (AddNewContactFlag == true) ?
                                                  (
                                                    <Grid>
                                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                        <TextField
                                                          variant="outlined"
                                                          fullWidth
                                                          autoFocus
                                                          required
                                                          label="Enter ASSESSE Name"
                                                          id="assesse_name"
                                                          name="assesse_name"
                                                          value={newCaseDetails.assesse_name}
                                                          onChange={(e) => setnewCaseDetails({ ...newCaseDetails, assesse_name: e.target.value })}
                                                        />
                                                      </Grid>
                                                      <div className={classes.validation}>{(newCaseDetails.assesse_name) ? (<div></div>) : (caseAsseNameErr)}</div>

                                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                        <TextField
                                                          variant="outlined"
                                                          fullWidth
                                                          required
                                                          label="Enter ASSESSE Email"
                                                          id="assesse_email"
                                                          name="assesse_email"
                                                          value={newCaseDetails.assesse_email}
                                                          onChange={(e) => setnewCaseDetails({ ...newCaseDetails, assesse_email: e.target.value })}
                                                        />
                                                      </Grid>
                                                      <div className={classes.validation}>{caseAsseEmailErr}</div>

                                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                        <TextField
                                                          variant="outlined"
                                                          fullWidth
                                                          required
                                                          label="Enter ASSESSE PAN"
                                                          placeholder="XXXXX0000X"
                                                          id="assesse_pan"
                                                          name="assesse_pan"
                                                          inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                                                          value={newCaseDetails.assesse_pan}
                                                          onChange={(e) => setnewCaseDetails({ ...newCaseDetails, assesse_pan: e.target.value.toUpperCase() })}
                                                        />
                                                      </Grid>
                                                      <div className={classes.validation}>{caseAssePanErr}</div>

                                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                        <PhoneInput
                                                          inputStyle={{
                                                            width: "100%",
                                                            height: "50px",
                                                            fontSize: "15px",
                                                            autoFocus: true
                                                          }}
                                                          countryCodeEditable={false}
                                                          onlyCountries={onlyCountryArray}
                                                          specialLabel="Mobile Number"
                                                          country={'in'}
                                                          masks={{
                                                            in: '..........', us: '..........', gb: '..........', ca: '..........', ae: '............',
                                                            sg: '........', np: '............', jp: '..........', de: '............', bt: '............',
                                                            au: '.............'
                                                          }}
                                                          // value={newCaseDetails.assesse_phone}
                                                          value={newCaseDetails.assesse_country_code + newCaseDetails.assesse_phone}
                                                          onChange={(assessePhoneNumber, e, formattedValue, value) => otherContactNumberChange(assessePhoneNumber, e, formattedValue, value)}
                                                        />
                                                      </Grid>
                                                      <div className={classes.validation}>{caseAssePhoneErr}</div>

                                                    </Grid>
                                                  )
                                                  : (<Grid></Grid>)
                                              }
                                              <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                <TextField
                                                  variant="outlined"
                                                  fullWidth
                                                  // autoFocus
                                                  required
                                                  label="Case Name"
                                                  id="name"
                                                  name="name"
                                                  value={newCaseDetails.name}
                                                  onChange={(e) => setnewCaseDetails({ ...newCaseDetails, name: e.target.value })}
                                                />
                                              </Grid>
                                              <div className={classes.validation}>{(newCaseDetails.name) ? (<div></div>) : (caseNameErr)}</div>

                                              {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                                          Case Description *
                                        </Grid> */}
                                              <Grid item xs={12} style={{ marginTop: '3%' }}>
                                                <TextField
                                                  variant="outlined"
                                                  fullWidth
                                                  required
                                                  label="Case Description"
                                                  id="description"
                                                  name="description"
                                                  value={newCaseDetails.description}
                                                  onChange={(e) => setnewCaseDetails({ ...newCaseDetails, description: e.target.value })}
                                                />
                                              </Grid>
                                              <div className={classes.validation}>{(newCaseDetails.description) ? (<div></div>) : (caseDescriptionErr)}</div>

                                              <Grid container item xs={12}>
                                                <Grid item xs={4} style={{ marginTop: '2%' }}>
                                                  <Grid className={classes.subHeadingLabelSelect} >
                                                    Super Category *
                                                  </Grid>
                                                  {/* {
                                              superCategoryList.map((item, index) =>{
                                              // console.log("item map is",item);
                                                return(
                                                  <RadioGroup
                                                    row
                                                    aria-label="position"
                                                    name="position"
                                                    value={superCategoryValue}
                                                    // onChange={(event, value) => setsuperCategoryValue(value) }
                                                    onChange={handleChangeSuperAcategory}
                                                  >
                                                    <div className={classes.customRadioButton}>
                                                      <FormControlLabel
                                                        key={index}
                                                        value={item.category_id}
                                                        classes={{ label: classes.label }}
                                                        control={<Radio size="small" color="primary" />}
                                                        label={item.name}
                                                      />
                                                    </div>
                                                  </RadioGroup>
                                                )
                                              })
                                            } */}
                                                  <Grid>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                      <Select
                                                        native
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={superCategoryValue}
                                                        // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                        onChange={handleChangeSuperAcategory}
                                                      >
                                                        <option value="" style={{ color: 'grey' }}>Select Super Category</option>
                                                        {superCategoryList && superCategoryList.map(item =>
                                                          <option value={item.category_id}>{item.name}</option>
                                                        )}
                                                      </Select>
                                                    </FormControl>
                                                  </Grid>
                                                  {/* <div className={classes.validation} style={{marginLeft:'1.5%'}}>{(newCaseDetails.service_id)?(<div></div>):(caseServiceErr)}</div> */}
                                                  <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                    {
                                                      (caseServiceErr) ?
                                                        (
                                                          <Grid>
                                                            {
                                                              (superCategoryValue) ? (<div></div>) : (
                                                                <div>Please select super category </div>
                                                              )
                                                            }
                                                          </Grid>
                                                        ) :
                                                        (
                                                          <Grid></Grid>
                                                        )
                                                    }
                                                  </div>
                                                </Grid>

                                                {/* second sub category options */}
                                                {
                                                  (SubCtegoryFetched == true) ?
                                                    (
                                                      <Grid item xs={4} style={{ marginTop: '2%' }} >
                                                        <Grid className={classes.subHeadingLabelSelect}>
                                                          Category or Service *
                                                        </Grid>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                          <Select
                                                            native
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={subCategoryValue}
                                                            // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                            onChange={(e) => handleChangeSubcategory(e)}
                                                          >
                                                            <option value="" style={{ color: 'grey' }}>Select Category or Service</option>
                                                            {subCategoryList && subCategoryList.map(item =>
                                                              <option value={item.category_id}>{item.name}</option>
                                                            )}
                                                          </Select>
                                                        </FormControl>
                                                        <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                          {
                                                            (caseServiceErr) ?
                                                              (
                                                                <Grid>
                                                                  {
                                                                    (subCategoryValue) ? (<div></div>) : (
                                                                      <div>Please select category or service</div>
                                                                    )
                                                                  }
                                                                </Grid>
                                                              ) :
                                                              (
                                                                <Grid></Grid>
                                                              )
                                                          }
                                                        </div>
                                                        {/* <Grid container item xs={12}>
                                                {
                                                  subCategoryList.map((item, index) =>{
                                                  // console.log("item map is",item);
                                                    return(
                                                      <RadioGroup
                                                        row
                                                        aria-label="position"
                                                        name="position"
                                                        value={subCategoryValue}
                                                        onChange={(e) => handleChangeSubcategory(e, item.category_type)}
                                                      >
                                                        <div className={classes.customRadioButton}>
                                                          <FormControlLabel
                                                            key={index}
                                                            value={item.category_id}
                                                            classes={{ label: classes.label }}
                                                            control={<Radio size="small" color="primary" />}
                                                            label={item.name}
                                                          />
                                                        </div>
                                                      </RadioGroup>
                                                    )
                                                  })
                                                }
                                                </Grid> */}
                                                      </Grid>
                                                    ) :
                                                    (<div></div>)
                                                }

                                                {/* Third if service navigate else if category then show options */}
                                                {
                                                  (SelectedCategoryIsCategory == true) ?
                                                    (
                                                      <Grid item xs={4} style={{ marginTop: '2%' }}>
                                                        <Grid className={classes.subHeadingLabelSelect}>
                                                          {/* Case Category */}
                                                          Service *
                                                        </Grid>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                          <Select
                                                            native
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={lastCategoryValue}
                                                            // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                            onChange={(e) => handleChangeLastCategory(e)}
                                                          >
                                                            <option value="" style={{ color: 'grey' }}>Select Service</option>
                                                            {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                              <option value={item.category_id}>{item.name}</option>
                                                            )}
                                                          </Select>
                                                        </FormControl>
                                                        <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                          {(newCaseDetails.service_id) ? (<div></div>) : (caseServiceErr)}
                                                        </div>
                                                        {/* <Grid container item xs={12}>
                                                {
                                                  categoryDetailsLastList.map((item, index) =>{
                                                  // console.log("item map is",item);
                                                    return(
                                                      <RadioGroup
                                                        row
                                                        aria-label="position"
                                                        name="position"
                                                        value={lastCategoryValue}
                                                        onChange={handleChangeLastCategory}
                                                      >
                                                        <div className={classes.customRadioButton}>
                                                          <FormControlLabel
                                                            key={index}
                                                            value={item.category_id}
                                                            classes={{ label: classes.label }}
                                                            control={<Radio size="small" color="primary" />}
                                                            label={item.name}
                                                          />
                                                        </div>
                                                      </RadioGroup>
                                                    )
                                                  })
                                                }
                                                </Grid> */}
                                                      </Grid>
                                                    ) :
                                                    (<div></div>)
                                                }
                                              </Grid>

                                              <Grid item xs={4} style={{ marginTop: '2%' }}>
                                                <Grid className={classes.subHeadingLabelSelect}>
                                                  Assesment Year *
                                                </Grid>
                                                <Grid style={{ textAlign: "left", marginLeft: '2%' }}>
                                                  {/* <DatePicker
                                            // Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) 
                                              // selected={newCaseDetails.assessment_year}
                                              selected={ Date.parse(moment(newCaseDetails.assessment_year, 'YYYY').toISOString()) }
                                              // onChange={(date) => setnewCaseDetails({...newCaseDetails, assessment_year: date.getFullYear() }) }
                                              // onChange={(date) => console.log("date.getFullYear()",JSON.stringify(date.getFullYear())) }
                                              onChange={(date) => handleChangeAssesmentYear(date) }
                                              placeholderText="Select Year"
                                              showYearPicker
                                              dateFormat="yyyy"
                                              yearItemNumber={8}
                                              minDate={minDate}
                                              maxDate={dueDate}
                                              // dateFormat={"yyyy"}
                                              className={classes.datePickerStyle}
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                              // containerstyle={{height:'50%'}}
                                            /> */}
                                                  <FormControl variant="outlined" className={classes.formControl} style={{ marginLeft: '1%' }}>
                                                    <Select
                                                      native
                                                      labelId="demo-simple-select-outlined-label"
                                                      id="demo-simple-select-outlined"
                                                      value={newCaseDetails.assessment_year}
                                                      onChange={(e) => setnewCaseDetails({ ...newCaseDetails, assessment_year: e.target.value })}
                                                    >
                                                      <option value="" style={{ color: 'grey' }}>Select Assesment Year</option>
                                                      {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                                        <option value={item.value}>{item.item}</option>
                                                      )}
                                                      {/* <option value="AY-2014-15" >AY-2014-15</option>
                                                <option value="AY-2015-16" >AY-2015-16</option>
                                                <option value="AY-2016-17" >AY-2016-17</option>
                                                <option value="AY-2017-18" >AY-2017-18</option>
                                                <option value="AY-2018-19" >AY-2018-19</option>
                                                <option value="AY-2019-20" >AY-2019-20</option>
                                                <option value="AY-2020-21" >AY-2020-21</option>
                                                <option value="AY-2021-22" >AY-2021-22</option>
                                                <option value="AY-2022-23" >AY-2022-23</option>
                                                <option value="AY-2023-24" >AY-2023-24</option>
                                                <option value="AY-2024-25" >AY-2024-25</option>
                                                <option value="N/A" >N/A</option> */}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                              <div className={classes.validation} style={{ marginLeft: '1.5%' }}>{(newCaseDetails.assessment_year) ? (<div></div>) : (caseAssesYearErr)}</div>

                                              <Grid alignItems={'center'} style={{ marginTop: '2%' }}>
                                                <Button
                                                  type="submit"
                                                  //   fullWidth
                                                  variant="contained"
                                                  color="primary"
                                                  className={classes.submit}
                                                  onClick={handelSubmitCreateCase}
                                                >
                                                  Create Case
                                                </Button>

                                                <Button
                                                  style={{ marginLeft: '1%' }}
                                                  type="submit"
                                                  //   fullWidth
                                                  variant="contained"
                                                  color="primary"
                                                  className={classes.submit}
                                                  onClick={handelSubmitCreateCaseReset}
                                                >
                                                  Reset
                                                </Button>

                                                <Button
                                                  style={{ marginLeft: '1%' }}
                                                  type="submit"
                                                  //   fullWidth
                                                  variant="contained"
                                                  // color="primary"
                                                  className={classes.submit}
                                                  href="/cases"
                                                // onClick={handelSubmitCreateCase}
                                                >
                                                  Cancel
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          )
                                          : (<Grid></Grid>)
                                      }

                                    </Grid>
                                  ) :
                                  (<div></div>)
                              }

                              {/* For bulk case creation */}
                              {
                                (newCaseForBulkCreation === true) ?
                                  (
                                    <Grid item xs={12}>
                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          autoFocus
                                          required
                                          label="Case Name"
                                          id="case_name"
                                          name="case_name"
                                          value={newCaseDetailsBulk.case_name}
                                          onChange={(e) => setnewCaseDetailsBulk({ ...newCaseDetailsBulk, case_name: e.target.value })}
                                        />
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetailsBulk.case_name) ? (<div></div>) : (caseNameErr)}</div>

                                      <Grid item xs={12} style={{ marginTop: '3%' }}>
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          required
                                          label="Case Description"
                                          id="case_desc"
                                          name="case_desc"
                                          value={newCaseDetailsBulk.case_desc}
                                          onChange={(e) => setnewCaseDetailsBulk({ ...newCaseDetailsBulk, case_desc: e.target.value })}
                                        />
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetailsBulk.case_desc) ? (<div></div>) : (caseDescriptionErr)}</div>

                                      <Grid container item xs={12}>
                                        <Grid item xs={4} style={{ marginTop: '2%' }}>
                                          <Grid className={classes.subHeadingLabelSelect} >
                                            Super Category *
                                          </Grid>
                                          <Grid>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                              <Select
                                                native
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={superCategoryValue}
                                                // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                onChange={handleChangeSuperAcategoryForBulkCreation}
                                              >
                                                <option value="" style={{ color: 'grey' }}>Select Super Category</option>
                                                {superCategoryList && superCategoryList.map(item =>
                                                  <option value={item.category_id}>{item.name}</option>
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                            {
                                              (caseServiceErr) ?
                                                (
                                                  <Grid>
                                                    {
                                                      (superCategoryValue) ? (<div></div>) : (
                                                        <div>Please select super category </div>
                                                      )
                                                    }
                                                  </Grid>
                                                ) :
                                                (
                                                  <Grid></Grid>
                                                )
                                            }
                                          </div>
                                        </Grid>

                                        {/* second sub category options */}
                                        {
                                          (SubCtegoryFetched == true) ?
                                            (
                                              <Grid item xs={4} style={{ marginTop: '2%' }} >
                                                <Grid className={classes.subHeadingLabelSelect}>
                                                  Category or Service *
                                                </Grid>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={subCategoryValue}
                                                    // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                    onChange={(e) => handleChangeSubcategoryBulkCreation(e)}
                                                  >
                                                    <option value="" style={{ color: 'grey' }}>Select Category or Service</option>
                                                    {subCategoryList && subCategoryList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>

                                                <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                  {
                                                    (caseServiceErr) ?
                                                      (
                                                        <Grid>
                                                          {
                                                            (subCategoryValue) ? (<div></div>) : (
                                                              <div>Please select category or service </div>
                                                            )
                                                          }
                                                        </Grid>
                                                      ) :
                                                      (
                                                        <Grid></Grid>
                                                      )
                                                  }
                                                </div>
                                              </Grid>
                                            ) :
                                            (<div></div>)
                                        }

                                        {/* Third if service navigate else if category then show options */}
                                        {
                                          (SelectedCategoryIsCategory == true) ?
                                            (
                                              <Grid item xs={4} style={{ marginTop: '2%' }}>
                                                <Grid className={classes.subHeadingLabelSelect}>
                                                  {/* Case Category */}
                                                  Service *
                                                </Grid>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={lastCategoryValue}
                                                    // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                                    onChange={(e) => handleChangeLastCategoryBulkCration(e)}
                                                  >
                                                    <option value="" style={{ color: 'grey' }}>Select Service</option>
                                                    {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                <div className={classes.validation} style={{ marginLeft: '1.5%' }}>
                                                  {(newCaseDetailsBulk.service_id) ? (<div></div>) : (caseServiceErr)}
                                                </div>
                                              </Grid>

                                            ) :
                                            (<div></div>)
                                        }
                                      </Grid>

                                      <Grid item xs={4} style={{ marginTop: '2%' }}>
                                        <Grid className={classes.subHeadingLabelSelect}>
                                          Assesment Year *
                                        </Grid>
                                        <Grid style={{ textAlign: "left", marginLeft: '2%' }}>

                                          <FormControl variant="outlined" className={classes.formControl} style={{ marginLeft: '1%' }} >
                                            <Select
                                              native
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={newCaseDetailsBulk.assessment_year}
                                              onChange={(e) => setnewCaseDetailsBulk({ ...newCaseDetailsBulk, assessment_year: e.target.value })}
                                            >
                                              <option value="" style={{ color: 'grey' }}>Select Assesment Year</option>
                                              {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                                <option value={item.value}>{item.item}</option>
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <div className={classes.validation}>{(newCaseDetailsBulk.assessment_year) ? (<div></div>) : (caseAssesYearErr)}</div>

                                      <Grid alignItems={'center'} style={{ marginTop: '2%' }}>
                                        <Button
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          color="primary"
                                          className={classes.submit}
                                          onClick={handelSubmitCreateBulkCases}
                                        >
                                          Create Case
                                        </Button>

                                        <Button
                                          style={{ marginLeft: '1%' }}
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          color="primary"
                                          className={classes.submit}
                                          onClick={handelSubmitCreateCaseReset}
                                        >
                                          Reset
                                        </Button>

                                        <Button
                                          style={{ marginLeft: '1%' }}
                                          type="submit"
                                          //   fullWidth
                                          variant="contained"
                                          // color="primary"
                                          className={classes.submit}
                                          href="/cases"
                                        // onClick={handelSubmitCreateCase}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>

                                    </Grid>
                                  ) :
                                  (<div></div>)
                              }


                            </Grid>
                          </Grid>
                        ) :
                        (
                          <Grid></Grid>
                        )
                    }



                  </div>
                )
            }

          </div>
        </Slide>
      </div>
    );
  }
}

export default injectIntl(TaxManagementNewCase);
