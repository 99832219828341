import React, {useEffect}from "react";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NewClientGeneralDetails from "../NewClientGeneralDetails";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { Link, Redirect} from 'react-router-dom';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';

import AddBulkClientModal from  '../NewClientGeneralDetails/AddBulkClientModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    // marginBottom: "2%",
    textAlign: "right",
  },
  linkToJsonOrZip:{
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    // marginBottom: "2%",
    // textAlign: "left",
    marginTop:"2%",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  linkOfJsonAndZip: {
    color: "blue",
    cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3), 
  },
  validationMaxTopMargin: {
    color: 'red',
    fontSize: 12,
    marginTop: '2%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));

function TaxManagementNewClient({ intl, props }) {
  const classes = useStyles();
  const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [flag, setflag] = React.useState(false);
  const [Open, setOpen] = React.useState(false);
  const [JsonUpload, setJsonUpload] = React.useState(false);
  const [ZipUpload, setZipUpload] = React.useState(false);
  const [DocumentName, setDocumentName] = React.useState();
  const [DocumentFile, setDocumentFile] = React.useState();
  const [FileErr, setFileErr] = React.useState("");
  const [RedirectToBulkDashboard, setRedirectToBulkDashboard] = React.useState(false);
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  useEffect(() => {
    // readItemFromStorage();
    dispatch(userActions.checkSessionValidity());
    localStorage.removeItem('view_clients_active_cases');
    localStorage.removeItem('createNewCaseForSelectedClient');
  }, [flag]);

 
  // Wrote this for get component directly
  // const uploadFileOpen = (jsonorzip) =>{
  //   console.log("uploadFileOpen clicked",jsonorzip)
  //   setopen(true)
  //   if(jsonorzip == "JSON"){
  //     setJsonUpload(true)
  //   }
  //   else if(jsonorzip == "ZIP"){
  //     setJsonUpload(false)
  //   }
    
  //   return(
  //     // <Grid>
  //       <AddBulkClientModal open={open} JsonUpload={JsonUpload}/>
  //     // </Grid>
  //   )
  // }

  const uploadFileOpen = (jsonorzip) => {
    console.log("uploadFileOpen clicked",jsonorzip)
    if ( jsonorzip === "JSON" ){
      setJsonUpload(true);
      setOpen(true);
    }
    if ( jsonorzip === "ZIP" ){
      setZipUpload(true);
      setOpen(true);
    }
  }

  const uploadFileClose = () => {
    setOpen(false);
    setJsonUpload(false);
    setZipUpload(false);
    setDocumentName();
    setDocumentFile();
    setFileErr("")
  }

  const onUploadFile = (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    console.log("event.target.files[0] name",event.target.files[0].name)
    console.log("uri",event.target.files.uri)
    setDocumentName(event.target.files[0].name);
    setDocumentFile(event.target.files[0])
  }

  const validate = () => {
    let FileErr = '';

    if( !DocumentFile ){
        FileErr = "Please Select File To Upload";
    }

    if( FileErr ){
        setFileErr(FileErr);

        return false;
      }
      return true;
  }

  const handelSubmitUploadFile = () => {
    const isValid = validate();
    if(isValid){
        // console.log('in handelSubmitUploadFile');
        // setOpen(false);
        // setJsonUpload(false);
        // setZipUpload(false);
        // setFileErr("")

            setOpen(false);
            console.log('in handelSubmitUploadFile',DocumentFile);
            setShowProcedure(true)
            
            // set this after response id true
            // setJsonUpload(false);
            // setZipUpload(false);
            // setFileErr("")

            const requestOptionsSecond = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                  'doc_type': 'client_zip', 'doc_name': DocumentFile.name, 
                  // 'doc_extra_info': {'business_id': business_id},
                  'doc_extra_info': '','business_id': business_id,
                  'device_token': device_token, 'session_id': session_id
                })
            }
            return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
            .then((Response) => Response.json())
            .then(checkResponseOfS3Upload => {
                console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
                if (checkResponseOfS3Upload.success === true) {
                    // console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                    console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

                    const formData = new FormData();
                    Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
                    formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
                    });
                    formData.append("file", DocumentFile);

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
                    xhr.send(formData);

                    xhr.onload = function () {
                        if (this.status === 204) {
                            console.log("-----SUCCESSFULLY UPLOADED");

                            // console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                            console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

                            let user = JSON.parse(localStorage.getItem('user'));
                            // let formdata = new FormData();
                            // formdata.append('zip_file', checkResponseOfS3Upload.data.public_url.url);
                            // formdata.append('business_id', business_id);
                            // formdata.append('device_token', device_token);
                            // formdata.append('session_id', session_id);
                            const requestOptions = {
                                method: 'POST',
                                headers: {'Authorization': 'Token ' + user.key},
                                // headers: authHeader(),
                                // body: formdata,
                                body: JSON.stringify({
                                    // 'zip_file': checkResponseOfS3Upload.data.public_url.url, 
                                    'zip_file': checkResponseOfS3Upload.data.upload_info.url, 
                                    'business_id': business_id,
                                    // 'file_key': checkResponseOfS3Upload.data.public_url.key,
                                    'file_key': checkResponseOfS3Upload.data.upload_info.fields.key,
                                    "uuid": checkResponseOfS3Upload.data.upload_info.uuid,
                                    'device_token': device_token, 'session_id': session_id
                                })
                            };
                            return fetch( process.env.REACT_APP_CLIENT_ZIP_FILES , requestOptions)
                            .then((Response) => Response.json())
                            .then( IdProofSaved => {
                                setShowProcedure(false);
                                console.log("IdProofSaved ",IdProofSaved.success);
                                console.log("IdProofSaved ",IdProofSaved);
                                if(IdProofSaved.success == true){
                                    Swal.fire({
                                        icon: 'success',
                                        text: "Document Uploaded Succesfully",
                                        confirmButtonColor: 'primary',
                                        confirmButtonText: 'OK',
                                        timer: 5000,
                                    })
                                    setJsonUpload(false);
                                    setZipUpload(false);
                                    setFileErr("");
                                    // setflag(false);
                                    setRedirectToBulkDashboard(true)
                                    
                                }
                                if(IdProofSaved.success == false){
                                    setShowProcedure(false);
                                    setOpen(true);
                                    Swal.fire({
                                      icon: 'error',
                                      text: IdProofSaved.errors,
                                      confirmButtonColor: '#d33',
                                      confirmButtonText: 'OK',
                                      timer: 5000,
                                    })
                                }  
                            })
                            .catch(err => {
                                setShowProcedure(false);
                                Swal.fire({
                                  icon: 'error',
                                  // text: "Something went wrong",
                                  text: "Server Error. Please try again.",
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK'
                                })
                            });

                        }
                        else {
                            console.log("---------FAILED UPLOAD");
                            setShowProcedure(false);
                            Swal.fire({
                              icon: 'error',
                              // text: "Something went wrong",
                              text: "Upload failed to s3",
                              confirmButtonColor: '#d33',
                              confirmButtonText: 'OK'
                            })
                        }
                    }

                }
                else {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error (s3). Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                }
            })
            .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error (s3). Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
            })
   }
  }
  
  
  const body = (
    <div className={classes.paperModal}>
        {console.log("in body ")}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
            <Grid  item xs={11}>
                {
                    ( JsonUpload == true )?
                    (
                        <h2 style={{marginLeft:'38%'}}>UPLOAD JSON</h2>
                    ):
                    (
                        ( ZipUpload == true )?
                        (
                            <h2 style={{marginLeft:'40%'}}>UPLOAD ZIP</h2>
                        ):
                        (
                            <Grid>Upload JSON</Grid>
                        )
                    )
                }
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
                <CloseIcon onClick={()=>uploadFileClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
        </Grid>
            
        </div>
        <div className={classes.modalBody}>
            <div style={{padding: 10     }}>
                <input type='file' 
                    accept={( JsonUpload == true ? ".json" : ".zip")}
                    id="myBulk"
                    onChange={ (e) => onUploadFile(e)} 
                />
                <div className={classes.validationMaxTopMargin}>{(DocumentFile)?(<div></div>):(FileErr)}</div>
            </div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handelSubmitUploadFile}
                >
                    UPLOAD
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={uploadFileClose}
                >
                    Cancel
                </Button>
            </div>
        </div> 
    </div>
  )
  
  if (RedirectToBulkDashboard == true){
    return <Redirect to={`/clientBulkDashboard`} />
  }
  else{
  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
            </modal>: null}
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.wrapper}>
          <Grid container className={classes.pageHeaderContainer}>
            <Grid item xs={6} className={classes.pageHeaderLabel}>
              {/* <FormattedMessage id="newClientLink" /> */}
              Create New Client
            </Grid>
            {user_permissions && user_permissions.permissions.view_clients &&
              <Grid item xs={6} className={classes.linkBackToCase}>
                <Link to="/client" className={classes.linkNoUnderline}>
                  {/* <FormattedMessage id="navigateToClient" /> */}
                  View List of Clients
                </Link>
              </Grid>
            }
          </Grid>

          {/* <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/client" className={classes.linkNoUnderline}> */}
                {/* <FormattedMessage id="navigateToClient" /> */}
                {/* View List of Clients */}
              {/* </Link>
            </Grid>
          </Grid> */}

          <Typography className={classes.instructions}>
            <NewClientGeneralDetails />
          </Typography>
          

          {/* <Grid container item xs={12} className={classes.linkToJsonOrZip}>
            Create Client Using &nbsp;
            <Grid onClick={() => uploadFileOpen('JSON')} className={classes.linkOfJsonAndZip}>
              JSON &nbsp;
            </Grid>
            or &nbsp;
            <Grid onClick={() => uploadFileOpen('ZIP')} className={classes.linkOfJsonAndZip}>
              ZIP
            </Grid>
          </Grid> */}

        </div>
        
      </Slide>
      
      <Modal
        open={Open}
        onClose={uploadFileClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
  }
}

export default injectIntl(TaxManagementNewClient);
