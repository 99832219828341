import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import { Link , Route, Redirect} from 'react-router-dom';
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },

  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },

}));

export default function TaxManagementNoticeBoard(props) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [Flag, setFlag] = React.useState(false);
  const [FlagTwo, setFlagTwo] = React.useState(false);
  const [BusinessClientGroupDetails, setBusinessClientGroupDetails] = React.useState([]);
  const [BusinessClientIndividualClientDetails, setBusinessClientIndividualClientDetails] = React.useState([]);
  const [BusinessClientIndividualClientDetailsSearch, setBusinessClientIndividualClientDetailsSearch] = React.useState([]);
  const [createNotice, setcreateNotice] = React.useState({
    notice: "",
    subject: "",
    groups: [],
    clients: [],
    on_click: "",
    is_archived: false,
    notice_id: "",
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [GroupsOrIndividuals, setGroupsOrIndividuals] = React.useState("");
  const [CheckGroups, setCheckGroups] = React.useState(true);
  const [CheckIndividuals, setCheckIndividuals] = React.useState(true);
  const[showMultiselect, setshowMultiselect] = React.useState(true);
  const[NoticeErr, setNoticeErr] = React.useState("");
  const[subjectErr, setsubjectErr] = React.useState("");
  const[GroupsErr, setGroupsErr] = React.useState("");
  const[clientNoticeDetails, setclientNoticeDetails] = React.useState([]);

  // redux
  const dispatch = useDispatch();
  
  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  console.log("user_permissions from local storage on notices::::::",user_permissions)


  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    localStorage.removeItem('createNewCaseForSelectedClient');
    
    setShowProcedure(true);
    setFlagTwo(true);
    // setcreateNotice({
    //   notice: "",
    // subject: "",
    //   groups: [],
    //   on_click: "https://example.com",
    //   is_archived: "",
    //   notice_id: "",
    //   business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })

    // to get all business groups for admin
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    // console.log("requestOptions for client groups",requestOptions);
    fetch( process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS , requestOptions)
      .then((Response) => Response.json())
      .then( async(businessClientGroupDetails) => {
        setShowProcedure(false);
        console.log("businessClientGroupDetails from api",businessClientGroupDetails);
        if(businessClientGroupDetails.success == true)
        {

          // setBusinessClientGroupDetails(businessClientGroupDetails.data.client_groups);
          let BusinessClientGroupDetailsHere = [];
          businessClientGroupDetails.data.client_groups.map((e,i)=>{
            console.log("element in businessClientGroupDetails map is",e)
            if(e.is_active === true){
              BusinessClientGroupDetailsHere.push(e);
            }
          })
          console.log("BusinessClientGroupDetailsHere is",BusinessClientGroupDetailsHere)

          setBusinessClientGroupDetails(BusinessClientGroupDetailsHere)
        }
        else if(businessClientGroupDetails.success == false){
            setBusinessClientGroupDetails([]);
          Swal.fire({
            icon: 'error',
            text: businessClientGroupDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to get all client details
    const requestOptionsClients = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
          'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CLIENTS , requestOptionsClients)
        .then((Response) => Response.json())
        .then( clientDetails => {
            console.log("clientDetails on same page",clientDetails.data.clients);
            if(clientDetails.data.clients)
            {
                setBusinessClientIndividualClientDetails(clientDetails.data.clients)
            }
            if(clientDetails.success == false){
              setBusinessClientIndividualClientDetails([])
                Swal.fire({
                icon: 'error',
                text: clientDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });


    // let users = JSON.parse(localStorage.getItem('users'))
    // console.log("users in useEffect",users)
    // to get pertcular clients notice for client
    const requestOptionsClientNotices = {
      method: 'POST',
      headers: authHeader(),
       body: JSON.stringify({ 'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
    };
    fetch( process.env.REACT_APP_GET_NOTICES , requestOptionsClientNotices)
      .then((Response) => Response.json())
      .then( async(clientNoticeDetails) => {
        setShowProcedure(false);
        console.log("clientNoticeDetails from api",clientNoticeDetails);
        if(clientNoticeDetails.success == true)
        {
          setclientNoticeDetails(clientNoticeDetails.data.notices_info.notices);
        }
        else if(clientNoticeDetails.success == false){
            setclientNoticeDetails([]);
          Swal.fire({
            icon: 'error',
            text: clientNoticeDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });


  }, [FlagTwo]);

  useEffect(() => {
    setFlag(true);
    // setcreateNotice({
    //   notice: "",
    // subject: "",
    //   groups: [],
    //   on_click: "https://example.com",
    //   is_archived: "",
    //   notice_id: "",
    //   business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })
  }, [Flag]);

  const validate = () => {
    let NoticeErr = "";
    let subjectErr = "";
    let GroupsErr = "";

    if( !createNotice.notice){
      NoticeErr = "Please Enter Notice"
    }
    if( !createNotice.subject){
      subjectErr = "Please Enter Subject"
    }
    if( createNotice.groups.length == 0 && createNotice.clients.length == 0 ){
      console.log("in validate grp indi",createNotice.groups.length, createNotice.clients.length )
      GroupsErr = "Please Select at least one grouop or client"
    }

    if(NoticeErr || GroupsErr || subjectErr ){
      setNoticeErr(NoticeErr);
      setsubjectErr(subjectErr);
      setGroupsErr(GroupsErr);

      return false;
  }

  return true;

  }

  const handleSubmitNotice = async() => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleSubmitNotice",createNotice)
    const isValid = validate();
    if(isValid){
        setShowProcedure(true);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(createNotice)
        };
        return fetch( process.env.REACT_APP_CREATE_NOTICE , requestOptions)
          .then((Response) => Response.json())
          .then( async(noticeSend) => {
            await setshowMultiselect(false);
            await setShowProcedure(false);
            console.log("noticeSend in api",noticeSend);
            if(noticeSend.success == true){
              // setBusinessClientGroupDetails(noticeSend.data.client_group_info)
              Swal.fire({
                icon: 'success',
                text: "Notice Sent Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              await setcreateNotice({
                notice: "",
                subject: "",
                groups: [],
                clients: [],
                on_click: "",
                is_archived: false,
                notice_id: "",
                business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')),
              });
              await setshowMultiselect(true);
              setNoticeErr("");
              setsubjectErr("");
              setGroupsErr("");
              setFlag(false);
              setFlagTwo(true);
              // setBusinessClientGroupDetails([]);
            }
            else if(noticeSend.success == false){
              await setcreateNotice({
                ...createNotice,
                groups: [],
                clients: [],
              });
              await setshowMultiselect(true);
              
              Swal.fire({
                  icon: 'error',
                  text: noticeSend.error,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
              })
            }
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
    }
  }

  const handleResetNotice = async() => {
    dispatch(userActions.checkSessionValidity());
    await setshowMultiselect(false);
    await setcreateNotice({
      notice: "",
      subject: "",
      groups: [],
      clients: [],
      on_click: "",
      is_archived: false,
      notice_id: "",
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setNoticeErr("");
    setsubjectErr("");
    setGroupsErr("");
    setBusinessClientGroupDetails([]);
    setFlag(false);
    setFlagTwo(false);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    await setshowMultiselect(true)
  }

  // const groupsOrIndividualsFunc = async(e) => {
  //   console.log("in groupsOrIndividualsFunc",e.target.value)
  //   let valueHere = e.target.value
  //   await setGroupsOrIndividuals(valueHere)
  //   if( valueHere == "Individuals" ){
  //     await setcreateNotice({...createNotice, groups: [] })
  //   }
  //   if( valueHere == "groups" ){
  //     // await setcreateNotice({...createNotice, individuals: [] })
  //     console.log("in groups",valueHere)
  //   }
  // }

  const groupsOrIndividualsFunc = async(e, grpOrIndividual) => {
    console.log("in groupsOrIndividualsFunc",e.target.checked,grpOrIndividual)
    if( grpOrIndividual == "groups"){
      setCheckGroups(e.target.checked)
    }
    if( grpOrIndividual == "individuals"){
      setCheckIndividuals(e.target.checked)
    }
  }

  const handleGroupSelect = (event,value) => {
    console.log("handle GroupSelect ============",event.target.value,value)
    let valueHere = []
    value.map((item, index)=>{
      let a = valueHere
      a.push(item.client_group_id)
      valueHere = a
    })
    // let valueHere = value.client_group_id
    // let grpsHere = createNotice.groups
    // grpsHere.push(valueHere)
    console.log("valueHere, grpsHere",valueHere)
    setcreateNotice({...createNotice, groups: valueHere })
  }

  const handleIndividualSelect =(event,value) => {
    console.log("handle individual select ============",event.target.value,value)
    let valueHere = []
    value.map((item, index)=>{
      let a = valueHere
      // a.push(item.client_id)
      a.push(item.user.user_id)
      valueHere = a
    })
    console.log("valueHere, grpsHere",valueHere)
    const filteredArr = Array.from(new Set(valueHere));
    console.log("filteredArr",filteredArr);
    // setcreateNotice({...createNotice, clients: valueHere })
    setcreateNotice({...createNotice, clients: filteredArr })
  }

  const handleSearch = async(e) => {
    console.log("ins handleSearch", e.target.value)
    let search_string = e.target.value;

    if( search_string !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'search_string': search_string, 'client_type': "REGISTERED",
        'device_token': device_token, 'session_id': session_id, 'business_id': business_id  })
      };
      fetch( process.env.REACT_APP_SEARCH_CLIENTS , requestOptions)
        .then((Response) => Response.json())
        .then( async(clientDetails) => {
          console.log("clientDetails in filter",clientDetails.data.clients);
          if(clientDetails.data.clients)
          {

            // to get only regostered clients updated date(25 april)
            console.log("clientDetails.data.clients",clientDetails.data.clients)
            // let registeredClientsArrayIs = []
            // clientDetails.data.clients.map((item,index)=>{
            //   console.log("item.user is map",item.user, item.user.user_id)
            //   if( item.user.user_id ){
            //     registeredClientsArrayIs = registeredClientsArrayIs.concat(item)
            //   }
            // })
            // console.log("registeredClientsArrayIs after map", registeredClientsArrayIs)
            // await setBusinessClientIndividualClientDetailsSearch(registeredClientsArrayIs)
            await  setBusinessClientIndividualClientDetailsSearch(
              clientDetails.data.clients.map((f) => {
                // for (let index = 0; index < BusinessClientIndividualClientDetailsSearch.length; index++) {
                    f.client_name = f.client_name + "(" + f.email + ")";
                //   break;
                // }
                return f ;
              })
            )

            // setBusinessClientIndividualClientDetailsSearch(clientDetails.data.clients)
            
          }
          if(clientDetails.success == false){
            setBusinessClientIndividualClientDetailsSearch([])
            Swal.fire({
              icon: 'error',
              text: clientDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user",role_of_user)

  if(role_of_user == "client"){
    return (
      console.log("clientNoticeDetails, createNotice",clientNoticeDetails, createNotice),
      <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
          <div className={classes.wrapper}>
            <Grid container className={classes.pageHeaderContainer}>
              <Grid item xs={12} className={classes.pageHeaderLabel}>
                Notice Board
              </Grid>
            </Grid>
            <Paper className={classes.paper} style={{marginTop:'-2%'}}>
              <Grid style={{color:'black', fontSize:'18px'}}>
                Notices
              </Grid>
              <Grid>
                {
                  clientNoticeDetails && clientNoticeDetails.map((item,index)=>(
                    <Grid item xs={12} style={{marginTop:'2%', padding:10, backgroundColor: '#ebe2ca'}}>
                      <Grid container style={{color:'black', fontSize:'15px', marginLeft:'1%' }}>
                        {/* {index + 1}. &nbsp;{item.notice} */}
                        <Grid container item xs={9}>
                          <Grid style={{fontWeight:'bold'}}>
                            Subject: 
                          </Grid>
                          <Grid style={{marginLeft:'2%'}}>
                            {item.subject} 
                          </Grid>
                        </Grid>

                        <Grid container item xs={3}>
                          <Grid style={{fontWeight:'bold'}}>
                            Date: 
                          </Grid>
                          <Grid style={{marginLeft:'3.8%'}}>
                            {item.created} 
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container style={{color:'black', fontSize:'15px', marginLeft:'1%', marginTop:'1%' }}>
                        <Grid style={{fontWeight:'bold'}}>
                          Notice: 
                        </Grid>
                        <Grid style={{marginLeft:'2.8%'}}>
                          {item.notice} 
                        </Grid>
                      </Grid>

                    </Grid>
                  ))
                }
              </Grid>
            </Paper>
          </div>
      </div>
    )
  } 
  // else if(role_of_user == "trpadmin"){
  else{
  return (
    console.log("BusinessClientGroupDetails,createNotice",BusinessClientGroupDetails,createNotice),
    console.log("GroupsOrIndividuals",GroupsOrIndividuals),
    console.log("BusinessClientIndividualClientDetailsSearch",BusinessClientIndividualClientDetailsSearch),
    // console.log("BusinessClientIndividualClientDetails",BusinessClientIndividualClientDetails),
    <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <div className={classes.wrapper}>
            <Grid container className={classes.pageHeaderContainer}>
                <Grid item xs={10} className={classes.pageHeaderLabel}>
                  {/* Send Notice */}
                  Notice
                </Grid>
                <Grid item xs={2} >
                  <Link 
                    to="NoticeHistory" 
                    style={{ textDecoration: 'none', marginLeft: '15%', fontWeight:'bold', fontSize:'18px' }}
                  >
                    Notice History
                  </Link>
                </Grid>
            </Grid>
            <Paper className={classes.paper} style={{marginTop:'-2%'}}>
              <Grid style={{color:'black', fontSize:'15px'}}>
                Subject
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="subject"
                // label="subject"
                size="small"
                placeholder="subject"
                autoFocus
                name="subject"
                value={createNotice.subject}
                onChange={(e) => setcreateNotice({...createNotice, subject:e.target.value }) }
              />
              <div className={classes.validation}>{(createNotice.subject)?(<div></div>):(subjectErr)}</div>

              <Grid style={{color:'black', fontSize:'15px', marginTop:'2%'}}>
                Notice Content
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                multiline
                rows={4}
                maxRows={4}
                id="notice"
                // label="notice"
                size="small"
                placeholder="Notice Content"
                // autoFocus
                name="notice"
                value={createNotice.notice}
                onChange={(e) => setcreateNotice({...createNotice, notice:e.target.value }) }
              />
              <div className={classes.validation}>{(createNotice.notice)?(<div></div>):(NoticeErr)}</div>

              <Grid style={{color:'black', fontSize:'15px', marginTop:'2%', marginBottom:'1%'}}>
                Send Notice To:
              </Grid>
              <Grid container item xs={12} spacing={2}>
                {user_permissions && user_permissions.permissions.send_broadcast_to_groups &&
                <Grid item xs={6} >
                  <Grid style={{color:'black', fontSize:'15px', marginTop:'2%', marginBottom:'1%'}}>
                    Groups:
                  </Grid>
                  <Grid style={{marginTop:'2%'}}>
                    {
                      ( showMultiselect == true )? 
                      (
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={BusinessClientGroupDetails}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => handleGroupSelect(event,value)}
                          defaultValue={createNotice.groups}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Groups"
                              placeholder="Groups"
                            />
                          )}
                        />
                      ):
                      (<Grid></Grid>)
                    }
                  </Grid>
                </Grid>
                }

                {user_permissions && user_permissions.permissions.send_notice_to_clients &&
                <Grid item xs={6}>
                  <Grid style={{color:'black', fontSize:'15px', marginTop:'2%', marginBottom:'1%'}}>
                    Clients:
                  </Grid>
                  <Grid style={{marginTop:'2%'}}>
                  {
                      ( showMultiselect == true )? 
                      (
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={BusinessClientIndividualClientDetailsSearch}
                          // options={BusinessClientIndividualClientDetails}
                          getOptionLabel={(option) => option.client_name}
                          onChange={(event, value) => handleIndividualSelect(event,value)}
                          defaultValue={createNotice.clients}
                          // value={createNotice}
                          valueField='client_id'
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search and Select Clients"
                              placeholder="Clients"
                              onChange={handleSearch}
                            />
                          )}
                        />
                      ):
                      (<Grid></Grid>)
                    }
                  </Grid>
                </Grid>
                }

                {/* <div className={classes.validation} style={{marginLeft:'1.5%'}}>{(createNotice.groups > 0 || createNotice.clients > 0)?(<div></div>):(GroupsErr)}</div> */}
                <div className={classes.validation} style={{marginLeft:'1.5%'}}>{GroupsErr}</div>
              </Grid>
              {/* <Grid container item xs={12} style={{marginTop:"1%"}}> */}
                      {/* <RadioGroup
                        row
                        id ="grp_individual"
                        aria-label="grps_individual"
                        name="grp_individual"
                        defaultValue={GroupsOrIndividuals}
                        // onChange={ (e) => setGroupsOrIndividuals(e.target.value)}
                        onChange={ (e) => groupsOrIndividualsFunc(e)}
                      >                  
                        <div>
                          <FormControlLabel
                            value="groups"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Groups"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            value="Individuals"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Individuals"
                          />
                        </div>
                      </RadioGroup> */}
                      {/* <FormControlLabel
                        control={
                          <Checkbox 
                            checked={CheckGroups} 
                            onChange={(e) => groupsOrIndividualsFunc(e, "groups")} 
                            name="Groups" 
                            color="primary"
                          />
                        }
                        label="Groups"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={CheckIndividuals} 
                            onChange={(e) => groupsOrIndividualsFunc(e, "individuals")} 
                            name="Individuals" 
                            color="primary"
                          />
                        }
                        label="Individuals"
                      /> */}
              {/* </Grid> */}

              {/* {
                ( GroupsOrIndividuals == "groups" ) ?
                (
                  <Grid>
                    {
                      ( showMultiselect == true )? 
                      (
                        <Grid>
                          <Grid style={{color:'black', fontSize:'15px', marginTop:'2%', marginBottom:'1%'}}>
                            Select Groups
                          </Grid>
                          <Multiselect
                            data={BusinessClientGroupDetails}
                            defaultValue={createNotice.groups}
                            placeholder="Select Client Groups"
                            valueField='client_group_id'
                            textField='name'
                            // onSelect={(valueField)=> console.log(valueField)}
                            
                            onChange={ async(event)=>{
                                console.log("event of multiselect",event);
                                var joined=[];
                                console.log("length : "+event.length);
                                if(event.length === 0)
                                {
                                  await setcreateNotice({...createNotice, groups: [] })
                                }
                                event.map(async(data)=>{
                                  console.log("data in eve map : "+JSON.stringify(data));
                                  joined = joined.concat(data.client_group_id);
                                  console.log("data join : ",joined);
                                  await setcreateNotice({...createNotice, groups: joined })
                                  console.log("check this createNotice",createNotice)
                                })
                            }}
                          />
                          <div className={classes.validation}>{(createNotice.groups > 0)?(<div></div>):(GroupsErr)}</div>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  </Grid>
                )
                :(<Grid></Grid>)
              } */}

              {/* {
                ( GroupsOrIndividuals == "Individuals" ) ?
                (
                  <Grid>
                    {
                      ( showMultiselect == true )? 
                      (
                        <Grid>
                          <Grid style={{color:'black', fontSize:'15px', marginTop:'2%', marginBottom:'1%'}}>
                            Select Clients
                          </Grid>
                          <Multiselect
                            data={BusinessClientGroupDetails}
                            defaultValue={createNotice.groups}
                            placeholder="Select Clients"
                            valueField='client_group_id'
                            textField='name'
                            // onSelect={(valueField)=> console.log(valueField)}
                            
                            onChange={ async(event)=>{
                                console.log("event of multiselect",event);
                                var joined=[];
                                console.log("length : "+event.length);
                                if(event.length === 0)
                                {
                                  await setcreateNotice({...createNotice, groups: [] })
                                }
                                event.map(async(data)=>{
                                  console.log("data in eve map : "+JSON.stringify(data));
                                  joined = joined.concat(data.client_group_id);
                                  console.log("data join : ",joined);
                                  await setcreateNotice({...createNotice, groups: joined })
                                  console.log("check this createNotice",createNotice)
                                })
                            }}
                          />
                          <div className={classes.validation}>{(createNotice.groups > 0)?(<div></div>):(GroupsErr)}</div>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  </Grid>
                )
                :(<Grid></Grid>)
              } */}
              
              

              <Grid style={{marginTop:'5%',textAlign: "center",marginBottom:'2%'}}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmitNotice}
                >
                  Send
                </Button>

                <Button
                  style={{marginLeft:'2%'}}
                  type="submit"
                  variant="contained"
                  // color="primary"
                  className={classes.submit}
                  onClick={handleResetNotice}
                >
                  Reset
                </Button>
              </Grid>
            </Paper>
        </div>
    </div>
  );
  }
}