import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { authHeader } from '../helpers';
import { userActions } from "../actions";
import { modal } from 'reactstrap';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// import useCheckSessionApi from "../SessionValidity";
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    topPosition: {
        top: "5px !important"
    },
    noUnderlineStyle: {
        textDecoration: "none"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
    },
    paperModalFeedback: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: 700,
        height: 600,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        overflowY:'scroll',
    },
    addNewBusinessHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#23c1f7',
        backgroundColor: 'white',
        height: '10%',
    },
    modalBody: {
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        // margin: theme.spacing(1),
        // marginTop: '4%',
        width: '100%',
    },
    validation: {
        color: 'red',
        fontSize: 12,
    },
    showProcedureStyle: {
        position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
      },

}));

export default function TakeFeedback({ users }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [ShowProcedure, setShowProcedure] = React.useState(false);
    const [flag, setflag] = React.useState(false);
    const [RedirectProfile, setRedirectProfile] = React.useState(false);

    const [OpenTakeFeedback, setOpenTakeFeedback] = React.useState(false);

    const [FeedbackSubject, setFeedbackSubject] = React.useState();
    const [FeedbackDescription, setFeedbackDescription] = React.useState();
    const [NewScreenShotName, setNewScreenShotName] = React.useState();
    const [NewScreenShotFile, setNewScreenShotFile] = React.useState();
    const [FeedbackSeverity, setFeedbackSeverity] = React.useState();

    const [FeedbackCategory, setFeedbackCategory] = React.useState("Yet to add");
    const [FeedbackStatus, setFeedbackStatus] = React.useState("OPEN");
    const [FeedbackPriority, setFeedbackPriority] = React.useState("LOW");

    const [FeedbackSubjectError, setFeedbackSubjectError] = React.useState();
    const [FeedbackDescriptionError, setFeedbackDescriptionError] = React.useState();
    // const [FeedbackFileError, setFeedbackFileError] = React.useState();
    const [FeedbackSeverityError, setFeedbackSeverityError] = React.useState();


    const [RedirectToShowFeedback, setRedirectToShowFeedback] = React.useState(false);

    useEffect(() => {
        setflag(true);

    }, [flag]);


    const dispatch = useDispatch()

    const handleFeedbackClick = () => {
        console.log("handleFeedbackClick")
        let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

        if(role_of_user === 'sysadmin'){
            setRedirectToShowFeedback(true);
        }
        else{
            setOpenTakeFeedback(true);
        }
        
    }

    const handelTakeFeedbackClose = () => {
        console.log("handelTakeFeedbackClose")
        setOpenTakeFeedback(false);

        setFeedbackSubject();
        setFeedbackDescription();
        setNewScreenShotFile();
        setFeedbackSeverity();
        setFeedbackSubjectError();
        setFeedbackDescriptionError();
        // setFeedbackFileError();
        setFeedbackSeverityError();

    }

    const onUploadScreenShot = (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setNewScreenShotName(event.target.files[0].name);
        setNewScreenShotFile(event.target.files[0])
    }

    const validate = () => {
        let FeedbackSubjectError = '';
        let FeedbackDescriptionError = '';
        // let FeedbackFileError = '';
        let FeedbackSeverityError = '';

        if (!FeedbackSubject) {
            FeedbackSubjectError = 'Please Enter Subject';
        }
        if (!FeedbackDescription) {
            FeedbackDescriptionError = 'Please Enter Description';
        }
        // if (!NewScreenShotFile) {
        //     FeedbackFileError = 'Please Select File';
        // }
        if (!FeedbackSeverity) {
            FeedbackSeverityError = 'Please Select Severity';
        }

        if (FeedbackSubjectError || FeedbackDescriptionError || FeedbackSeverityError) {
            setFeedbackSubjectError(FeedbackSubjectError);
            setFeedbackDescriptionError(FeedbackDescriptionError);
            // setFeedbackFileError(FeedbackFileError);
            setFeedbackSeverityError(FeedbackSeverityError);
      
            return false;
          }
          return true;

    }

    const handleUploadFeedback = () => {
        const isValid = validate();
        if (isValid) {
            setShowProcedure(true);
            console.log("after submit : ",FeedbackSubject,FeedbackDescription,NewScreenShotName,
                NewScreenShotFile,FeedbackSeverity
            )

            const requestOptionsFileUploadAws = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                  'doc_type': 'case_document', 'doc_name': NewScreenShotName, 'doc_extra_info': '',
                  'device_token': device_token, 'session_id': session_id, 'business_id': business_id
                })
            }
            return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsFileUploadAws)
            .then((Response) => Response.json())
            .then(checkResponseOfS3Upload => {
                console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
    
                if (checkResponseOfS3Upload.success === true) {
                // console.log("in success checkResponseOfS3Upload.data.public_url.url", 
                //     checkResponseOfS3Upload.data.public_url.url)
                console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

                const formData = new FormData();
                Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
                    formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
                });
                formData.append("file", NewScreenShotFile);
    
                const xhr = new XMLHttpRequest();
                xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
                xhr.send(formData);
    
                xhr.onload = function () {
                    if (this.status === 204) {
                        console.log("-----SUCCESSFULLY UPLOADED");
                        // console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                        console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)
                        
                        const requestOptions = {
                            method: 'POST',
                            headers: authHeader(),
                            body: JSON.stringify({'business_id': business_id, 'device_token': device_token, 'session_id': session_id, 
                                'subject': FeedbackSubject, 'description': FeedbackDescription, 'category': FeedbackCategory,
                                'ticket_priority': FeedbackPriority, 'status': FeedbackStatus, 'severity': FeedbackSeverity,
                                // 'attachment': checkResponseOfS3Upload.data.public_url.url
                                "uuid": checkResponseOfS3Upload.data.upload_info.uuid,
                                'attachment': checkResponseOfS3Upload.data.upload_info.fields.key
                            })
                        };
                        fetch( process.env.REACT_APP_CREATE_TICKET , requestOptions)
                        .then((Response) => Response.json())
                        .then( async(FeedbackAdded) => {
                            setShowProcedure(false);
                            console.log("FeedbackAdded after api call in useeffect",FeedbackAdded);
                            if(FeedbackAdded.success == true)
                            {
                                // console.log("FeedbackDetails.data.tickets ",FeedbackDetails.data.tickets);
                                // setFeedbacks(FeedbackDetails.data.tickets)
                                Swal.fire({
                                    icon: 'success',
                                    text: "Feedback Sent",
                                    confirmButtonColor: 'primary',
                                    confirmButtonText: 'OK',
                                    timer: 5000,
                                })
                                setOpenTakeFeedback(false);

                                setFeedbackSubject();
                                setFeedbackDescription();
                                setNewScreenShotFile();
                                setFeedbackSeverity();

                                setFeedbackSubjectError();
                                setFeedbackDescriptionError();
                                // setFeedbackFileError();
                                setFeedbackSeverityError();
                            }
                            else if(FeedbackAdded.success == false){
                            // setFeedbacks([]);
                            Swal.fire({
                                icon: 'error',
                                text: FeedbackAdded.errors,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK',
                                timer: 9000,
                            })
                            }
                        })
                        .catch(err => {
                        setShowProcedure(false);
                        Swal.fire({
                            icon: 'error',
                            // text: "Something went wrong",
                            text: "Server Error. Please try again.",
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        });
                    }
                }
                }
                else {
                setShowProcedure(false);
                Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error (s3). Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
                }
            })
            .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error (s3). Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
                })
            })



            // const requestOptions = {
            //     method: 'POST',
            //     headers: authHeader(),
            //     body: JSON.stringify({'business_id': business_id, 'device_token': device_token, 'session_id': session_id, 
            //         'subject': FeedbackSubject, 'description': FeedbackDescription, 'category': FeedbackCategory,
            //         'ticket_priority': FeedbackPriority, 'status': FeedbackStatus, 'severity': FeedbackSeverity
            //     })
            // };
            // fetch( process.env.REACT_APP_CREATE_TICKET , requestOptions)
            // .then((Response) => Response.json())
            // .then( async(FeedbackAdded) => {
            //     setShowProcedure(false);
            //     console.log("FeedbackAdded after api call in useeffect",FeedbackAdded);
            //     if(FeedbackAdded.success == true)
            //     {
            //         // console.log("FeedbackDetails.data.tickets ",FeedbackDetails.data.tickets);
            //         // setFeedbacks(FeedbackDetails.data.tickets)
            //         Swal.fire({
            //             icon: 'success',
            //             text: "Feedback Added Succesfully",
            //             confirmButtonColor: 'primary',
            //             confirmButtonText: 'OK',
            //             timer: 5000,
            //         })
            //         setOpenTakeFeedback(false);

            //         setFeedbackSubject();
            //         setFeedbackDescription();
            //         setNewScreenShotFile();
            //         setFeedbackSeverity();

            //         setFeedbackSubjectError();
            //         setFeedbackDescriptionError();
            //         setFeedbackFileError();
            //         setFeedbackSeverityError();
            //     }
            //     else if(FeedbackAdded.success == false){
            //     // setFeedbacks([]);
            //     Swal.fire({
            //         icon: 'error',
            //         text: FeedbackAdded.errors,
            //         confirmButtonColor: '#d33',
            //         confirmButtonText: 'OK',
            //         timer: 9000,
            //     })
            //     }
            // })
            // .catch(err => {
            // setShowProcedure(false);
            // Swal.fire({
            //     icon: 'error',
            //     // text: "Something went wrong",
            //     text: "Server Error. Please try again.",
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            // })
            // });
        }
    }

    
    
    const bodyTakeFeedback = (
        <div className={classes.paperModalFeedback}>
            <div className={classes.addNewBusinessHeaderBackgroud}>
                <h2>Feedback</h2>
            </div>

            <div className={classes.modalBody} style={{position:'absolute'}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    variant="outlined"
                    id="subject"
                    label="Subject"
                    placeholder='Enter Subject'
                    name="subject"
                    inputProps={{ maxLength: 200 }}
                    autoComplete="subject"
                    autoFocus
                    value={FeedbackSubject}
                    onChange={(e) => setFeedbackSubject(e.target.value)}
                />
                <div className={classes.validation}>{(FeedbackSubject) ? (<div></div>) : (FeedbackSubjectError)}</div>

                <FormControl className={classes.formControl}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="feedback"
                        label="Description"
                        multiline
                        rows={4}
                        placeholder='Enter Description'
                        name="feedback"
                        autoComplete="feedback"
                        value={FeedbackDescription}
                        onChange={(e) => setFeedbackDescription(e.target.value)}
                    />
                </FormControl>
                <div className={classes.validation}>{(FeedbackDescription) ? (<div></div>) : (FeedbackDescriptionError)}</div>
                
                <FormControl className={classes.formControl} style={{marginTop:'2%'}}>
                    <Grid>Upload Screenshot</Grid>
                    <Grid item xs={12}>
                        <div style={{marginTop:'2%'}}>
                            <input type='file' 
                            accept="image/*"
                            id="idProf"
                            onChange={ (e) => onUploadScreenShot(e)} 
                            />
                        </div>
                    </Grid>
                    {/* <div className={classes.validation} style={{marginTop:'1%'}}>{(NewScreenShotFile) ? (<div></div>) : (FeedbackFileError)}</div> */}
                </FormControl>

                <FormControl className={classes.formControl} style={{marginTop:'4%'}}>
                    <Grid>Severity*</Grid>
                    <Grid item xs={12} >
                        <RadioGroup
                            row
                            id ="severity"
                            aria-label="is_active"
                            name="severity"
                            defaultValue={(FeedbackSeverity)}
                            // onChange={ (e) => setcategoryDetails({...categoryDetails, is_active: JSON.parse(e.target.value) })}
                            onChange={ (e) => setFeedbackSeverity(e.target.value)} 
                        >                  
                        <div>
                            <FormControlLabel
                            value="CRITICAL"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Critical"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                            value="BLOCKER"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Blocker"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                            value="MAJOR"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Major"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                            value="MODERATE"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Moderate"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                            value="MINOR"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Minor"
                            />
                        </div>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.validation}>{(FeedbackSeverity) ? (<div></div>) : (FeedbackSeverityError)}</div>
                </FormControl>

                <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'5%'}}>
                    <Button
                        type="submit"
                        //   fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleUploadFeedback}
                    >
                        Submit
                    </Button>
                    <div style={{marginLeft:'2%'}}></div>
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes.submit}
                        onClick={handelTakeFeedbackClose}
                    >
                        Cancel
                    </Button>
                </div>

            </div>
        </div>
    )

    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    if (RedirectProfile == true) {
        return <Redirect to={`/UsersProfile`} />
    }
    if (RedirectToShowFeedback == true) {
        return <Redirect to={`/ShowFeedback`} />
    }
    else {
        return (
            <div className={classes.root}>

                {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                    <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
                </modal> : null}

                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleFeedbackClick}

                >
                    <Tooltip
                        title="Feedback"
                        placement="center"
                    >
                        <FeedbackIcon style={{ fontSize: 45, cursor: 'pointer', color: '#e1e5eb', }} />
                    </Tooltip>
                </Button>

                <Modal
                    open={OpenTakeFeedback}
                    onClose={handelTakeFeedbackClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyTakeFeedback}
                </Modal>

            </div>
        );
    }
}
