import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import routes from "../routes";
import TaxManagementNotFound from "../TaxManagementNotFound";
import { useThemeContext, useThemeUpdate } from '../ThemeContext';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";

import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "#E5E5E5",
    // background: "#ffffff",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paperModalTandC: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: 600,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
  },
  modalBodyTandC: {
    // marginTop: '-2%',
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TaxManagementWrapper({ users, matchRoute }) {
  const classes = useStyles();
  const darkTheme = useThemeContext()
  const [flag, setflag] = React.useState(false);
  // const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  // const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  // const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));

  // const [device_token, setdevice_token] = React.useState();
  // const [session_id, setsession_id] = React.useState();
  // const [business_id, setbusiness_id] = React.useState();
  // const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);
  // const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  // const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  // console.log("businessIdFromRedux, deviceTokenFromRedux,sessionIdFromRedux on wrapper is",
  //   businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)
  // const roleOfUserFromRedux = useSelector(state => state.loginDetails.logedInRole);
  // const [role_of_user, setrole_of_user] = React.useState();

  // T and c
  // const [TermsAndConditionAccepted, setTermsAndConditionAccepted] = React.useState(false);
  // const [TermsAndConditionAccepted, setTermsAndConditionAccepted] = React.useState(true);

  const [TandCdata, setTandCdata] = React.useState();

  const [OpenTermsAndCondition, setOpenTermsAndCondition] = React.useState(false);

  const dispatch = useDispatch()

  const themeStyles = {
    // backgroundColor: darkTheme ? {marginLeft: '10%'} : '',
    // color: darkTheme ? '#CCC' : '#333',
    marginLeft: darkTheme ? '15%' : '0%',
    // marginTop: darkTheme ? '10%' : '0%',
    // padding: '2rem',
    // margin: '2rem',
  }

  // let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
  // console.log('Check this for business_id on wrapper', business_id);

  // let roleOfUserFromReduxUseEffect = useSelector(state => state.loginDetails.logedInRole);



  React.useEffect(() => {
    setflag(true)
    // getReduxValues();
    // setdevice_token(deviceTokenFromRedux)
    // setsession_id(sessionIdFromRedux)
    // setbusiness_id(businessIdFromRedux)
    // setrole_of_user(roleOfUserFromRedux)

    // console.log("roleOfUserFromReduxUseEffect in useeffect of wrapper ",roleOfUserFromReduxUseEffect)

      // checkTermsAndConditionAcceted();

    // getTandC()
  }, [flag]);

  // }, [flag, businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux, roleOfUserFromRedux ]);

  // const getReduxValues = async() => {
  //   await setrole_of_user(roleOfUserFromRedux)
  //   await setdevice_token(deviceTokenFromRedux)
  //   await setsession_id(sessionIdFromRedux)
  //   await setbusiness_id(businessIdFromRedux)
  // }

  // const checkTermsAndConditionAcceted = () => {
  //   console.log(" role= role_of_user in checkTermsAndConditionAcceted", role_of_user)
  //   console.log(" role= roleOfUserFromRedux in checkTermsAndConditionAcceted", roleOfUserFromRedux)
  //   console.log("in checkTermsAndConditionAcceted normal", device_token, session_id, business_id)
  //   console.log("in checkTermsAndConditionAcceted redux",
  //     businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: authHeader(),
  //       body: JSON.stringify({
  //         'business_id': businessIdFromRedux,
  //         'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux,
  //       })
  //     };
  //     return fetch(process.env.REACT_APP_GET_TERMS_STATUS_CHECK, requestOptions)
  //       .then((Response) => Response.json())
  //       .then(TermsStatusCheck => {
  //         if (TermsStatusCheck.success === true) {
  //           console.log("accepted t and c ON WRAPPER", TermsStatusCheck.accepted)
  //           if (TermsStatusCheck.accepted === false) {
  //             setTermsAndConditionAccepted(false);
  //             handleOpenTermsAndCondition();
  //           }
  //           else if (TermsStatusCheck.accepted === true) {
  //             setTermsAndConditionAccepted(true);
  //           }
  
  //         }
  //         else if (TermsStatusCheck.success === false) {
  //           setTermsAndConditionAccepted(false);
  //         }
  //       })
  //       .catch(err => {
  //         Swal.fire({
  //           icon: 'error',
  //           text: "Something went wrong",
  //           confirmButtonColor: '#d33',
  //           confirmButtonText: 'OK'
  //         })
  //       })
  // }

  // const getTandC = () => {

  //   console.log("in getTandC of wrapper normal", device_token, session_id, business_id)
  //   console.log("in getTandC of wrapper redux",
  //     businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: authHeader(),
  //     body: JSON.stringify({
  //       // 'business_id': businessIdFromRedux,
  //       "terms_id": 1,
  //       'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux,
  //     })
  //   };
  //   return fetch(process.env.REACT_APP_GET_SINGLE_TERMS, requestOptions)
  //     .then((Response) => Response.json())
  //     .then(AcceptenceTandC => {
  //       if (AcceptenceTandC.success === true) {
  //         console.log("accepted t and c ON WRAPPER", AcceptenceTandC.data.business_info)

  //         setTandCdata(AcceptenceTandC.data.business_info);
  //       }
  //       else if (AcceptenceTandC.success === false) {
  //         setTandCdata();
  //       }
  //     })
  //     // .catch(err => {
  //     //   Swal.fire({
  //     //     icon: 'error',
  //     //     text: "Something went wrong",
  //     //     confirmButtonColor: '#d33',
  //     //     confirmButtonText: 'OK'
  //     //   })
  //     // })
  // }

  // const handleOpenTermsAndCondition = () => {
  //   setOpenTermsAndCondition(true)
  //   getTandC();
  // }

  // const handleCloseTermsAndCondition = () => {
  //   setOpenTermsAndCondition(false)
  //   dispatch(userActions.logout());
  // }

  // const handleAcceptTermsAndCondition = () => {
  //   console.log("in handleAcceptTermsAndCondition of wrapper normal", device_token, session_id, business_id)
  //   console.log("in handleAcceptTermsAndCondition of wrapper redux",
  //     businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)
  // }

  // const bodyTermsAndCondition = (
  //   <div className={classes.paperModalTandC}>
  //     <div className={classes.modalBody} style={{ position: 'absolute' }}>
  //       Terms and Conditions
  //     </div>

  //     <div className={classes.modalBodyTandC}>
  //       <Grid>
  //         {/* {
  //           TandCdata && TandCdata.map( (item, index) => (
  //             <Grid>
  //               {item.name}
  //             </Grid>
  //           ))
  //         } */}
  //       </Grid>

  //     </div>

  //     <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '5%' }}>
  //       <Button
  //         type="submit"
  //         variant="contained"
  //         color="primary"
  //         className={classes.submit}
  //         onClick={handleAcceptTermsAndCondition}
  //       >
  //         Accept
  //       </Button>
  //       <div style={{ marginLeft: '2%' }}></div>
  //       <Button
  //         type="submit"
  //         variant="contained"
  //         className={classes.submit}
  //         onClick={handleCloseTermsAndCondition}
  //       >
  //         Reject
  //       </Button>
  //     </div>

  //   </div>
  // )

  return (
    // console.log("wrapper file"),
    <div className={classes.root} style={themeStyles}>
    {/* <div className={classes.root} > */}
      <Grid container>
        <Grid item xs={12}>
          {/* for t and c */}
          {/* <div>
            {
              (TermsAndConditionAccepted === true) ?
                ( */}
                  <div>
                    {
                      <Switch>
                        <Route exact path="/">
                          <Redirect to="/search" />
                        </Route>
                        {/* <Route exact path="/HomePage">
                          <Redirect to="/HomePage" />
                        </Route> */}
                        {routes.map((route, index) => (
                          // console.log("in routes.map of wrapper",route, index),
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                          />
                        ))}
                        <Route path="*">
                          <TaxManagementNotFound />
                        </Route>
                      </Switch>
                    }
                  </div>
            {/* //     ) :
            //     (
            //       <div>
            //         <Grid> show tandc to accepts </Grid>
            //       </div>
            //     )
            // }
          </div> */}
          {/* for t and c */}

        </Grid>
      </Grid>
      {/* <Modal
        open={OpenTermsAndCondition}
        onClose={handleCloseTermsAndCondition}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyTermsAndCondition}
      </Modal> */}
    </div>
  );
}
