import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import io from 'socket.io-client';

import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import { useSelector, useDispatch } from 'react-redux';
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  feedbackDescription: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "15px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    width: '100%',
    color: theme.palette.text.secondary,
    marginTop: '-1%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  leftAlignWithoutColor: {
    textAlign: "left",
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
}));

export default function FeedbackMessages() {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false)
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [flag, setflag] = React.useState(false);
  const [Feedbacks, setFeedbacks] = React.useState()
  const [FeedbackMessage, setFeedbackMessage] = React.useState();
  const [SendNewMsgErr, setSendNewMsgErr] = React.useState();

  const dispatch = useDispatch();

  React.useEffect(() => {
    // setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());

    let Feedback_Details_Here = JSON.parse(localStorage.getItem('selected_Feedback_Details'));
    console.log("Feedback_Details_Here on meassages tab", Feedback_Details_Here)
    setFeedbacks(Feedback_Details_Here)


    // setShowProcedure(false);
  }, [flag]);

  const validate = () => {
    let SendNewMsgErr = '';

    if (!FeedbackMessage) {
      SendNewMsgErr = "Please Enter Message"
    }

    if (SendNewMsgErr) {
      setSendNewMsgErr(SendNewMsgErr);
      return false;
    }
    return true;
  }

  const handleSendMessage = () => {
    console.log("handleSendMessage", FeedbackMessage)
    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    const isValid = validate();
    if (isValid) {
      setShowProcedure(true);
      let FeedbackMessageDetails = {
        ticket_id: Feedbacks.ticket_id,
        message: FeedbackMessage,
        is_active: true,
        device_token: device_token,
        session_id: session_id,
        business_id: business_id
      }
      console.log("FeedbackMessageDetails", FeedbackMessageDetails);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(FeedbackMessageDetails)
      };

      fetch(process.env.REACT_APP_CREATE_COMMUNICATION, requestOptions)
        .then((Response) => Response.json())
        .then(async (FeedbackMessageAdded) => {
          setShowProcedure(false);
          console.log("FeedbackMessageAdded", FeedbackMessageAdded);
          if (FeedbackMessageAdded.success == true) {
            console.log("FeedbackMessageAdded success", FeedbackMessageAdded.data);

            // Feedbacks.ticket_communication.push(FeedbackMessageAdded.data.msg_info)
            Feedbacks.ticket_communication.splice(0,0,FeedbackMessageAdded.data.msg_info)
            setFeedbackMessage("");
            setSendNewMsgErr("");
            await localStorage.setItem('selected_Feedback_Details', JSON.stringify(Feedbacks));
          }
          else if (FeedbackMessageAdded.success == false) {
            Swal.fire({
              icon: 'error',
              text: FeedbackMessageAdded.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 9000,
            })
          }
          setflag(false);
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }
  }

  const handleSendMessageCancel = () => {
    console.log("handleSendMessageCancel", FeedbackMessage)
    setFeedbackMessage("");
    setSendNewMsgErr("");
  }

  // const Chat = ()=>{
  //   const [messages,setMessages] = useState([]);
  //   const [input,setInput] = useState('');
  //   const [image,setImage] = useState(null);

  //   useEffect(() =>{
  //     socket.on('receiveMessage',(message) => {
  //       setMessages((prev) => [...prev,message]);
  //     });
  //   },[]);

  //   const handleSend = async () => {
  //     if(input.trim() || image){
  //       let message = {text:input, image:null};

  //       if (image){
  //         const formData = new FormData();
  //         formData.append('image',image);
  //         const response = await axios.post(-----------,formData);
  //         message.image = response.data.url;
  //       }

  //       socket.emit('sendMessage',message);
  //       setInput('');
  //       setImage(null);
  //     }
  //   };
  // }



  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}

      <Grid container className={classes.pageHeaderContainer} style={{ marginTop: '2%' }}>
        <Grid item xs={12}>
          <Grid item xs={10} className={classes.pageHeaderLabel}>
            Feedback Messages
          </Grid>

          <TextField
            margin="normal"
            required
            fullWidth
            variant="outlined"
            id="message"
            label="message"
            placeholder='Enter Message'
            name="message"
            autoComplete="message"
            value={FeedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
          />
          <div className={classes.validation} style={{ textAlign: "left", marginTop: "-1%" }}>
            {(FeedbackMessage) ? (<div></div>) : (SendNewMsgErr)}
          </div>

          <div style={{
            marginTop: '2%', display: 'flex', alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            onClick={() => handleSendMessage()}
            >
              Send
              {/* <FormattedMessage id="sendLabel" /> */}
            </Button>
            <div style={{ marginLeft: '2%' }}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            onClick={() => handleSendMessageCancel()}
            >
              Cancel
            </Button>
          </div>

          <Grid item xs={12}>
              {
                Feedbacks && Feedbacks.ticket_communication.map((item, index) => (
                  <Grid container className={classes.gridContianer}>
                    
                      
                        <Grid item xs={12} className={classes.leftAlignWithoutColor}>
                          <Grid container>
                            <Grid item xs={10} style={{color:"grey"}}>
                              {item.user}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.message}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {/* {"14 hours ago"} */}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"2%"}}>
                          <Divider />
                        </Grid>
                      </Grid>
                    
                  
                ))
              }
            </Grid>


        </Grid>
      </Grid>

    </div>
  )
}